<template>
  <v-list-item>
    <v-list-item-avatar>
      <app-chat-avatar :is_urgent="is_urgent" />
    </v-list-item-avatar>
    <v-list-item-title class="d-flex flex-row">
      <!-- precio,momento,origen->destino        -->
      <app-chat-header-resume :chat="chat"/>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn
            @click.stop="close_chat"
            v-if="is_closeable"
            v-on="on"
            icon
            large
            class="flex-shrink-0 ma-auto"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        Borrar el chat
      </v-tooltip>
    </v-list-item-title>
  </v-list-item>
</template>
<script>
import { session } from "@/api/auth";
import { chat } from "@/api/data/bazaar";
import AppChatAvatar from "@/views/bazaar/components/bazzarServiceRequest/AppChatAvatar.vue";
import AppChatHeaderResume from "@/views/bazaar/components/bazzarServiceRequest/AppChatHeaderResume.vue";

export default {
  name: "app-chat-header",
  components: { AppChatHeaderResume, AppChatAvatar },
  props: {
    chat: {},
  },
  computed: {
    is_urgent() {
      return !!this.chat.description["is_urgent"];
    },
    is_closeable() {
      return (
        session.me.id == this.chat.seller ||
        session.me.is_superuser ||
        session.me.agent
      );
    },
  },
  methods: {
    close_chat() {
      chat.remove({
        bazaar_id: this.chat.id,
      });
    },
  },
};
</script>
