<template>
  <div>
    <v-expansion-panels v-if="collapsable" class="pt-1 px-2">
      <v-expansion-panel>
        <v-expansion-panel-header class="pa-0 pr-3">
          <app-chat-header :chat="chat"/>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <app-chat-content :chat="chat"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-hover v-else v-slot="{ hover }">
      <v-card
          :elevation="$listeners.click ?(hover ? 3 : 1):0"
          v-on="$listeners"
          v-bind="$attrs"
      >
        <v-card-title class="ma-0 py-0 text-body-1">
          <app-chat-header :chat="chat"/>
        </v-card-title>
        <v-card-text class="pb-5">
          <app-chat-content :chat="chat"/>
        </v-card-text>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import goodLookJson from "@/components/molecules/goodLookJson.vue";
import AppChatHeader from "@/views/bazaar/components/bazzarServiceRequest/AppChatHeader.vue";
import AppChatContent from "@/views/bazaar/components/bazzarServiceRequest/AppChatContent.vue";

export default {
    inheritAttrs: false,
    name: "appChat",
    components: {AppChatContent, AppChatHeader, goodLookJson},
    props: {
        chat: {
            type: Object,
            required: true
        },
        collapsable: {
            type: Boolean,
            default: () => false
        }
    }
}
</script>