import Vue from "vue";
import VueRouter from "vue-router";
// import HomeView from '../views/HomeView.vue'
import Dashboard from "../views/home/Dashboard.vue";
// import EchartPage from '../views/home/echartpage.vue'
import ResourceView from "@/views/settings/ResourceView";
import ResourceListView from "@/views/settings/ResourceListView";
import UsersView from "@/views/settings/AgentView.vue";
import ImagesView from "@/views/settings/ImagesView";
import FilesView from "@/views/settings/FilesView.vue";
import FileView from "@/views/settings/FileView.vue";
import DriversView from "@/views/settings/DriversView";
import loginView from "@/views/app/loginView";
import RegisterView from "@/views/app/registerView.vue";
import AppServiceListView from "@/views/home/services_list/AppServiceListView.vue";
// import AppNotDoneServiceListView from "@/views/home/services_list/AppNotDoneServiceListView.vue";
// import ApCollaboratorsServiceListView from "@/views/home/services_list/AppCollaboratorsServiceListView.vue";
import appDriverFactureView from "@/views/debtAndFacture/AppDriverFactureView.vue";
import appAgentFactureView from "@/views/debtAndFacture/AppAgentFactureView.vue";
// import AppFactureView from "@/views/home/AppFactureView";
import AppDepositView from "@/views/debtAndFacture/AppDepositView.vue";
import AppTransactionView from "@/views/debtAndFacture/AppTransactionView.vue";
import AppResumenView from "@/views/debtAndFacture/AppResumenView.vue";
import App from "@/views/app/App";
import ProceduresView from "@/views/settings/ProceduresView";
import ProcedureOperationsView from "@/views/settings/OperationsView.vue";
import LocationView from "@/views/settings/LocationView";
import ApplicationLogsView from "@/views/settings/LogsView.vue";
import ApplicationUserAccountView from "@/views/settings/UserView.vue";
import serviceDetail from "@/components/molecules/serviceDetail.vue";
import {
  mdiAccount,
  mdiAlert,
  mdiAppleIcloud,
  mdiCamera,
  mdiCashLockOpen,
  mdiCashRegister,
  mdiChat,
  mdiCog,
  mdiCurrencyUsd,
  mdiFaceAgent,
  mdiFile,
  mdiFileClock,
  mdiGoogleMaps,
  mdiHome,
  mdiNewspaper,
  mdiShield,
  mdiTaxi,
  mdiTimelineClock,
  mdiViewDashboard,
} from "@mdi/js";
import AppIncidencesView from "@/views/home/AppIncidencesView.vue";
import appChatRoomView from "@/views/bazaar/AppChatRoomView.vue";
import appMyServices from "@/views/bazaar/appMyServicesView.vue";

Vue.use(VueRouter);

const comingSoon = {
  render: (h) => h("h1", "Coming soon..."),
};
const routerView = {
  render: (h) => h("routerView", ""),
};

class Route {
  constructor(path, name, component, icon, children, hidden = false) {
    this.path = path;
    this.name = name;
    this.component = component ?? comingSoon;
    this.icon = icon ?? "mdi-inbox-arrow-down";
    this.children = children;
    this.hidden = hidden;
    this.model = false;
    this.sensitive = true;
  }
}

export const internal_routers = [
  // new Route('/echart-page', 'echartpage', EchartPage, mdiChartBar),
  new Route("/dashboard", "Dashboard", Dashboard, mdiViewDashboard),
  new Route("/chat", "Taxi", routerView, mdiChat, [
    new Route("room", "Chat", appChatRoomView, mdiChat),
    new Route("services", "Mis servicios", appMyServices, mdiChat),
  ]),
  new Route("/home", "home", routerView, mdiHome, [
    new Route("services", "Services", AppServiceListView, mdiTimelineClock),
    // new Route('notDoneServices', 'Not Done Services', AppNotDoneServiceListView, mdiTimelinePlus),
    // new Route('collaborator', 'Collaborator Services', ApCollaboratorsServiceListView, mdiTimelinePlus),
    new Route("incidences", "Incidences", AppIncidencesView, mdiAlert),
    new Route(
      "services/:service_id",
      "service_details",
      serviceDetail,
      null,
      null,
      true
    ),
  ]),
  new Route("/debtAndFacture", "Debt and Facture", routerView, mdiCurrencyUsd, [
    new Route("resumen", "Driver Resumen", AppResumenView, mdiNewspaper),
    new Route(
      "driverFacture",
      "Driver Facture",
      appDriverFactureView,
      mdiCashRegister
    ),
    new Route(
      "agentFacture",
      "Agent Facture",
      appAgentFactureView,
      mdiCashRegister
    ),
    new Route("deposit", "Deposit", AppDepositView, mdiCashLockOpen),
    new Route(
      "transaction",
      "Transactions",
      AppTransactionView,
      mdiCashLockOpen
    ),
  ]), // new Route(
  //     '/about', 'about',
  //     () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  //     'mdi-inbox-arrow-down'
  // ),
  new Route("/settings", "Settings", routerView, mdiCog, [
    new Route("procedures", "Procedures", ProceduresView),
    new Route(
      "/procedure/:procedure_id",
      "Operations",
      ProcedureOperationsView,
      null,
      null,
      true
    ),
    new Route("secure-table", "Resource", ResourceListView, mdiAppleIcloud),
    new Route("secure-table2", "Resource2", ResourceView, mdiAppleIcloud),
    new Route("agents", "Agents", UsersView, mdiFaceAgent),
    new Route("drivers", "Drivers", DriversView, mdiTaxi),
    new Route("locations", "Locations", LocationView, mdiGoogleMaps),
    new Route("images", "Images", ImagesView, mdiCamera),
    new Route("files", "Files", FilesView, mdiFile),
    new Route("files/:file_name", "Files", FileView, mdiFile),
  ]),
  new Route("/security", "Security", routerView, mdiShield, [
    new Route("/user", "user", ApplicationUserAccountView, mdiAccount),
    new Route("/logs", "logs", ApplicationLogsView, mdiFileClock),
  ]),
  {
    hidden: true, // skip in navigation drawer
    path: "*",
    redirect: { name: "Services" },
  },
];

export const routes = [
  new Route("/login", "login", loginView),
  new Route("/register", "register", RegisterView),
  new Route("/", "app", App, "", internal_routers),
];

const createRouter = () =>
  new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.options.routes = [];
}

export default router;
