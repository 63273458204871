<template>
  <v-container class="flex-grow-0" fluid>
    <v-row v-if="show_seller">
      <v-col class="pa-1 pb-0">
        <span class="text--lighten-2 text-caption">
          @{{ chat.seller_nested.username }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex flex-column flex-grow-0 pa-1">
        <div class="flex-grow-1 d-flex flex-column">
          <h3 class="text--accent-2 my-auto">
            {{ chat.description["price"] | monetize }}
          </h3>
        </div>
        <span class="flex-grow-0 text-caption">{{ time }}</span>
      </v-col>
      <v-col>
        <h5 class="flex-shrink-0 flex-grow-0">
          {{ chat.description["Municipio Origen"] }}
          <v-icon> mdi-arrow-right</v-icon>
          {{ chat.description["Municipio Destino"] }}
        </h5>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { session } from "@/api/auth";
import { dateFromNowUtc } from "@/plugins/filters";

export default {
  name: "app-chat-header-resume",
  computed: {
    show_seller() {
      return session.isAgent || session.me.id == this.chat.seller;
    },
  },
  methods: {
    calculateTime() {
      this.time = dateFromNowUtc(this.chat.updated_at || this.chat.created_at);
    },
  },
  created() {
    this.calculateTime();
    this._updater = setInterval(this.calculateTime, 1000);
  },
  destroyed() {
    clearInterval(this._updater);
  },
  data: () => ({
    time: null,
    _updater: null,
  }),
  props: {
    chat: {},
  },
};
</script>
