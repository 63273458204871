import {ConfigDefinition, Table} from "@/api/Table";
import {extend, headers, limit, order_by} from "@/api/helper";


export const subscribe = new Table(
    '/bazaar',
    'subscribe'
)
export const chat = new Table(
    '/bazaar',
    'chat'
)

export const request = new Table(
    '/bazaar',
    'request',
    new ConfigDefinition(
        {
            ...headers(false),
        }
    )
);
export const acceptRequest = new Table(
    '/bazaar',
    'accept-request'
)

export const chatInteractions = new Table(
    '/bazaar',
    'interaction', new ConfigDefinition(
        {
            ...headers(false),
            ...limit(-1),
            ...order_by(
                "sended_at"
            ),
            ...extend(
                "bazaar_id"
            )
        }
    )
)

export const interactions = new Table(
    '/bazaar',
    'bazaar_interactions'
)
