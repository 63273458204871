import {session} from "@/api/auth";

export class WebsocketApp {
    constructor(url, {
        tryReconnectOnError,
        reconnectTimeOut,
        onClose,
        authToken
    }) {
        reconnectTimeOut = reconnectTimeOut ?? 1000
        authToken = authToken ?? session.token
        tryReconnectOnError = tryReconnectOnError ?? false

        let socket;
        this.status = "disconnect"
        let _reconnectTimeOut;
        const subscription = {}

        const self = this;

        function connect() {
            self.status = "connecting"
            console.log("try connect to websocket")
            let tryReconnectOnError_temp = tryReconnectOnError
            tryReconnectOnError = false
            socket?.close();
            tryReconnectOnError = tryReconnectOnError_temp
            socket = new WebSocket(url)
            console.log("websocket created")

            socket.onopen = (event) => {
                self.status = "connected"
                self.sendJson({
                    type: "auth",
                    token: authToken
                });
                console.log("websocket connected")
            }

            socket.onerror = function (err) {
                console.error('Socket encountered error: ', err.reason);
            };

            socket.onclose = (e) => {
                onClose?.(e);

                self.status = "disconnect"
                if (tryReconnectOnError) {
                    console.log('Socket is closed. Reconnect will be attempted in 3 second.', e);
                    clearReconnectTimeout();
                    self._reconnect = setTimeout(
                        connect,
                        reconnectTimeOut
                    )
                } else {
                    console.log('Socket is closed');
                }
            }

            socket.onmessage = function onNewMessage(event) {
                const {type, ...data} = JSON.parse(event.data);
                if (type in subscription) {
                    for (let callback of subscription[type]) {
                        callback(
                            data
                        )
                    }
                } else {
                    console.warn("Unknown message type", type)
                }
            }
        }

        function clearReconnectTimeout() {
            return clearTimeout(
                _reconnectTimeOut
            )
        }

        this.close = function websocketClose() {
            tryReconnectOnError = false;
            socket.close();
            clearReconnectTimeout();
        }

        this.subscribe = function subscribe(key, callback) {
            if (key in subscription) {
                subscription[key].append(
                    callback
                )
            } else {
                subscription[key] = [callback]
            }
        }

        this.sendJson = function sendMessageIntoWebsocket(data) {
            return socket.send(
                JSON.stringify(
                    data
                )
            )
        }

        connect();
    }
}
