import { render, staticRenderFns } from "./likeBtn.vue?vue&type=template&id=7a77f804&scoped=true&"
import script from "./likeBtn.vue?vue&type=script&lang=js&"
export * from "./likeBtn.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a77f804",
  null
  
)

export default component.exports