<template>
  <app-basic-layout :hideHeader="true" @add="openCreate">
    <app-data-table
      :collection="collection"
      ref="appDataTable"
      @onSearch="search"
      :hideHeader="false"
      :allow-download="true"
      :show-select="session.isSuperUser"
      item-key="user_id"
      :actionSelected="[
        {
          icon: 'mdi-account-switch',
          text: 'Cambiar gestor',
          action: (items) => showUpdateAttendedBy(items),
          hidden: !session.isSuperUser,
        },
      ]"
    >
      <template v-slot:head>
        <h2 class="ma-auto">Drivers</h2>
      </template>
      <template v-slot:edit-dialog="{ item: { user_id, ...item } }">
        <auto-form
          :loading-action="collection.loading"
          :form-data="item"
          :formFields="collection.formFields"
          @submit="(obj) => _update_item({ user_id }, obj)"
        >
          <template v-slot:prepend-action>
            <v-btn
              :loading="collection.loading"
              :disabled="collection.loading"
              color="error"
              @click.stop="_remove_item({ user_id })"
            >
              delete
            </v-btn>
          </template>
        </auto-form>
      </template>
    </app-data-table>
    <app-dialog v-model="createDialog">
      <auto-form
        :loading-action="collection.loading"
        text="create"
        :form-data="collection.formData"
        :formFields="collection.formFields"
        :exclude="['id']"
        @clear="clear"
        @submit="create"
      />
    </app-dialog>
    <app-dialog v-model="updateAttendedByDialog">
      <auto-form
        :loading-action="collection.loading"
        text="Actualiza el gestor que los atiende"
        :form-data="{ user_id: updateAttendedByItems }"
        :formFields="updateAttendedByFields"
        @submit="updateAttendedBy"
      />
    </app-dialog>
  </app-basic-layout>
</template>

<script>
import { Collection } from "@/plugins/collection";
import AppDataTable from "@/components/organisms/AppDataTable";
import appBasicLayout from "@/components/templates/appBasicLayout";
import autoForm from "@/plugins/AutoForms/AutoForm";
import { user_driver } from "@/api/data/user";
import AppDialog from "@/components/molecules/appDialog";
import { session } from "@/api/auth";
import { FormFieldItem, INTEGER_TYPE_FIELD } from "@/plugins/AutoForms/tools";

export default {
  name: "DriversView",
  components: { AppDialog, AppDataTable, appBasicLayout, autoForm },
  data: () => ({
    collection: new Collection(user_driver),
    createDialog: false,
    updateAttendedByItems: null,
    updateAttendedByDialog: false,
    updateAttendedByFields: [
      new FormFieldItem({
        type: INTEGER_TYPE_FIELD,
        foreign_key: { table: "user_driver" },
        multiple: true,
        nullable: true,
        keyData: "user_id",
      }),
      new FormFieldItem({
        type: INTEGER_TYPE_FIELD,
        foreign_key: { table: "user_agent" },
        keyData: "old_agent",
        nullable: true,
      }),
      new FormFieldItem({
        type: INTEGER_TYPE_FIELD,
        foreign_key: { table: "user_agent" },
        keyData: "new_agent",
        nullable: false,
      }),
    ],
  }),
  computed: {
    session: () => session,
  },
  methods: {
    showUpdateAttendedBy(items) {
      console.log("items", items);
      this.updateAttendedByItems = items.reduce(
        (carr, { user_id }) => [...carr, user_id],
        []
      );
      this.updateAttendedByDialog = true;
    },
    search(text) {
      if (text.trim() == "") {
        this.collection.clear_filters(["like", "like_cols"]);
      } else {
        this.collection.filter({
          like: text,
          like_cols: [
            "code",
            "name",
            "user_account.username",
            "user_account.phone_number",
          ],
        });
      }
    },
    clear() {
      this.collection.clearData();
    },
    openCreate() {
      this.createDialog = true;
    },
    create(obj) {
      this.collection.create(obj).then(() => {
        this.collection.fetchData();
        this.createDialog = false;
      });
    },
    updateAttendedBy({ user_id, old_agent, new_agent }, autoform) {
      let filter = {};
      if (user_id && user_id.length > 0) {
        filter = { user_id };
      }
      if (old_agent != null) {
        filter = { ...filter, attended_by: old_agent };
      }
      if (Object.keys(filter).length == 0) {
        this.$alert.error("debes seleccionar al menos un campo para filtrar");
      } else {
        debugger;
        this.collection
          .update(filter, {
            attended_by: new_agent,
          })
          .then(() => {
            this.collection.fetchData();
            this.updateAttendedByDialog = false;
            this.updateAttendedByItems = null;
            autoform.loadInnerValue();
          });
      }
    },
    _remove_item(filter) {
      this.collection.remove(filter).then((res) => {
        this.collection.fetchData();
        this.$refs["appDataTable"].editDialog = false;
      });
    },
    _update_item(filter, obj) {
      this.collection.update(filter, obj).then((res) => {
        this.collection.fetchData();
        this.$refs["appDataTable"].editDialog = false;
      });
    },
  },
};
</script>

<style scoped></style>
