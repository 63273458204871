<template>
  <div
      class="my-auto"
  >
    <v-menu
        offset-y
        rounded="b-xl"
    >
      <template v-slot:activator="{on}">
        <v-btn
            icon
            v-on="on"
        >
          <v-badge
              :value="badge>0"
              :content="badge"
              color="alert"
              overlap
          >
            <v-icon
                v-if="session.dingdong"
                class='ringing-animation'
                color="white"
            >mdi-bell-ring
            </v-icon>
            <v-icon
                v-else
                color="white"
            >
              mdi-bell
            </v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-card style="width: 30em">
        <v-card-title class="primary white--text d-flex flex-nowrap flex-row">
          Notificaciones
          <v-spacer></v-spacer>
          <div>
            <v-menu>
              <template v-slot:activator="{on}">
                <v-btn v-on="on" rounded>
                  {{ filter_notification }}
                  <v-icon>
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                    :key="opt"
                    v-for="opt in ['todas','Leídas','No leídas']"
                    @click="filter_notification = opt"
                >
                  <v-list-item-title
                      style="width: 7em"
                  >
                    {{ opt }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-card-title>
        <v-list class="overflow-auto" style="max-height: 80vh">
          <v-list-item two-line v-if="notifications.length == 0">
            <v-list-item-title>Nada que mostrar</v-list-item-title>
          </v-list-item>
          <template v-else>
            <template
                v-for="(message,index) in notifications"
            >
              <app-notification-row
                  :key="message.data.interaction.id"
                  @onDelete="deleteNotification(message)"
                  :message="message"
                  @raiseNotification="raiseNotificationDialog(message)"
              />
            </template>
          </template>
        </v-list>
      </v-card>
    </v-menu>
    <app-dialog
        :persistent="alertDialog"
        v-model="alertDialog"
        title="Alerta"
        color-icon="accent"
        icon="mdi-alert"
    >
      <app-content-alert @dismiss="dismiss_alert()" :message_selected="message_selected"/>
    </app-dialog>
    <app-dialog
        v-model="notificationDialog"
        v-if="message_selected"
        title="Notificaciones"
    >
      <v-card class="d-flex flex-column fill-height">
        <v-card-title>
          <span v-if="message_selected.type==='reject'">
            Su viaje a sido rechazado
            <v-icon>mdi-block-helper</v-icon>
          </span>
          <span v-else-if="message_selected.type==='accept'">
            Su viaje a sido aceptado
            <v-icon>mdi-check</v-icon>
          </span>
          <span v-else>
            A solicitado un viaje a un chofer <b>@{{ message_selected.data.interaction.user.username }}</b>
          </span>
        </v-card-title>
        <v-card-text>
          <app-chat :chat="message_selected.data.bazaar">
          </app-chat>
          <v-timeline
              align-top
              dense
              class="flex-grow-1 pt-0"
          >
            <app-bazaar-interaction
                :interaction="message_selected.data.interaction"
                :chat="message_selected.data.bazaar"
            >
            </app-bazaar-interaction>
          </v-timeline>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </app-dialog>
  </div>
</template>
<script>
import {session} from "@/api/auth";
import AppDialog from "@/components/molecules/appDialog.vue";
import AppChat from "@/views/bazaar/components/bazzarServiceRequest/appChat.vue";
import AppBazaarInteraction from "@/views/bazaar/components/appBazaarInteraction.vue";
import AppContentAlert from "@/views/app/AppContentAlert.vue";
import AppNotificationRow from "@/views/app/AppNotificationRow.vue";
import {is_back, message_type} from "@/const";


export default {
    name: 'app-alert-menu',
    components: {
        AppNotificationRow,
        AppContentAlert,
        AppBazaarInteraction,
        AppChat,
        AppDialog
    },
    data: () => ({
        message_selected: null,
        filter_notification: "Todas",

        alertDialog: false,
        notificationDialog: false,
        session
    }),
    watch: {
        alerts(newValue) {
            if (this.alerts.length > 0) {
                this.message_selected = this.alerts[0];
                this.alertDialog = true;
            }
        },
    },
    beforeMount() {
        if (this.alerts.length > 0) {
            this.message_selected = this.alerts[0];
            this.alertDialog = true;
        }
    },
    computed: {
        alerts() {
            return this.session.alerts;
        },
        notifications() {
            return [...this.session.notifications, ...this.session.alerts];
        },
        badge() {
            return this.notifications.length;
        }
    },
    methods: {
        deleteNotification(message) {
            let index = this.notifications.findIndex(
                ({data}) => data.interaction.id === message.data.interaction.id
            )
            if (index >= 0) {
                this.notifications.splice(index, 1);
            }
        },
        dismiss_alert() {
            this.message_selected = null;
            this.alertDialog = false
            this.notificationDialog = false;
        },
        async raiseNotificationDialog(message) {
            let {data} = message;
            this.message_selected = message;
            if (is_back.includes(message_type(data)) || data.bazaar.seller == session.user_id) {
                this.notificationDialog = true;
            } else {
                this.alertDialog = true
            }
        }
    }
}
</script>
<style lang="scss">

@keyframes ringing {
  0% {
    transform: rotate(-10deg);
  }
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

.ringing-animation {
  animation: ringing 1s infinite;
}

</style>