var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!!_vm.current_user.agent || _vm.current_user.id == _vm.chat.seller)?_c('AppAgenthumbUp',{on:{"yo":function($event){return _vm.riseProposal(true)},"cancel":_vm.hiddeTrip}}):(!!_vm.current_user.driver)?_c('AppDriverthumbUp',{on:{"yo":function($event){return _vm.riseProposal(false)},"cancel":_vm.hiddeTrip}}):_vm._e(),_c('app-chat',{attrs:{"chat":_vm.chat,"collapsable":true}}),_c('v-timeline',{staticClass:"flex-grow-1 d-flex flex-column overflow-auto",attrs:{"align-top":"","dense":""}},[(_vm.current_user.id !== _vm.chat.seller && !!_vm.ownerInteraction)?_c('app-bazaar-interaction',{attrs:{"interaction":_vm.ownerInteraction,"chat":_vm.chat,"color":"red","activeAccept":false},on:{"accept":_vm.onSelectInteraction}}):_vm._e(),(!!_vm.yourInteraction)?_c('app-bazaar-interaction',{attrs:{"interaction":_vm.yourInteraction,"chat":_vm.chat,"color":"green"}}):_vm._e(),_c('v-expansion-panels',[_c('v-expansion-panel',{staticClass:"bg2"},[_c('v-expansion-panel-header',[_vm._v(" Mostrar interacciones de los agentes ("+_vm._s(_vm.agentsInteractions.length)+") ")]),_c('v-expansion-panel-content',[_c('v-timeline',{staticClass:"pl-0 bg1 flex-grow-1 d-flex flex-column overflow-auto",attrs:{"align-top":"","dense":""}},_vm._l((_vm.agentsInteractions),function(agentsInteraction){return _c('app-bazaar-interaction',{key:agentsInteraction.id,attrs:{"interaction":agentsInteraction,"chat":_vm.chat,"active-accept":_vm.chat.seller == _vm.current_user.id},on:{"accept":function($event){return _vm.post_new_price({
                  price: agentsInteraction.description.price,
                  bazaar_id: _vm.chat.id,
                })}}})}),1)],1)],1)],1),_vm._l((_vm.driversInteractions),function(driversInteraction){return [_c('app-bazaar-interaction',{key:driversInteraction.id,attrs:{"color":"orange","interaction":driversInteraction,"chat":_vm.chat,"active-accept":_vm.chat.seller == _vm.current_user.id},on:{"accept":function($event){return _vm.onSelectInteraction(driversInteraction)}}})]})],2),_c('app-dialog',{attrs:{"title":"Desea comenzar la solicitud"},model:{value:(_vm.riseHandDialog),callback:function ($$v) {_vm.riseHandDialog=$$v},expression:"riseHandDialog"}},[_c('auto-form',{staticClass:"flex-shrink-0",attrs:{"form-data":_vm.proposal,"form-fields":_vm.text_field,"actionName":_vm.onChangePriceFormActionName,"actionColor":_vm.onChangePriceFormActionColor},on:{"submit":_vm.post_new_price}},[_c('app-chat',{attrs:{"chat":_vm.chat}})],1)],1),_c('app-dialog',{attrs:{"title":"Desea proponer un nuevo precio"},model:{value:(_vm.riseAgentProposalDialog),callback:function ($$v) {_vm.riseAgentProposalDialog=$$v},expression:"riseAgentProposalDialog"}},[_c('auto-form',{staticClass:"flex-shrink-0",attrs:{"form-data":_vm.proposal,"form-fields":_vm.text_field.slice(0, 1),"actionName":_vm.onChangePriceFormActionName,"actionColor":_vm.onChangePriceFormActionColor},on:{"submit":_vm.post_new_price}},[_c('app-chat',{attrs:{"chat":_vm.chat}})],1)],1),_c('app-dialog',{attrs:{"title":"Desea comenzar la solicitud"},model:{value:(_vm.requestDialog),callback:function ($$v) {_vm.requestDialog=$$v},expression:"requestDialog"}},[_c('auto-form',{attrs:{"readOnly":true,"editArray":['onTime'],"form-fields":_vm.request_fields,"form-data":{
        interaction_id: _vm.interaction_selected?.id,
        price: _vm.interaction_selected?.description['price'],
        dir_origen: _vm.chat_description['origen'],
        dir_destino: _vm.chat_description['destino'],
        origen: _vm.chat_description['_origen'],
        destino: _vm.chat_description['_destino'],
        pax: _vm.chat_description['pax'],
        onTime: _vm.interaction_selected?.description['onTime'],
        driver_id: _vm.interaction_selected?.user.user_id,
      }},on:{"submit":_vm.sendRequest}})],1),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }