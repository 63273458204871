<template>

</template>

<script>

import {Collection} from "@/plugins/collection";
import {services} from "@/api/data/services";
import {order_by} from "@/api/helper";

export default {
    name: "fileView",
    data: () => ({
        file_name: null,
        collection: null,
        
    }),
    beforeMount() {
        this.file_name = this.$route.params.file_name
        this.fetchData()
    },
    watch: {
        '$route.params.file_name'(newValue, oldValue) {
            if (newValue !== this.file_name) {
                this.file_name = newValue
                this.fetchData({file_name: this.file_name})
            }
        }
    },
    methods: {
        fetchData() {
            this.collection = new Collection(services, {
                ...order_by('-date', '-moment'),
            }, null, {
                ...this.active_filters
            }, null, false)

        }
    }
}

</script>

<style scoped>

</style>