<template>
  <div class="d-flex flex-column pt-3">
    <validation-observer
        tag="div"
        class="d-flex flex-row pl-3"
        :class="$slots.default? '':'pb-3'"
        v-if="appendKeys"
        ref="observer"
        v-slot="{ valid,invalid }"
    >
      <auto-field
          dense
          class="flex-grow-1"
          outlined
          hide-details
          label="new key"
          type="textField"
          v-model="newKeyTypePair.name"
      ></auto-field>
      <auto-field
          class="grow-shrink-0"
          type="selectField"
          :items="components"
          dense
          outlined
          hide-details
          label="type"
          v-model="newKeyTypePair.type"
      ></auto-field>
      <div class="px-1">
        <v-btn icon class="my-auto" @click="appendKey">
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </div>
    </validation-observer>
    <slot>

    </slot>
  </div>
</template>
<script>
import {KeyTypePair} from "@/plugins/AutoForms/tools";
import {components} from "@/plugins/AutoForms/AutoField.vue";


export default {
    name: "jsonEditField",
    methods: {
        appendKey() {
            this.$emit('appendKey', this.newKeyTypePair)
            this.newKeyTypePair = new KeyTypePair()
        }
    },
    components: {
        autoField: () => import('../AutoField.vue'),
    },
    computed: {
        components() {
            return Object.keys(components);
        }
    },
    props: {
        appendKeys: {
            type: Boolean,
            default: () => false
        }
    },
    data: () => ({
        newKeyTypePair: new KeyTypePair(),
    })
}
</script>

<style scoped>

</style>