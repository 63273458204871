<template>
  <div
    class="d-flex flex-wrap flex-row py-2 pb-3 mt-2"
    style="box-sizing: content-box;position: relative"
  >
    <span class="text--secondary caption" style="position: absolute;top: -1em;left: 0">{{ label }}</span>
    <v-container>
      <v-row>
        <v-col
          v-for="option in _options"
          :key="option.value"
        >
          <v-checkbox
            class="ma-0 pa-0"
            hide-details
            v-model="innerValue"
            v-bind="{...$attrs,...option}"
            v-on="$listeners"
          >
          </v-checkbox>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import {keyFormData} from "@/plugins/AutoForms/autoFieldMixin";

export default {
  name: "checkboxField",
  mixins: [keyFormData],
  computed: {
    _options() {
      let options = this.options ?? [];
      if (typeof options[0] === "string") {
        return options.map((option) => ({label: option, value: option}))
      }
      if (typeof options[0] === "object") {
        return options
      }
    }
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    label: String
  }
}
</script>

<style scoped>

</style>