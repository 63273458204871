<template>
  <v-autocomplete
    ref="selector"
    filled
    dense
    v-bind="$attrs"
    v-on="$listeners"
    :multiple="multiple"
    :chips="multiple"
    :deletable-chips="multiple"
    :items="items"
    :disabled="disabled || collection.loading"
    :loading="collection.loading"
    item-value="id"
    item-text="text"
    :search-input.sync="innerValue"
    hide-no-data
    hide-selected
    :clearable="!required"
    @focus="is_selected = true"
    @blur="is_selected = false"
  >
    <template v-slot:prepend>
      <div style="aspect-ratio: 1/1; min-width: 2em">
        <v-progress-circular
          v-if="sleepInterval"
          style="margin: auto"
          indeterminate
          size="16"
          width="2"
          color="primary"
        />
        <v-icon v-else> mdi-database-search</v-icon>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import { apiSelectTables } from "@/api/data/api_select_tables";
import { DEFAULT_SEARCH_DEBOUNCE } from "@/const";

export default {
  name: "ApiSelectField",
  data: () => ({
    entries: {},
    collection: null,
    sleepInterval: null,
    innerValue: "",
    is_selected: false,
  }),
  computed: {
    items() {
      let { admit_new } = this.collection.config;
      let innerValue = (this.innerValue ?? "").trim();
      if (admit_new && innerValue.length > 0) {
        return Object.values({
          [innerValue]: innerValue,
          ...this.entries,
        });
      }
      return Object.values(this.entries);
    },
  },
  watch: {
    innerValue(newVal, oldVal) {
      this.onSearch(newVal);
    },
  },
  props: {
    required: {
      type: Boolean,
      default: () => false,
    },
    lazy: { type: Boolean | Number, default: () => true },
    disabled: {
      type: Boolean,
    },
    multiple: {
      type: Boolean,
    },
    rules: {},
    collectionSelector: {
      type: String,
    },
    foreign_key: {},
  },
  created() {
    this.collection = apiSelectTables(this.collectionSelector);
    if (this.collection) {
      this.loadingOn(this.fetchData({}));
    } else {
      console.log("collection not found", this.collectionSelector);
    }

    if (this.lazy) {
      if (typeof this.lazy === "boolean")
        this.debounce = DEFAULT_SEARCH_DEBOUNCE;
      if (typeof this.lazy === "number") this.debounce = this.lazy;

      this.sleepInterval = setTimeout(() => {
        this.sleepInterval = null;
      }, this.debounce);
    }
  },
  beforeDestroy() {
    clearInterval(this.sleepInterval);
  },
  methods: {
    resetInterval() {
      clearTimeout(this.sleepInterval);
      this.sleepInterval = null;
    },
    search(value) {
      this.resetInterval();
      this.fetchData({ like: value });
    },
    onSearch(value) {
      if (this.lazy) {
        this.resetInterval();
        this.sleepInterval = setTimeout(() => {
          this.search(value);
          this.sleepInterval = null;
        }, this.debounce);
      } else {
        this.search(value);
      }
    },
    fix_entries(items, is_selected) {
      this.entries = {
        ...this.entries,
        ...items.reduce((carr, curt) => ({ ...carr, [curt.id]: curt }), {}),
      };
      if (is_selected) {
        this.$refs.selector?.focus();
      }
    },
    fetchData({ like }) {
      let is_selected = this.is_selected;
      if (this.collection) {
        if (like?.length > 0) {
          return this.collection.get({ like }).then((items) => {
            this.fix_entries(items, is_selected);
          });
        } else {
          return this.collection
            .get({
              id: this.$attrs.value,
            })
            .then((items) => this.fix_entries(items, is_selected));
        }
      } else {
        console.warn("collection isn't already created");
      }
    },
  },
};
</script>

<style scoped></style>
