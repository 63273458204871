import Vue from "vue";
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import {
  double,
  email,
  numeric,
  regex,
  required,
} from "vee-validate/dist/rules";

// No message specified.
extend("email", email);
extend("numeric", numeric);
extend("regex", regex);
extend("double", double);

// Override the default message.
extend("required", {
  ...required,
  message: "This field is required",
});

extend("equalTo", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Password confirmation does not match",
});

extend("password", {
  validate(value) {
    // Validación de longitud
    if (!/^.{8,}$/.test(value)) {
      return "La contraseña debe tener al menos 8 caracteres.";
    }
    // Validación de letra mayúscula
    if (!/[A-Z]/.test(value)) {
      return "La contraseña debe incluir al menos una letra mayúscula.";
    }
    // Validación de letra minúscula
    if (!/[a-z]/.test(value)) {
      return "La contraseña debe incluir al menos una letra minúscula.";
    }
    // Validación de número
    if (!/[0-9]/.test(value)) {
      return "La contraseña debe contener al menos un número.";
    }
    // Validación de caracter especial
    if (!/[\\^$*.\[\]{}()?"!@#%&/,><':;|_~`]/.test(value)) {
      return "La contraseña debe contener al menos un caracter especial.";
    }
    // Validación de espacios en blanco
    if (!/^\S*$/.test(value)) {
      return "La contraseña no debe contener espacios en blanco.";
    }

    // Si todas las validaciones pasan, retornar true
    return true;
  },
  message: "{_field_} no cumple con los criterios de seguridad establecidos.",
});

extend("max_length", {
  params: ["max_length"],
  validate(value, { max_length }) {
    return value.length <= max_length;
  },
  message: "Excede la cantidad de caracteres",
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

setInteractionMode('eager');

// import {required, digits, email, max, regex} from 'vee-validate/dist/rules.esm'
//
// Validator.extend('digits', {
//     ...digits,
//     message: '{_field_} needs to be {length} digits. ({_value_})',
// })
//
// Validator.extend('required', {
//     ...required,
//     message: '{_field_} can not be empty',
// })
//
// Validator.extend('max', {
//     ...max,
//     message: '{_field_} may not be greater than {length} characters',
// })
//
// Validator.extend('regex', {
//     ...regex,
//     message: '{_field_} {_value_} does not match {regex}',
// })
//
// Validator.extend('email', {
//     ...email,
//     message: 'Email must be valid',
// })