<template>
  <v-stepper v-model="stepper_model">
    <v-stepper-header>
      <v-stepper-step
        :complete="stepper_model > 1"
        step="1"
      >
        Campos básicos
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="stepper_model > 2"
        step="2"
      >
        campos avanzados
      </v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1">
        <auto-form
          ref="step_1_form"
          action-name="siguiente"
          :form-fields="fields_step_1" @submit="save_progress"
        >
        </auto-form>
      </v-stepper-content>
      <v-stepper-content step="2">
        <auto-form
          ref="step_2_form"
          :form-fields="fields_step_2" @submit="addNewBazaar"
        >
        </auto-form>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import AutoForm from "@/plugins/AutoForms/AutoForm.vue"
import {
  RADIO_FIELD,
  DOUBLE_PRECISION_TYPE_FIELD,
  FormFieldItem,
  INTEGER_TYPE_FIELD,
  JSON_FIELD, CHECKBOX_FIELD
} from "@/plugins/AutoForms/tools";
import {advance_description_service, create_service_fields} from "@/views/bazaar/components/create_bazzar_fields";

export default {
  name: 'app-create-new-bazzar-dialog-content',
  data: () => ({
    stepper_model: 1,
    data: {},
    fields_step_1: create_service_fields,
    fields_step_2: advance_description_service,
  }),
  components: {AutoForm},
  methods: {
    save_progress({_origen, _destino, ...rest}, autoform) {
      let description = {};
      let origen = _origen?.split(',')[0];
      if (origen) {
        description["Municipio Origen"] = _origen.slice(origen.length + 1);
        description["_origen"] = parseInt(origen);
      }

      let destino = _destino?.split(',')[0];
      if (destino) {
        description["Municipio Destino"] = _destino.slice(destino.length + 1);
        description["_destino"] = parseInt(destino);
      }
      this.data = {
        description: {
          ...description,
          ...rest
        }
      };
      this.stepper_model = 2

    },
    addNewBazaar(data, autoform) {
      this.data.description = {
        ...this.data.description,
        ...data
      }

      this.$emit('create', this.data, this)
    },
    flush() {
      this.data = {};
      this.stepper_model = 1;
      this.$refs.step_1_form.loadInnerValue();
      this.$refs.step_2_form.loadInnerValue();
    }
  }
}
</script>