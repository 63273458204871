<template>
  <v-combobox
      dense
      filled
      chips
      clearable
      multiple
      v-model="innerValue"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <template v-slot:selection="{ attrs, item, select, selected }">
      <v-chip
          close-icon="mdi-delete"
          v-bind="attrs"
          :input-value="selected"
          close
          @click="select"
          @click:close="remove(item)"

      >
        <strong>{{ item }}</strong>
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
import {keyFormData} from "@/plugins/AutoForms/autoFieldMixin";
import {LIST_FIELD} from "@/plugins/AutoForms/tools";

export default {
    name: LIST_FIELD,
    props: ['rules'],
    mixins: [keyFormData],
    data: () => ({
        innerValue: [],
    }),
    beforeMount() {
        this.innerValue = [...this.value];
    },
    methods: {
        remove(item) {
            this.innerValue.splice(this.innerValue.indexOf(item), 1)
        },
    }
}
</script>

<style scoped>

</style>