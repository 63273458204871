<template>
  <app-basic-layout>
    <template v-slot:head>
      <h2>Agent Facture</h2>
    </template>
    <v-progress-linear indeterminate v-if="is_loading"></v-progress-linear>
    <v-container fluid class="flex-grow-1 ">
      <v-expansion-panels v-model="model">
        <v-expansion-panel class="my-1">
          <v-expansion-panel-header color="green">
            <h3 class="white--text text-uppercase">
              Payed
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0">
            <app-data-table
                item-key="id"
                :allow-download="true"
                ref="agent_payed_facture_collection"
                :collection="agent_payed_collection"
                :hide-header="false"
                height="400"
            ></app-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="my-1">
          <v-expansion-panel-header color="orange">
            <h3 class="white--text text-uppercase">
              collaborations services
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0">
            <app-data-table
                item-key="id"
                :hide-header="false"
                height="400"

                :allow-download="true"

                ref="collaborator_collection"
                :collection="collaborator_collection"
                v-on="{
                  ...(isSuperUser? {
                    actionOnGroup:(items)=>agent_pay(collaborator_collection,items)
                  }:{})
                }"
                group-by="agency"
                :group-resume="(items)=>monetize(items.map(i=>i.value).reduce((a,b)=>a+b,0))"

            ></app-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="my-1">
          <v-expansion-panel-header color="blue">
            <h3 class="white--text text-uppercase">
              Agents services
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0">
            <app-data-table
                item-key="id"
                :hide-header="false"
                height="400"
                :allow-download="true"
                ref="agent_pay_collection"
                :collection="agent_pay_collection"
                group-by="user_id.username"
                :loading="is_loading"
                v-on="{
                  ...(isSuperUser? {
                    actionOnGroup:(items)=>agent_pay(agent_pay_collection,items)
                  }:{})
                }"
                :group-resume="(items)=>monetize(items.map(i=>i.value).reduce((a,b)=>a+b,0))"

            ></app-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </app-basic-layout>
</template>

<script>
import AppDialog from "@/components/molecules/appDialog";
import TextField from "@/plugins/AutoForms/FormFields/textField";
import AppDataTable from "@/components/organisms/AppDataTable";
import appBasicLayout from "@/components/templates/appBasicLayout";
import autoForm from "@/plugins/AutoForms/AutoForm";
import {Collection} from "@/plugins/collection";
import {facture} from "@/api/data/facture";
import ValueCircle from "@/components/molecules/valueCircle"

import {agg, group_by, SUM, extend, values_list, order_by} from "@/api/helper";
import {monetize, timeStampHelper, yesNo} from "@/plugins/filters";
import {session} from "@/api/auth";

export default {
  name: "AppFactureView",
  components: {AppDialog, TextField, AppDataTable, appBasicLayout, autoForm, ValueCircle},

  data: () => ({
    agent_payed_collection: new Collection(facture, {
          // pagados
          // filters
          type: 'debt_pay',
          'parent_id.type': [
            'night_payed_service',
            'agent_collaboration',
            'payed_service',
            'collaboration'
          ],

          // config
          limit: -1,

          // params
          ...extend('parent_id', 'user_id.username', 'user_agent'),
          ...values_list(
              'id',
              'user_id.username',
              'parent_id.type',
              'value',
              'introduced_at',
              'parent_id.introduced_at',
              'user_agent.is_collaborator',
              'user_agent.constalation_name'
          )
        },
        {
          'parent_id.introduced_at': {
            ...timeStampHelper(),
            rename: 'facture_date'
          },
          'introduced_at': {
            rename: 'payed_date'
          },
          'user_id.username': {
            rename: 'username'
          },
          'user_agent.constalation_name': {
            rename: 'constalation_name'
          },
          'user_agent.is_collaborator': {
            rename: 'is_collaborator',
            filter: yesNo
          }
        }),
    collaborator_collection: new Collection(facture, {
          // collaboration por pagar
          // filters
          type: 'collaboration',
          facture__isnull: true,

          // config
          limit: -1,

          // params
          ...extend('parent_id', 'user_id.username', 'facture', 'user_agent'),
          ...values_list('id', 'user_id', 'user_id.username', 'value', 'introduced_at','agency', 'parent_id.introduced_at'),
          ...order_by('agency')
        },
        {
          'parent_id.introduced_at': {
            ...timeStampHelper(),
            rename: 'facture_date'
          },
          'introduced_at': {
            rename: 'payed_date'
          },
          'user_id.username': {
            rename: 'username'
          },
          'user_agent.constalation_name': {
            rename: 'constalation_name'
          }
        }),
    agent_pay_collection: new Collection(facture, {
          // pagados
          // filters
          type: ['night_payed_service', 'agent_collaboration', 'payed_service'],
          facture__isnull: true,

          // config
          limit: -1,

          // params
          ...order_by('user_id.username', 'introduced_at'),
          ...extend('parent_id', 'user_id.username', 'facture', 'user_agent'),
          ...values_list('id', 'user_id', 'type', 'user_id.username', 'value', 'introduced_at', 'parent_id.introduced_at')
        },
        {
          'parent_id.introduced_at': {
            ...timeStampHelper(),
            rename: 'facture_date'
          },
          'introduced_at': {
            rename: 'payed_date'
          },
          'user_id.username': {
            rename: 'agent_name'
          }
        }),
    model: -1,

    night_service: 0,
    collaboration_services: 0,
    agent_service: 0,
    agent_collaboration_services: 0,
    payed_services: 0,
  }),
  computed: {
    isSuperUser() {
      return session.isSuperUser;
    },

    total() {
      return [
        this.agent_collaboration_services,
        this.night_service,
        this.collaboration_services,
        this.agent_service
      ].reduce((a, b) => a + b, 0)
    },
    xs_viewing() {
      return this.$vuetify.breakpoint.xs
    },
  },
  methods: {
    agent_pay(collection, items) {
      this.loadingOn(
          ...items.map(
              ({id, user_id, value}) =>
                  collection.create(
                      {
                        type: "debt_pay",
                        parent_id: id,
                        user_id: user_id,
                        value: value
                      }
                  )
          )
      ).finally(() => {
        this.loadingOn(
            // collection.fetchData(),
            this.$refs['agent_payed_facture_collection']?.fetchData(),
            this.$refs['collaborator_collection']?.fetchData(),
            this.$refs['agent_pay_collection']?.fetchData(),
        )
      })
    },
    monetize,
    search_debt(collection, text) {
      if (text.trim() == '') {
        collection.clear_filters(['like', 'like_cols']);
      } else {
        collection.filter({
          like: text,
          like_cols: [
            'user_id.username',
          ],
        })
      }
    }
  }
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}

#detail-view {
  width: 100%;
}

#detail-view th {
  border-bottom: slategray solid 1px;
  padding: .2em;
}

#detail-view td {
  border-bottom: slategray solid 1px;
  border-bottom-color: #ccc;
  padding: .1em;
}

/*#detail-view td:first-child{*/
/*  padding: .1em;*/
/*  text-align: right;*/

/*}*/

/*value circles operators and result*/
.value-circle-operator {
  padding-top: .3em;
  font-size: 1.8em;
}

.total-incentive {
  padding-top: .4em;
}

@media (max-width: 599px) {
  .total-incentive {
    font-size: 2em;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  .value-circle-operator {
    font-size: 1.3em;
    padding-top: .4em;
  }

  .total-incentive {
    font-size: 1.2em;
    padding-top: 1em;
  }
}

@media (min-width: 1904px) {
  .value-circle-operator {
    font-size: 2em;
    padding-top: .1em;
  }

  .total-incentive {
    font-size: 3em;
    padding-top: .05em;
  }
}


</style>