import axios from "axios";
import qs from "qs";
import {requestInterceptor, responseInterceptor} from "@/plugins/axios/axiosInterceptors";
import {isDevelopmentMode} from "@/const";

// const DEFAULT_BACKEND_URL = "https://portal.cubasolarte.com/solarte";
const DEFAULT_BACKEND_URL = "http://localhost:9000/app";

axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || DEFAULT_BACKEND_URL;
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export const baseUri = isDevelopmentMode
    ? process.env.VUE_APP_BACKEND_URL || DEFAULT_BACKEND_URL
    : "/solarte"

let config = {
    baseURL: baseUri,
    // baseURL: process.env.baseURL || process.env.apiUrl || ""
    // timeout: 60 * 1000, // Timeout
    withCredentials: true, // Check cross-site Access-Control,
    paramsSerializer: (params) => qs.stringify(params, {arrayFormat: "repeat"}),
};

export function get_websocketUrl(path, data) {
    path = path || "";
    data = data || {};

    const l = window.location;
    let host = isDevelopmentMode ? `ws://${l.hostname}:9000/connection/websocket` : `wss://${l.hostname}/connection/websocket`;

    if (path !== "" && path[0] !== "/") {
        throw new Error("path must start with /");
    }
     if (Object.keys(data).length>0){
         let params = new URLSearchParams(data)
         return `${host}${path}?${params.toString()}`;
     }

    return `${host}${path}`;
}

export const _axios = axios.create(config);

export const _axiosBlob = axios.create({
    ...config,
    responseType: "blob",
});

responseInterceptor.forEach(function ({response, error}) {
    _axios.interceptors.response.use(response, error);
    _axiosBlob.interceptors.response.use(response, error);
});

requestInterceptor.forEach(function ({request}) {
    _axios.interceptors.request.use(request);
    _axiosBlob.interceptors.request.use(request);
});