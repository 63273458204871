import {
  CHECKBOX_FIELD,
  DOUBLE_PRECISION_TYPE_FIELD,
  FormFieldItem,
  INTEGER_TYPE_FIELD,
  RADIO_FIELD,
} from "@/plugins/AutoForms/tools";

export const create_service_fields = [
  new FormFieldItem({
    text: "price",
    type: DOUBLE_PRECISION_TYPE_FIELD,
    extend: {
      cols: { md: 6 },
    },
  }),
  new FormFieldItem({
    text: "No. Teléfono",
    type: "VARCHAR(255)",
    keyData: "phone_number",
    extend: {
      cols: { md: 6 },
    },
    nullable: false,
  }),
  new FormFieldItem({
    text: "cantidad de pasajeros",
    nullable: true,
    type: INTEGER_TYPE_FIELD,
    keyData: "pax",
    extend: {
      cols: { md: 6 },
    },
  }),
  new FormFieldItem({
    text: "No. de la casa origen",
    type: "VARCHAR(255)",
    keyData: "Number",
    extend: {
      cols: { md: 6 },
    },
  }),
  new FormFieldItem({
    text: "Dir. origen",
    type: "VARCHAR(255)",
    keyData: "origen",
    nullable: false,
  }),
  new FormFieldItem({
    text: "Mun. origen",
    keyData: "_origen",
    type: "VARCHAR(255)",
    nullable: false,
    foreign_key: { table: "locations_ext" },
  }),
  new FormFieldItem({
    text: "Mun. destino",
    keyData: "_destino",
    type: "VARCHAR(255)",
    nullable: false,
    foreign_key: { table: "locations_ext" },
  }),
  new FormFieldItem({
    text: "Dir. Destino",
    type: "VARCHAR(255)",
    keyData: "destino",
    nullable: false,
  }),
  new FormFieldItem({
    text: "Es urgente??",
    description:
      "Este campo es solo para hospitales, Si es urgente, el conductor deberá llegar lo más pronto posible",
    type: RADIO_FIELD,
    keyData: "is_urgent",
    nullable: false,
    default_value: () => false,
  }),
];
export const advance_description_service = [
  new FormFieldItem({
    text: "Servicio de Mensajería",
    type: RADIO_FIELD,
    keyData: "Mensajeria",
    nullable: false,
    default_value: () => false,
  }),
  new FormFieldItem({
    text: "Tipo de vehiculo",
    type: CHECKBOX_FIELD,
    keyData: "Tipo de vehiculo",
    multiple: true,
    options: ["Moto y triciclo", "Carro 4 plazas", "Microbús"],
    default_value: () => ["Moto y triciclo", "Carro 4 plazas", "Microbús"],
    nullable: true,
  }),
  new FormFieldItem({
    text: "Servicio premium",
    type: CHECKBOX_FIELD,
    keyData: "servicio premium",
    options: ["Convertible", "Techo duro", "climatizado"],
    nullable: true,
  }),
  new FormFieldItem({
    text: "Extras",
    type: CHECKBOX_FIELD,
    keyData: "extras",
    nullable: true,
    multiple: true,
    options: ["Parrilla", "Mascota", "Trailer", "Equipaje"],
  }),
];
