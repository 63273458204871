<template>
  <app-basic-layout :loading="collection.loading">
    <template v-slot:head>
      <v-btn icon @click="$router.back()">
        <v-icon>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <h2>Service Details</h2>
    </template>
    <auto-form
        :read-only="can_edit"
        :loading-action="collection.loading"
        v-if="!collection.loading"
        class="fill-height overflow-auto"
        :form-fields="collection.formFields"
        :exclude="['id']"
        :disabled="service.is_lock||!!service['payed_service.payed_at']"
        :form-data="service"
        @submit="updateItem"
    />
  </app-basic-layout>
</template>

<script>
import AppBasicLayout from "@/components/templates/appBasicLayout.vue";
import {Collection} from "@/plugins/collection";
import {services} from "@/api/data/services";
import autoForm from "@/plugins/AutoForms/AutoForm.vue";
import {session} from "@/api/auth";

export default {
    name: "serviceDetail",
    components: {AppBasicLayout, autoForm},
    watch: {
        '$route.params.service_id': (newVal, oldVal) => {
            if (newVal != oldVal) {
                this.fetchData()
            }
        }
    },
    computed: {
        can_edit() {
            return session.me.is_superuser || session.me.agent?.isActive;
        },
    },
    data: () => ({
        collection: new Collection(services),
        service: {},
        service_id: null,
    }),

    beforeMount() {
        this.service_id = this.$route.params.service_id
        this.fetchData()
    },
    methods: {
        updateItem(obj) {
            this.collection.update(
                {id: this.service_id}, obj
            ).then(res => {
                this.$router.back();
                this.fetchData();
                return res;

            })
        },
        fetchData() {
            this.collection.fetchData(
                {
                    id: this.service_id
                }
            ).then(collection => {
                this.service = collection.items[0]
            })
        }
    }
}
</script>

<style scoped>

</style>