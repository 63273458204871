<template>
  <v-btn v-on="$listeners" text outlined x-small class="rounded-t-circle" height="80">
    <div class="d-flex flex-column">
      <slot name="icon" v-bind="{color,classStyle,icon}">
        <v-icon :color="color" size="40">{{ icon }}</v-icon>
      </slot>
      <h4 :class="classStyle" class="mt-1"> {{ text }}</h4>
    </div>
  </v-btn>
</template>
<script>
export default {
    name: 'app-toolbar-btn',
    props: {
        text: {type: String},
        icon: {type: String},
        color: {type: String},
        colorText: {type: String}
    },
    computed: {
        classStyle() {
            if (this.colorText) {
                return `${this.colorText}--text`;
            }
            return ''
        }
    }
}
</script>