<template>
  <app-dialog
      :mini="true"
      v-model="active" title="Confirm your password">
    <template v-slot:activator="{on,attr}">
      <v-btn
          name="activator"
          v-bind="{...attr,...$attrs}"
          v-on="on"
          :style="$vnode.data.staticStyle"
      >
        <slot></slot>
      </v-btn>
    </template>
    <v-container class="fill-height">
      <v-row>
        <v-col cols="12">
          <slot name="top">

          </slot>
        </v-col>
        <v-col cols="12">
          <auto-form
              class="ma-auto"
              :loading-action="is_loading"
              :formFields="formFields"
              @submit="validate_user"
          />
        </v-col>
      </v-row>
    </v-container>
  </app-dialog>
</template>

<script>
import AppDialog from "@/components/molecules/appDialog.vue";
import {FormFieldItem, PASSWORD_FIELD} from "@/plugins/AutoForms/tools";
import AutoForm from "@/plugins/AutoForms/AutoForm.vue";
import {_axios} from "@/plugins/axios";

export default {
    inheritAttrs: false,
    name: "app-protected-btn",
    components: {AutoForm, AppDialog},
    data: () => ({
        formFields: [
            new FormFieldItem({
                type: PASSWORD_FIELD,
                text: 'password'
            })
        ],
        active: false
    }),
    beforeMount() {
        if (!this.$listeners.submit) {
            console.warn("⚠️ Submit isn't define ⚠️")
        }
    },
    methods: {
        validate_user(obj) {
            this.loadingOn(
                _axios.post(
                    '/validate_password', obj
                ).then(
                    (res) => {
                        this.$emit("submit", res.data)
                        this.active = false;
                    }).catch(() => {
                    this.$alert.error("password incorrecto");
                })
            );
        }
    }
}
</script>

<style scoped>

</style>