<template>
  <div class="my-auto flex-grow-1 d-flex flex-column">
    <good-look-json
        v-if="_isObject"
        :inverse-color="inverseColor"
        :data="_innerValue"
        :global-format="{type:'row'}"
    ></good-look-json>
    <template v-else-if="_isArrayObject">
      <template v-for="(_,index) in _innerValue">
        <sub-json-field
            :inverse-color="inverseColor"
            :key="`${index}`"
            :text-header="`index - ${index}`"
            v-model="_innerValue[index]"
            :disabled="true"
        >
        </sub-json-field>
      </template>
    </template>
    <v-chip-group
        v-else-if="Array.isArray(_innerValue)"
        class="px-0 my-0 my-0"
        column
    >
      <v-chip
          class="px-2 my-0 my-0"
          v-for="tag in _innerValue"
          :key="tag"
      >
        {{ tag }}
      </v-chip>
    </v-chip-group>
    <span v-else class="text-body-1 px-2 ">
      {{ _innerValue }}
    </span>
  </div>
</template>
<script>

import AppBasicRow from "@/plugins/AutoForms/app-basic-row.vue";

export default {
    name: 'app-good-look-value',
    components: {
        AppBasicRow,
        SubJsonField: () => import("@/plugins/AutoForms/FormFields/subJsonField.vue"),
        goodLookJson: () => import("@/components/molecules/goodLookJson.vue")
    },
    props: {
        filter: {type: Function},
        value: {},
        inverseColor: {
            type: Boolean,
            default: () => false
        },
    },
    computed:
        {
            _isObject() {
                let _value = this._innerValue;
                return (!!_value && _value.constructor === Object)
            },
            _isArrayObject() {
                let _value = this._innerValue;
                if (Array.isArray(_value) && _value.length > 0) {
                    let item0 = _value[0];
                    return (!!item0 && item0.constructor === Object)
                }
                return false
            },
            _innerValue() {
                if (this.filter) {
                    return this.filter(this.value)
                }
                return this.value
            }
        }
}
</script>
