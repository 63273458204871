import {blobDomLink, downloadBlob} from "./tools";

const DownloadFileMixin = {
  methods: {
    _buildBlobAndDownload(_blob, fileName, type) {
      blobDomLink(_blob, fileName, type);
    }
  },
};

const MyDownloadFilePlugin = {
  install(Vue, options) {
    Vue.mixin(DownloadFileMixin);
  },
};

export default MyDownloadFilePlugin;
