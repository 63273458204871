export const SUM = 'sum';
export const COUNT = 'count';
export const NESTED = 'nested';
export const EXTEND = 'extend';
export const GROUPBY = 'group_by';
export const ORDERBY = 'order_by';
export const LIMIT = 'limit';
export const VALUESLIST = 'values_list';
export const HEADERS = 'headers';

export const SKIP_KEYS = new Set([
    SUM,
    COUNT,
    NESTED,
    EXTEND,
    GROUPBY,
    ORDERBY,
    LIMIT,
    HEADERS,
    "page"
])

export function agg(field_name, func) {
    return {
        [`agg__${field_name}`]: func
    }
}

export function group_by(...fields) {
    return {
        [GROUPBY]: fields
    }
}

export function order_by(...fields) {
    return {
        [ORDERBY]: fields
    }
}

export function limit(field) {
    return {
        [LIMIT]: field
    }
}

export function nested(...fields) {
    return {
        [NESTED]: fields
    }
}

export function extend(...fields) {
    return {
        [EXTEND]: fields
    }
}

export function values_list(...fields) {
    return {
        [VALUESLIST]: fields
    }
}

export function headers(value) {
    return {
        [HEADERS]: !!value
    }
}
