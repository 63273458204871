<template>
  <div v-if="json_data.length>0" class="d-flex flex-column">
    <v-row class="ma-0 flex-grow-1">
      <v-col
          class="px-1"
          v-for="{key,value,format} in json_data"
          :key="key"
          cols="12"
          v-bind="formatColGenerator(format)"
      >
        <div class="d-flex flex-row">
          <v-btn v-if="$listeners.close"
                 icon
                 @click="$emit('close',key)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <div class="flex-grow-1 d-flex flex-column justify-end px-1">
            <slot :name="`head.${key}`" v-bind="{key,value,format}">
              <div class="text-center text-truncate fill-height d-flex flex-column"
                   v-bind="formatHeaderGenerator(format)"
              >
                <span class="my-auto">
                  {{ format.rename ?? key }}:
                </span>
              </div>
            </slot>
          </div>
        </div>
        <div :class="!inverseColor? 'tertiary black--text':'primary white--text'"
             :style="{ borderRadius: '12px', borderColor:_color }"
             class="pa-1 d-flex flex-column flex-grow-1"
             v-bind="formatValueGenerator(format)"
        >
          <slot :name="`item.${key}`" v-bind="{value,key}">
            <app-good-look-value
                :inverse-color="!inverseColor"
                :filter="format?.filter"
                :value="value"
            />
          </slot>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppGoodLookValue from "@/components/molecules/AppGoodLookValue.vue";

export default {
    name: "goodLookJson",
    components: {AppGoodLookValue},
    methods: {
        formatColGenerator(format) {
            return {
                class: ['d-flex', format.type === 'row' ? 'flex-row pa-1' : 'flex-column py-1 px-3'],
                ...format.cols
            }
        },
        formatHeaderGenerator(format) {
            return {
                class: [
                    format.type === 'row' ? 'header-row' : 'header-col'
                ],
                ...format.cols
            }
        },
        formatValueGenerator(format) {
            return {
                class: [format.type === 'row' ? 'value-row' : 'value-col'],
                ...format.cols
            }
        },
    },
    computed: {
        _headers() {
            return this.headers.reduce(
                (carr, curt) => ({
                    [curt.value]: curt,
                    ...carr
                }), {}
            )
        },
        _color() {
            let {primary, tertiary} = this.$vuetify.theme.currentTheme;
            return this.inverseColor ? primary : tertiary;
        },
        json_data: function () {
            if ((this.data == null)) {
                return []
            }

            let keys = Object.keys(this.data);

            if (this.order) {
                let temp = [...keys];
                keys = [...this.order.reduce((carr, curt) => {
                    let index = temp.findIndex(v => v == curt);
                    if (index >= 0) {
                        carr.push(curt)
                        temp.splice(index, 1)
                    }
                    return carr
                }, []), ...temp];
            }

            if (this.hidden) {
                keys = keys.filter(
                    (key) => !this.hidden.includes(key)
                )
            }
            return keys.filter(key => {
                if (this.headers.length == 0) {
                    return true
                }
                return key in this._headers;
            }).map(
                key => {
                    let {extend} = this._headers[key] ?? {};
                    let {rename, filter} = extend ?? {};
                    if (filter == null)
                        filter = v => v

                    return {
                        key: rename ?? key,
                        value: filter(this.data[key]),
                        format: {
                            ...this.globalFormat, ...this.format[key] ?? {}
                        }
                    }
                });
        }
    },
    props: {
        headers: {
            type: Array,
            default: () => []
        },
        inverseColor: {
            type: Boolean,
            default: () => false
        },
        data: {
            type: Object,
            default: () => ({})
        },
        hidden: {
            type: Array,
            default: () => []
        },
        format: {
            type: Object,
            required: false,
            default: () => ({})
        },
        globalFormat: {
            type: Object,
            required: false,
            default: () => ({})
        },
        order: {
            type: Array,
            required: false,
            default: () => ([])
        }
    }
}
</script>

<style scoped>
.header-col {
    border-bottom: solid 1px
}

.header-row {
    flex-grow: 0;
    flex-shrink: 0;
    border: solid 1px;
    border-radius: 12px;
    padding: 0 0.3em;
}

.value-col {
}

.value-row {
    flex-grow: 1;
}
</style>