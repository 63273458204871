const JSON_TYPE_FIELD = 'JSON';
const INTEGER_TYPE_FIELD = 'INTEGER';
const DOUBLE_PRECISION_TYPE_FIELD = 'DOUBLE PRECISION';
const BOOLEAN_TYPE_FIELD = 'BOOLEAN';
const TIMESTAMP_TYPE_FIELD = 'TIMESTAMP';
const TIME_TYPE_FIELD = 'TIME';
const DATE_TYPE_FIELD = 'DATE';
const VARCHAR_TYPE_FIELD = /VARCHAR\((\d+)\)/;

const JSON_FIELD = 'jsonField';
const SELECT_FIELD = 'selectField';
const RADIO_FIELD = 'radioField';
const CHECKBOX_FIELD = 'checkboxField';
const TEXT_FIELD = 'textField';
const PASSWORD_FIELD = 'passwordField';
const API_SELECT_FIELD = 'apiSelectField';
const TIMESTAMP_FIELD = 'timestampField';
const TIME_FIELD = 'timeField';
const DATE_FIELD = 'dateField';
const LIST_FIELD = 'listField';
const TEXTAREA_FIELD = 'textareaField';
export { //FIELD
  JSON_FIELD,
  SELECT_FIELD,
  RADIO_FIELD,
  CHECKBOX_FIELD,
  TEXT_FIELD,
  PASSWORD_FIELD,
  API_SELECT_FIELD,
  TIMESTAMP_FIELD,
  TIME_FIELD,
  DATE_FIELD,
  LIST_FIELD,
  TEXTAREA_FIELD
};
export {//TYPE
  JSON_TYPE_FIELD,
  INTEGER_TYPE_FIELD,
  DOUBLE_PRECISION_TYPE_FIELD,
  BOOLEAN_TYPE_FIELD,
  TIMESTAMP_TYPE_FIELD,
  TIME_TYPE_FIELD,
  DATE_TYPE_FIELD,
}

export class FormFieldItem {
  constructor(
    {
      type,
      text,
      define_field,
      skip_on_create = false,
      foreign_key = null,
      nullable = true,
      extend = {},
      keyData = null,
      rules = null,
      disabled = false,
      hidden = false,
      range = false,
      icon = true,
      multiple = false,
      default_value = null,
      description = null,
      dependes_on = null,
      options = null,
    }) {
    rules = rules ?? {};
    this.options = options;
    this.appendKeys = false;
    this.default_value = default_value ?? (() => null);
    this.description = description ?? '';
    this.dependes_on = dependes_on ?? (() => true)
    this.foreign_key = foreign_key;
    this.collectionSelector = null
    this.extend = extend ?? {};
    this.required = !(this.extend.nullable ?? nullable);
    this.disabled = disabled;
    this.hidden = hidden;
    this.icon = icon;
    this.multiple = multiple;
    this.rules = {
      required: this.required,
      // digits: 7,
      // regex: '^(71|72|74|76|81|82|84|85|86|87|88|89)\\d{5}$'
      ...rules
    };

    if (define_field) {
      let {dtype, foreign_key, ...config} = define_field;
      this.config = config;
      this.foreign_key = foreign_key;
      this.type = this.getFormType(dtype ?? type, this.foreign_key)
    } else {
      this.type = this.getFormType(type, foreign_key)
    }
    this.skip_on_create = skip_on_create;
    this.label = text;
    this.keyData = keyData ?? text;
    this.label = this.label ?? this.keyData;
    this.range = range;
  }

  data() {
    return {
      [this.keyData]: this.get_dtype()
    }
  }

  get_dtype() {
    switch (this.getFormType(this.type)) {
      case RADIO_FIELD:
        return false;
      case JSON_FIELD:
        return {}
      default:
        return this.required ? "" : null;
    }
  }

  getFormType(type, foreign_key) {
    if (foreign_key) {
      this.collectionSelector = foreign_key.table
      return API_SELECT_FIELD
    }

    // console.log(type);
    switch (type) {
      case LIST_FIELD:
        return LIST_FIELD;
      case JSON_TYPE_FIELD:
      case JSON_FIELD:
        this.appendKeys = true;
        this.formFields = [];
        this.rules = {
          required: false,
        }
        return JSON_FIELD
      case SELECT_FIELD:
        this.items = this.config.items;
        return SELECT_FIELD
      case TIMESTAMP_TYPE_FIELD:
      case TIMESTAMP_FIELD:
        return TIMESTAMP_FIELD
      case TIME_TYPE_FIELD:
      case TIME_FIELD:
        return TIME_FIELD
      case DATE_TYPE_FIELD:
      case DATE_FIELD:
        return DATE_FIELD
      case BOOLEAN_TYPE_FIELD:
      case RADIO_FIELD:
        return RADIO_FIELD
      case CHECKBOX_FIELD:
        return CHECKBOX_FIELD
      case PASSWORD_FIELD:
        return PASSWORD_FIELD
      case INTEGER_TYPE_FIELD:
        this.rules = {
          ...this.rules,
          numeric: true,
          regex: /^[0-9]+$/,
        }

        return TEXT_FIELD
      case DOUBLE_PRECISION_TYPE_FIELD:
        this.rules = {
          ...this.rules,
          regex: /^-?\d+(\.\d+)?$/,
          double: true
        }
        return TEXT_FIELD
      default:
        const match = type?.match(VARCHAR_TYPE_FIELD);
        if (match) {
          let max_length = parseInt(match[1])
          let a = [].length
          this.extend = {
            max_length,
            ...this.extend
          }
          this.rules = {
            max_length,
            ...this.rules
          }
          if (max_length > 100) {
            return TEXTAREA_FIELD
          }
        }
        return TEXT_FIELD
    }
  }
}

export class KeyTypePair {
  constructor() {
    this.name = ""
    this.type = ""
  }
}

