<script setup>
import {ref} from 'vue'

import {service_operation} from "@/api/data/services";
import AppDialog from "@/components/molecules/appDialog.vue";

const props = defineProps({
    'service': {type: Object, required: true}
})
const emit = defineEmits(['submit']);
let activeModal = ref(false);
let loading = ref(false);


async function turnDoneService() {
    loading.value = true;
    let {service} = props;
    let {id} = service;
    try {
        await service_operation.update(
            {service_id: id}
        );
        emit('submit', {service_id: id});
        activeModal.value = false;
    } finally {
        loading.value = false;
    }
}

</script>

<template>
  <app-dialog :persistent="loading" :mini="true" v-model="activeModal">
    <template v-slot:title>
      <h5 class="text-center mx-auto">
        ¿El viaje finalizó?
      </h5>
    </template>
    <template v-slot:activator="{on}">
      <v-btn v-bind="$attrs" v-on="on" class="white--text" color="primary">
        finalizar viaje
      </v-btn>
    </template>
    <v-container
        class="d-flex flex-row justify-space-around"
    >
      <v-btn @click="activeModal= false">no</v-btn>
      <v-btn :loading="loading" color="primary" class="white--text" @click="turnDoneService()">si</v-btn>
    </v-container>
  </app-dialog>

</template>

<style scoped>

</style>