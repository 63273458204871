<script>

import {session} from "@/api/auth";


export default {
  data: () => ({
    fab: false
  }),
}


</script>

<template>
  <v-speed-dial
    v-model="fab"
    fixed
    large
    bottom
    right
    style="bottom: 4em; right: 1.5em"
    direction="left"
  >
    <template v-slot:activator>
      <v-btn
        v-model="fab"
        color="primary"
        fab
        dark
      >
        <v-icon v-if="fab">
          mdi-close
        </v-icon>
        <v-icon v-else>
          mdi-human-greeting
        </v-icon>
      </v-btn>
    </template>
    <v-tooltip top>
      <template v-slot:activator="{on}">
        <v-btn
          fab
          v-on="on"
          dark
          color="green"
          @click="$emit('yo')"
        >
          <v-icon>mdi-thumb-up</v-icon>
        </v-btn>
      </template>
      <span>Yo!</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{on}">
        <v-btn
          fab
          v-on="on"
          dark
          color="red"
          small
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
      <span>Ocultar carrera</span>
    </v-tooltip>
  </v-speed-dial>
</template>

<style scoped>

</style>