var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-basic-layout',{attrs:{"hide-header":true}},[_c('app-data-table',{ref:"appDataTable",attrs:{"hide-header":false,"collection":_vm.collection},on:{"onSearch":_vm.search},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('h2',[_vm._v("Users")])]},proxy:true},{key:"edit-dialog",fn:function({item:{
      id,
      user_agent,
      user_driver,
      ...item
    }}){return [_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"6"}},[(user_agent?.length>0)?_c('app-dialog',{attrs:{"title":"Edit Agent"},scopedSlots:_vm._u([{key:"activator",fn:function({on, attrs}){return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" Agent "),_c('v-icon',[_vm._v(" mdi-face-agent ")])],1)]}}],null,true),model:{value:(_vm.agentDialog),callback:function ($$v) {_vm.agentDialog=$$v},expression:"agentDialog"}},[(user_agent?.length>0)?_c('auto-form',{attrs:{"loading-action":_vm.agent_collection.loading,"form-fields":_vm.agent_collection.formFields,"form-data":user_agent[0]},on:{"submit":({user_id,...obj})=>_vm._update_collection(_vm.agent_collection,{user_id},obj)},scopedSlots:_vm._u([{key:"prepend-action",fn:function(){return [_c('v-btn',{attrs:{"loading":_vm.agent_collection.loading,"disabled":_vm.agent_collection.loading,"color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm._remove_item_on_collection(_vm.agent_collection,{user_id:id})}}},[_vm._v(" delete ")])]},proxy:true}],null,true)}):_vm._e()],1):_c('app-dialog',{attrs:{"title":"Add Agent"},scopedSlots:_vm._u([{key:"activator",fn:function({on, attrs}){return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v(" Agent "),_c('v-icon',[_vm._v(" mdi-face-agent ")])],1)]}}],null,true),model:{value:(_vm.agentDialog),callback:function ($$v) {_vm.agentDialog=$$v},expression:"agentDialog"}},[_c('auto-form',{attrs:{"loading-action":_vm.agent_collection.loading,"form-fields":_vm.agent_collection.formFields,"exclude":['user_id']},on:{"submit":(obj)=>_vm._create_item_on_collection(
                      _vm.agent_collection,{user_id:id,...obj}
                      )}})],1)],1),_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"6"}},[(user_driver?.length>0)?_c('app-dialog',{attrs:{"title":"Edit Driver"},scopedSlots:_vm._u([{key:"activator",fn:function({on, attrs}){return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" driver "),_c('v-icon',[_vm._v(" mdi-taxi ")])],1)]}}],null,true),model:{value:(_vm.driverDialog),callback:function ($$v) {_vm.driverDialog=$$v},expression:"driverDialog"}},[(user_driver?.length>0)?_c('auto-form',{attrs:{"loading-action":_vm.driver_collection.loading,"form-fields":_vm.driver_collection.formFields,"form-data":user_driver[0]},on:{"submit":({user_id,...obj})=>_vm._update_collection(_vm.driver_collection,{user_id},obj)},scopedSlots:_vm._u([{key:"prepend-action",fn:function(){return [_c('v-btn',{attrs:{"loading":_vm.driver_collection.loading,"disabled":_vm.driver_collection.loading,"color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm._remove_item_on_collection(_vm.driver_collection,{user_id:id})}}},[_vm._v(" delete ")])]},proxy:true}],null,true)}):_vm._e()],1):_c('app-dialog',{attrs:{"title":"Add Driver"},scopedSlots:_vm._u([{key:"activator",fn:function({on, attrs}){return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v(" driver "),_c('v-icon',[_vm._v(" mdi-taxi ")])],1)]}}],null,true),model:{value:(_vm.driverDialog),callback:function ($$v) {_vm.driverDialog=$$v},expression:"driverDialog"}},[_c('auto-form',{attrs:{"loading-action":_vm.driver_collection.loading,"form-fields":_vm.driver_collection.formFields,"exclude":['user_id']},on:{"submit":(obj)=>_vm._create_item_on_collection(
                      _vm.driver_collection,{user_id:id,...obj}
                      )}})],1)],1)],1)],1),_c('auto-form',{attrs:{"loading-action":_vm.collection.loading,"form-data":item,"formFields":_vm.editCollectionFields,"exclude":['id','user_agent','user_driver']},on:{"submit":({username,...obj})=>_vm._update_item({username},obj)},scopedSlots:_vm._u([{key:"prepend-action",fn:function(){return [_c('v-btn',{attrs:{"loading":_vm.collection.loading,"disabled":_vm.collection.loading,"color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm._remove_item({id})}}},[_vm._v(" delete ")])]},proxy:true}],null,true)})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }