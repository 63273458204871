<script>
import {defineComponent} from 'vue'
import AppDataTable from "@/components/organisms/AppDataTable.vue";
import appBasicLayout from "@/components/templates/appBasicLayout.vue";
import {session} from "@/api/auth";
import {Collection} from "@/plugins/collection";
import {driver_facture_resume} from "@/api/data/user";
import {monetize} from "@/plugins/filters";
import autoForm from "@/plugins/AutoForms/AutoForm.vue";
import {BOOLEAN_TYPE_FIELD, FormFieldItem, INTEGER_TYPE_FIELD} from "@/plugins/AutoForms/tools";
import GoodLookJson from "@/components/molecules/goodLookJson.vue";

export default defineComponent({
    name: "AppResumenView",
    components: {GoodLookJson, autoForm, appBasicLayout, AppDataTable},
    data: () => ({
        collection: new Collection(driver_facture_resume),
        active_filters: {},
        filter_form: [
            new FormFieldItem({
                    multiple: true,
                    text: 'agent name',
                    keyData: 'attended_by',
                    type: INTEGER_TYPE_FIELD,
                    foreign_key: {table: "user_agent", column: "id"}
                }
            ),
            new FormFieldItem({
                text: 'isActive',
                keyData: 'isActive',
                type: BOOLEAN_TYPE_FIELD,
            }),
            new FormFieldItem({
                text: 'isPremium',
                keyData: 'isPremium',
                type: BOOLEAN_TYPE_FIELD,
            })
        ]
    }),
    computed: {
        isSuperUser() {
            return session.isSuperUser
        }
    },
    methods: {
        filters({attended_by, isActive, isPremium, ...rest}) {
            attended_by = attended_by == null || attended_by.length == 0 ? null : attended_by
            let _filters = {
                attended_by,
                isActive,
                isPremium
            };
            this.active_filters = {
                ..._filters
            };
            this.collection.filter(_filters);
            this.$refs['appDataTable'].filterDialog = false;
        },
        search(text) {
            if (text.trim() == '') {
                this.collection.clear_filters(['like', 'like_cols']);
            } else {
                this.collection.filter({
                    like: text,
                    like_cols: [
                        'code',
                        'name',
                        'attended_by.name'
                    ],
                })
            }
        },
        monetize,
        colorClass(item) {
            return item['total'] == 0 ? 'success' : item['total'] <= 0 ? 'error white--text' : (item['total'] < 1000 ? 'warning' : 'success')
        },
        total(item) {
            let deposit = item['deposit.value'] ?? 0;
            let calc_debt = item['calc_debt.value'] ?? 0;
            // let calc_deb_pay = item['calc_deb_pay.value'] ?? 0
            let calc_service_unclose = item['calc_service_unclose.value'] ?? 0;
            // return deposit - (calc_debt + calc_deb_pay + calc_service_unclose);
            return deposit - (calc_debt + calc_service_unclose);
        }
    }
})
</script>

<template>
  <app-basic-layout
      :hideHeader="true"
      :hide-header="true"
  >
    <app-data-table
        :allow-download="true"
        ref="appDataTable"
        :collection="collection"
        :hide-header="false"
        @onSearch="search"
        :item-class="colorClass"
        :extendCols="{total:{filter:monetize}}"
        :calcCols="{total:total}"
    >
      <template v-slot:head>
        <h2>Resumen</h2>
      </template>
      <template v-slot:edit-dialog="{item,headers}">
        <good-look-json
            :global-format="{cols:{sm:6}}"
            :headers="headers"
            :data="item"
        ></good-look-json>
      </template>
      <template v-slot:filter-dialog>
        <auto-form
            :loading-action="collection.loading"
            ref="filterForm"
            :form-data="active_filters"
            :form-fields="filter_form"
            @clear="filters({})"
            @submit="filters"
            action-name="filter"
        />
      </template>

    </app-data-table>
  </app-basic-layout>
</template>

<style scoped>

</style>