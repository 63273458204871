import Vue from 'vue'
import _ from "lodash";

const LoadingMixin = {
    data: () => ({
        loadingMixin: 0,
    }),
    computed: {
        is_loading() {
            return !!this.loadingMixin;
        }
    },
    methods: {
        async loadingOn(...promise) {
            this.loadingMixin += promise.length;
            return Promise.all(
                promise
            ).finally(
                () => promise.map(this.loadingOff)
            );
        },
        loadingOff() {
            this.loadingMixin = _.max([this.loadingMixin - 1, 0]);
        }
    }
};

Vue.use({
        install(Vue) {
            Vue.mixin(LoadingMixin);
        }
    }
);
