import {_axios} from "@/plugins/axios";
import {Table, TableBlob} from "@/api/Table";


export const secure_table = new Table('/settings', 'secure_table');
export const images = new Table('/settings', 'images');
export const files = new Table('/settings', 'files');


import {services} from "./services";
import {user_agent, user_driver} from "./user";
import {facture} from "./facture";

export default {
    table_list: {
        get() {
            return _axios.get('/settings/list').then((res) => res.data);
        },
    },
    run_procedure(id, obj) {
        return _axios.post(`/settings/run/${id}`, obj).then((res) => res.data);
    },
    secure_table,
    services,
    user_agent,
    user_driver,
    facture
};

const imagesBlob = new TableBlob('/settings', 'images');


export const apiImageTables = {
    imagesBlob
}

