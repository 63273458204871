import {ConfigDefinition, ExtendTableDefinition, Table} from "@/api/Table";
import {dateFromNowUtc, timeStampMinWidth} from "@/plugins/filters";
import {order_by} from "@/api/helper";

export const logs_system = new Table(
    '',
    'logs_system',
    new ConfigDefinition(order_by('-created_at')),
    new ExtendTableDefinition(
        ['body', 'params', 'filters', 'complex_filters', 'agg_filters'],
        {
            created_at: {
                filter: dateFromNowUtc,
                minWidth: timeStampMinWidth
            }
        }
    )
)