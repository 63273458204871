var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-basic-layout',{attrs:{"hideHeader":true},on:{"add":_vm.openCreate}},[_c('app-data-table',{ref:"appDataTable",attrs:{"collection":_vm.collection,"hideHeader":false,"allow-download":true,"show-select":_vm.session.isSuperUser,"item-key":"user_id","actionSelected":[
      {
        icon: 'mdi-account-switch',
        text: 'Cambiar gestor',
        action: (items) => _vm.showUpdateAttendedBy(items),
        hidden: !_vm.session.isSuperUser,
      },
    ]},on:{"onSearch":_vm.search},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('h2',{staticClass:"ma-auto"},[_vm._v("Drivers")])]},proxy:true},{key:"edit-dialog",fn:function({ item: { user_id, ...item } }){return [_c('auto-form',{attrs:{"loading-action":_vm.collection.loading,"form-data":item,"formFields":_vm.collection.formFields},on:{"submit":(obj) => _vm._update_item({ user_id }, obj)},scopedSlots:_vm._u([{key:"prepend-action",fn:function(){return [_c('v-btn',{attrs:{"loading":_vm.collection.loading,"disabled":_vm.collection.loading,"color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm._remove_item({ user_id })}}},[_vm._v(" delete ")])]},proxy:true}],null,true)})]}}])}),_c('app-dialog',{model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('auto-form',{attrs:{"loading-action":_vm.collection.loading,"text":"create","form-data":_vm.collection.formData,"formFields":_vm.collection.formFields,"exclude":['id']},on:{"clear":_vm.clear,"submit":_vm.create}})],1),_c('app-dialog',{model:{value:(_vm.updateAttendedByDialog),callback:function ($$v) {_vm.updateAttendedByDialog=$$v},expression:"updateAttendedByDialog"}},[_c('auto-form',{attrs:{"loading-action":_vm.collection.loading,"text":"Actualiza el gestor que los atiende","form-data":{ user_id: _vm.updateAttendedByItems },"formFields":_vm.updateAttendedByFields},on:{"submit":_vm.updateAttendedBy}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }