<template>
  <app-basic-layout
      protected-add="true"
      v-on="{
      ...(isSuperUser? {add:createNewFacture}:{})
      }"
  >
    <template v-slot:head>
      <h2>Driver Facture</h2>
    </template>
    <v-progress-linear
        indeterminate v-if="is_loading"></v-progress-linear>
    <app-dialog
        v-model="create_dialog"
        title="New Facture"
    >
      <auto-form
          :loading-action="isCreating"
          :form-fields="form_fields"
          :readOnlyArray="['type']"
          :form-data="{type:'debt'}"
          @submit="create"
      />
    </app-dialog>
    <v-container fluid class="flex-grow-1">
      <v-expansion-panels v-model="model">
        <v-expansion-panel class="my-1">
          <v-expansion-panel-header color="orange">
            <h3 class="white--text text-uppercase">
              Choferes desactivados <br v-if="$vuetify.breakpoint.xsOnly"> ( no se calculan )
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0">
            <app-data-table
                item-key="id"
                :allow-download="true"
                ref="disable_driver_debt_collection"
                :collection="disable_driver_debt_collection"
                :hide-header="true"
                @onSearch="search_disables"
                height="400"
            ></app-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="my-1">
          <v-expansion-panel-header color="green">
            <h3 class="white--text text-uppercase">
              Deudas pagadas
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0">
            <app-data-table
                item-key="id"
                :allow-download="true"
                ref="debt_pay_facture_collection"
                :collection="payed_collection"
                :hide-header="true"
                @onSearch="search_payed"
                height="400"
            ></app-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="my-1">
          <v-expansion-panel-header color="red">
            <h3 class="white--text text-uppercase">
              Deudas sin pagar
            </h3>
            <div
                class="flex-shrink-0 flex-grow-0"
            >
              <v-menu v-if="$vuetify.breakpoint.mdAndDown">
                <template v-slot:activator="{on}">
                  <v-btn icon v-on="on">
                    <v-icon>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item @click="payAll">
                    pagar todos los posibles
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                  v-else
                  :loading="is_loading"
                  @click.stop="payAll"
              >pagar todos los posibles
              </v-btn>
            </div>

          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0">
            <app-data-table
                item-key="id"
                :allow-download="true"
                ref="debt_facture_collection"
                :collection="debt_facture_collection"
                height="400"
                @onSearch="search_debt"
                :hide-header="true"
                :item-disabled="(item)=>item.value>item['deposit.value']"
            >
              <template v-slot:edit-dialog="{item:{id,value,user_id,...rest}}">
                <auto-form
                    :readOnlyArray="['value','parent_id','user_id','type']"
                    :loading-action="debt_facture_collection.loading"
                    :formFields="form_fields"
                    :form-data="{value,parent_id:id,user_id,type:'debt_pay'}"
                    :function-disabled="(item)=>(item.value>rest['deposit.value'])"
                    @submit="(obj)=>pay_debt(obj,rest['deposit.value'])"
                >
                </auto-form>
                <app-protected-btn
                    v-if="isSuperUser"
                    class="d-block"
                    color="error"
                    @submit="remove_debt({id})"
                >DELETE DEBT
                </app-protected-btn>
              </template>
            </app-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </app-basic-layout>
</template>

<script>
import AppDialog from "@/components/molecules/appDialog";
import TextField from "@/plugins/AutoForms/FormFields/textField";
import AppDataTable from "@/components/organisms/AppDataTable";
import appBasicLayout from "@/components/templates/appBasicLayout";
import autoForm from "@/plugins/AutoForms/AutoForm";
import {Collection} from "@/plugins/collection";
import {facture, deposit_simple, facture_operations} from "@/api/data/facture";
import ValueCircle from "@/components/molecules/valueCircle"
import {
    FormFieldItem,
    SELECT_FIELD,
    INTEGER_TYPE_FIELD,
    DOUBLE_PRECISION_TYPE_FIELD
} from "@/plugins/AutoForms/tools";
import {agg, group_by, SUM, extend, values_list, order_by} from "@/api/helper";
import {monetize, timeStampHelper, yesNo} from "@/plugins/filters";
import AppProtectedBtn from "@/components/organisms/appProtectedBtn.vue";
import {session} from "@/api/auth";

export default {
    name: "AppFactureView",
    components: {AppProtectedBtn, AppDialog, TextField, AppDataTable, appBasicLayout, autoForm, ValueCircle},
    data: () => ({
        disable_driver_debt_collection: new Collection(facture, {
                type: 'debt',
                facture__isnull: true,
                'user_driver.isActive': false,

                //params
                ...extend('facture', 'user_id.username', 'deposit', 'user_driver'),
                ...values_list('id', 'user_driver.isActive', 'user_driver.code', 'user_id', 'value', 'introduced_at', 'deposit.value')
            },
            {
                'user_driver.isActive': {
                    rename: 'is Active?',
                    filter: yesNo
                },
                'deposit.value': {
                    rename: 'deposit',
                    filter: monetize
                },
                'user_driver.code': {
                    rename: 'code'
                }
            }),
        payed_collection: new Collection(facture, {
                type: 'debt_pay',
                'parent_id.type': 'debt',
                'user_driver.isActive': true,
                ...order_by('user_driver.code'),
                ...extend('parent_id', 'user_id', 'user_driver'),
                ...values_list('id', 'user_driver.code', 'value', 'introduced_at', 'parent_id.introduced_at')
            },
            {
                'parent_id.introduced_at': {
                    ...timeStampHelper(),
                    rename: 'facture_date'
                },
                'introduced_at': {
                    rename: 'payed_date'
                },
                'user_driver.code': {
                    rename: 'code'
                }
            }),
        debt_facture_collection: new Collection(facture, {
                // driver debt
                // filters
                type: 'debt',
                facture__isnull: true,
                'user_driver.isActive': true,

                // config
                //params
                ...extend('facture', 'user_id.username', 'deposit', 'user_driver'),
                ...values_list('id', 'user_driver.code', 'user_id', 'value', 'introduced_at', 'deposit.value')
            },
            {
                'deposit.value': {
                    rename: 'deposit',
                    filter: monetize
                },
                'user_driver.code': {
                    rename: 'code'
                }
            }
        ),
        model: 2,
        debt: 0,
        payed: 0,
        deposit: 0,
        total: 0,
        create_dialog: false,
        isCreating: false,
        form_fields: [
            new FormFieldItem({
                    type: DOUBLE_PRECISION_TYPE_FIELD,
                    nullable: false,
                    text: 'value'
                }
            ), new FormFieldItem({
                    type: INTEGER_TYPE_FIELD,
                    nullable: false,
                    foreign_key: {
                        table: 'user_driver',
                        column: 'user_id',
                    },
                    text: 'user_id'
                }
            ), new FormFieldItem({
                    type: SELECT_FIELD,
                    nullable: false,
                    define_field: {
                        items: [
                            'debt', 'debt_pay'
                        ],
                    },
                    text: 'type'
                }
            ),
        ]
    }),
    computed: {
        isSuperUser() {
            return session.isSuperUser;
        },
        current_user() {
            return session.me
        },
        xs_viewing() {
            return this.$vuetify.breakpoint.xs
        },
    },
    methods: {
        fetchData() {
            let debt_facture_collection = this.$refs.debt_facture_collection;
            let debt_pay_facture_collection = this.$refs.debt_pay_facture_collection;
            debt_facture_collection?.fetchData();
            debt_pay_facture_collection?.fetchData();
        },

        payAll() {
            this.loadingOn(
                facture_operations.create().then(
                    () => this.fetchData()
                )
            );
        },
        remove_debt(filter) {
            this.isCreating = true;
            Promise.all([
                facture.remove(filter)
            ]).finally(() => {
                this.isCreating = false;
                this.create_dialog = false;
                this.fetchData();
            })
        },
        search_payed(text) {
            if (text.trim() == '') {
                this.payed_collection.clear_filters(['like', 'like_cols']);
            } else {
                this.payed_collection.filter({
                    like: text,
                    like_cols: [
                        'user_driver.code',
                    ],
                })
            }
        },
        search_disables(text) {
            if (text.trim() == '') {
                this.disable_driver_debt_collection.clear_filters(['like', 'like_cols']);
            } else {
                this.disable_driver_debt_collection.filter({
                    like: text,
                    like_cols: [
                        'user_driver.code',
                    ],
                })
            }
        },
        search_debt(text) {
            if (text.trim() == '') {
                this.debt_facture_collection.clear_filters(['like', 'like_cols']);
            } else {
                this.debt_facture_collection.filter({
                    like: text,
                    like_cols: [
                        'user_driver.code',
                    ],
                })
            }
        },
        createNewFacture() {
            this.create_dialog = true;
        },
        create(obj, autoForm) {
            this.isCreating = true;
            facture.create(
                obj
            ).then(() => {
                this.isCreating = false;
                this.create_dialog = false;
                autoForm.loadInnerValue();
                this.fetchData();
            }).finally(() => {
                this.isCreating = false;
                this.create_dialog = false;
            })
        },
        pay_debt(obj, deposit_value) {
            this.isCreating = true;
            let {user_id, value} = obj;
            let deposit_operation = deposit_value == null ? deposit_simple.create(
                {
                    user_id,
                    value: -value,
                }
            ) : deposit_simple.update({
                user_id
            }, {
                value: deposit_value - value
            })
            this.loadingOn(
                deposit_operation,
                facture.create(obj)
            ).finally(() => {
                this.isCreating = false;
                this.create_dialog = false;
                this.fetchData();
            })
        }
    }
}
</script>

<style scoped>
.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */
{
    transform: translateX(10px);
    opacity: 0;
}

#detail-view {
    width: 100%;
}

#detail-view th {
    border-bottom: slategray solid 1px;
    padding: .2em;
}

#detail-view td {
    border-bottom: slategray solid 1px;
    border-bottom-color: #ccc;
    padding: .1em;
}

/*#detail-view td:first-child{*/
/*  padding: .1em;*/
/*  text-align: right;*/

/*}*/

/*value circles operators and result*/
.value-circle-operator {
    padding-top: .3em;
    font-size: 1.8em;
}

.total-incentive {
    padding-top: .4em;
}

@media (max-width: 599px) {
    .total-incentive {
        font-size: 2em;
    }
}

@media (min-width: 600px) and (max-width: 959px) {
    .value-circle-operator {
        font-size: 1.3em;
        padding-top: .4em;
    }

    .total-incentive {
        font-size: 1.2em;
        padding-top: 1em;
    }
}

@media (min-width: 1904px) {
    .value-circle-operator {
        font-size: 2em;
        padding-top: .1em;
    }

    .total-incentive {
        font-size: 3em;
        padding-top: .05em;
    }
}


</style>