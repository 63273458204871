<template>
  <good-look-json
      :global-format="{type:'row',cols:{sm:6}}"
      :format="{origen:{cols:12},destino:{cols:12}}"
      :order="['origen','destino']"
      :data="chat_data"
  />
</template>
<script>
import GoodLookJson from "@/components/molecules/goodLookJson.vue"

export default {
    name: 'app-chat-content',
    components: {GoodLookJson},
    props: {
        chat: {}
    },
    computed: {
        chat_data() {
            return Object.keys(
                this.chat.description
            ).filter(key => key[0] !== '_').reduce(
                (carr, key) => ({
                    ...carr,
                    [key]: this.chat.description[key]
                }), {}
            );
        }
    }

}
</script>