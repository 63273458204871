import Vue from 'vue';
import _ from 'lodash'
import moment from 'moment'

const MONETIZE_DEFAULT_NO_VALUE = '$ 0.00';

function monetize(value) {
    if (!value) return MONETIZE_DEFAULT_NO_VALUE;
    let number = Number(value);
    if (isNaN(number)) return MONETIZE_DEFAULT_NO_VALUE;
    if (number < 0) {
        number = number * -1;
        const monetizeFormat = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(number);
        return `(${monetizeFormat})`;
    }
    return new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(number);
}

function percent(value) {
    return value + "%"
}

function extractCoins(val) {
    const aux = `${val}`.match(new RegExp(/\d+(.\d\d?)?/, 'gi'));
    return aux && aux.shift()
}

function extractDigits(val) {
    const aux = `${val}`.match(new RegExp(/\d+/, 'gi'));
    return aux && aux.shift()
}

function yesNo(value) {
    return value ? 'Yes' : 'No'
}

function startCase(value) {
    return _.startCase(value)
}

function upper(value) {
    return value.toUpperCase();
}

function revertDateFormat(date) {
    /* Este metodo es para revertir el formato retornado por dateFormat*/
    return moment(date, 'MM-DD-YYYY').format('YYYY-MM-DD');
}

function dateFormat(date) {
    return date ? moment(date).format('DD/MM/YYYY') : '-'
}


function monthFormat(date) {
    return date ? moment(date).format('MMMM, YYYY') : '-'
}

function timeStamp(date) {
    return date ? moment(date).format('DD/MM/YYYY, h:mm A') : '-'
}

function hoursMinutes(date) {
    return date ? moment(date).format('h:mm A') : '-'
}

function time(date) {
    return date ? moment(date, 'H:mm:ss').format('h:mm A') : '-'
}

function dateFromNow(date) {
    return moment(date).fromNow()
}

function dateFromNowUtc(date) {
    return moment.utc(date).fromNow()
}

function cleanSpaces(value) {
    return value.replace(/\s/g, '')
}


const round = (num, fractionD = 2) => {
    try {
        return (Math.round(parseFloat(num.toString()) * 100) / 100).toFixed(fractionD);
    } finally {

    }
    return num
};

const paddy = (num, padlen = 3, padchar = '0') => {
    const pad = new Array(1 + padlen).join(padchar);
    return (pad + num).slice(-pad.length);
};

const ownerWord = (name = '') => {
    return `${name}'${name.endsWith('s') ? '' : 's'}`
};

const timeStampMinWidth ='11.7em';
const dateMinWidth ='3.8em';
const timeMinWidth ='5em';

export function timeStampHelper() {
    return {
        filter: timeStamp,
        minWidth: timeStampMinWidth
    }
}

export function dateHelper() {
    return {
        filter: dateFormat,
        minWidth: dateMinWidth
    }
}
export function timeHelper() {
    return {
        filter: time,
        minWidth: timeMinWidth
    }
}


Vue.use({
    install(Vue) {
        Vue.filter('monetize', monetize);
        Vue.filter('timeStamp', timeStamp);
        Vue.filter('time', time);
        Vue.filter('yesNo', yesNo);
        Vue.filter('startCase', startCase);
        Vue.filter('upper', upper);
        Vue.filter('dateFromNow', dateFromNow);
        Vue.filter('dateFromNowUtc', dateFromNowUtc);
        Vue.filter('dateFormat', dateFormat);
        Vue.filter('monthFormat', monthFormat);
        Vue.filter('cleanSpaces', cleanSpaces);
        Vue.filter('paddy', paddy);
        Vue.filter('round', round);
        Vue.filter('percent', percent);
        Vue.filter('ownerWord', ownerWord);
        Vue.filter('hoursMinutes', hoursMinutes);
    }
});

export {
    upper,
    startCase,
    dateFormat,
    dateFromNow,
    dateFromNowUtc,
    monetize,
    percent,
    cleanSpaces,
    paddy,
    extractCoins,
    extractDigits,
    timeStamp,
    hoursMinutes,
    round,
    time,
    monthFormat,
    revertDateFormat,
    ownerWord,
    yesNo,
    timeStampMinWidth,
    dateMinWidth,
    timeMinWidth
}
