<template>
  <app-basic-layout :hideHeader="false">
    <template v-slot:head>
      <h2>Dashboard</h2>
    </template>
    <template v-slot:head:left>
      <date-field
          v-model="selectedMonth"
          activator="btn"
          type="month"
          @change="fetchData"
          @input="fetchData"
      >
      </date-field>
    </template>
    <v-progress-linear indeterminate v-if="is_loading"></v-progress-linear>
    <div
        class="fill-height d-flex flex-column overflow-auto"
    >
      <div
          class="mx-0 flex-grow-1 d-flex flex-row ma-0 pa-0 overflow-auto"
      >
        <div class="overflow-auto flex-grow-1 pa-3">
          <app-dialog
              v-model="dayResumeDialog"
              :title="focus"
              class="pa-0 bg2"
          >
            <div
                class="overflow-auto flex-grow-1 fill-height"
            >
              <v-timeline
                  class="overflow-auto "
                  align-top
                  dense
              >
                <template v-for="item in eventsFiltered">
                  <v-timeline-item
                      small
                      :key="item.id"
                      :color="item.canceled_at? 'red':'primary'"
                  >
                    <service-resume :service="item" class="mb-1" style="width: 100%"/>
                  </v-timeline-item>
                </template>
              </v-timeline>
            </div>

          </app-dialog>
          <v-calendar
              ref="calendar"
              v-model="focus"
              :events="events"
              :start="start_calendar_day"
              type="month"
              @click:more="viewDay"
              @click:date="viewDay"
              @click:event="showEvent"
          >
            <!--  region Day            -->
            <!--            <template v-slot:day="data">-->
            <!--              slot day-->
            <!--            </template>-->
            <!--            <template v-slot:day-body="data">-->
            <!--              slot day body-->
            <!--            </template>-->
            <!--            <template v-slot:day-header="data">-->
            <!--              slot day header-->
            <!--            </template>-->
            <!--            <template v-slot:day-label="data">-->
            <!--              slot day label-->
            <!--            </template>-->
            <!--            <template v-slot:day-label-header="data">-->
            <!--              day-label-header-->
            <!--            </template>-->

            <!--            <template v-slot:day-month="data">-->
            <!--              day-month-->
            <!--            </template>-->
            <!--  endregion Day            -->
            <!--            <template v-slot:event="data">-->
            <!--              slot event-->
            <!--            </template>-->
          </v-calendar>
        </div>
        <!--        <div class="overflow-auto grow-shrink-0">-->
        <!--          <template-->
        <!--              v-for="service in service_undone"-->
        <!--          >-->
        <!--            <service-resume :service="service" class="mb-1"/>-->
        <!--          </template>-->
        <!--        </div>-->
      </div>
    </div>
  </app-basic-layout>
</template>

<script>
import AppBasicLayout from "@/components/templates/appBasicLayout.vue";
import {DATE_FIELD, FormFieldItem, INTEGER_TYPE_FIELD} from "@/plugins/AutoForms/tools";
import DateField from "@/plugins/AutoForms/FormFields/dateField.vue";
import {Collection} from "@/plugins/collection";
import {service_for_resume, services} from "@/api/data/services";
import {agg, COUNT, extend, group_by, nested, order_by, SUM} from "@/api/helper";
import ServiceResume from "@/views/home/appServiceResume.vue";
import moment from "moment";
import AppDialog from "@/components/molecules/appDialog.vue";

function get_color(value) {

}

export default {
    name: "Dashboard",
    components: {AppDialog, ServiceResume, DateField, AppBasicLayout},
    computed: {
        eventsFiltered() {
            return this.counters.filter(service => service.date == this.focus)
        },

    },
    data: () => ({
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        focus: '',
        type: 'month',
        events: [],
        dayResumeDialog: false,
        selectedMonth: null,
        start_calendar_day: null,
        active_filters: {},
        service_undone: [],
        service_collection: null,
        counters: [],
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        busy: false,
        filterForm: [
            new FormFieldItem({
                type: DATE_FIELD,
                range: true,
                text: 'date'
            }), new FormFieldItem({
                multiple: true,
                text: 'origen',
                type: INTEGER_TYPE_FIELD,
                foreign_key: {table: "locations", column: "id"}
            }), new FormFieldItem({
                multiple: true,
                text: 'destino',
                type: INTEGER_TYPE_FIELD,
                foreign_key: {table: "locations", column: "id"}
            }), new FormFieldItem({
                multiple: true,
                text: 'agent name',
                keyData: 'agent_id',
                type: INTEGER_TYPE_FIELD,
                foreign_key: {table: "user_agent", column: "id"}
            }), new FormFieldItem({
                multiple: true,
                text: 'driver code',
                keyData: 'driver_id',
                type: INTEGER_TYPE_FIELD,
                foreign_key: {table: "user_driver", column: "id"}
            }),
        ]
    }),
    beforeMount() {
        this.selectedMonth = (
            new Date(
                Date.now() - (new Date()).getTimezoneOffset() * 60000
            )
        ).toISOString().substr(
            0, 7
        )
        this.fetchData();
    },
    methods: {
        loadMore() {
            alert("load more")
            this.busy = true
        },
        viewDay({date, day, ...rest}) {
            this.focus = date
            this.dayResumeDialog = true;
        },
        showEvent({nativeEvent, day: {date}, event}) {
            this.focus = date
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.dayResumeDialog = true))
            }
            if (this.dayResumeDialog) {
                this.dayResumeDialog = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }
            nativeEvent.stopPropagation()
        },
        fetchData(selectedMonth) {
            this.loadingOn();
            let month = moment(selectedMonth ?? this.selectedMonth, moment.ISO_8601)
            this.start_calendar_day = month.format('YYYY-MM-DD')
            month.add(1, 'month')
            let l2 = month.format('YYYY-MM-DD')

            this.service_collection = new Collection(
                service_for_resume
            )
            // let is_not_done_promise = this.service_collection.fetchData({
            //   is_done: false
            // }).then(
            //     ({items}) => {
            //       this.service_undone = items;
            //     }
            // )
            let dashboard_promise = this.service_collection.fetchData({
                date__gte: this.start_calendar_day,
                date__lt: l2,
            }).then(({items}) => {
                this.counters = items
                let resume = this.counters.reduce(
                    (carr, curt) => {
                        const {date} = curt
                        if (date in carr) {
                            carr[date] = [curt, ...carr[date]]
                        } else {
                            carr[date] = [curt]
                        }
                        return carr
                    }, {})
                this.events = Object.keys(resume).map(
                    date => {
                        let services = resume[date];
                        let canceled = services.filter(
                            service => service.canceled_at != null
                        ).length
                        return {
                            name: `${services.length - canceled} ✅ services`,
                            start: new Date(`${date}T00:00:00`),
                            timed: false
                        }
                    }
                )
                this.events = [
                    ...this.events,
                    ...Object.keys(resume).filter(
                        date => {
                            let services = resume[date];
                            return !!services.filter(service => service.canceled_at != null).length
                        }).map(
                        date => {
                            let services = resume[date];
                            let canceled = services.filter(service => service.canceled_at != null).length
                            return {
                                name: ` ${canceled} 🟩 canceled`,
                                start: new Date(`${date}T00:00:00`),
                                color: 'red',
                                timed: false
                            }
                        }
                    )]
                // this.events = this.counters.map(
                //     (service) => {
                //       let month = moment(`${service.date}T${service.moment}`, moment.ISO_8601)
                //       let l1 = month.format()
                //       month.add(1, 'hour')
                //       let l2 = month.format('')
                //       return {
                //         name: `services (${service.id}) (${service['driver_id.code']})`,
                //         color: service.canceled_at ? 'red' : 'primary',
                //         start: new Date(l1),
                //         end: new Date(l2),
                //         timed: true
                //       }
                //     }
                // )
            })

            Promise.all([
                // is_not_done_promise,
                dashboard_promise
            ]).finally(this.loadingOff)
        },
    }
}
</script>

