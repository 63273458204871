<template>
  <app-basic-layout :hide-header=true>
    <app-data-table
        :hide-header="false"
        :collection="collection"
        ref="appDataTable"
        @onSearch="search"
    >
      <template v-slot:head>
        <h2>Users</h2>
      </template>
      <template v-slot:edit-dialog="{item:{
        id,
        user_agent,
        user_driver,
        ...item
      }}">
        <v-container>
          <v-row>
            <v-col cols="6" class="d-flex flex-column">
              <app-dialog
                  v-if="user_agent?.length>0"
                  v-model="agentDialog"
                  title="Edit Agent"
              >
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                      v-on="on"
                      v-bind=attrs
                  >
                    Agent
                    <v-icon>
                      mdi-face-agent
                    </v-icon>
                  </v-btn>
                </template>
                <auto-form
                    :loading-action="agent_collection.loading"
                    v-if="user_agent?.length>0"
                    :form-fields="agent_collection.formFields"
                    :form-data="user_agent[0]"
                    @submit="({user_id,...obj})=>_update_collection(agent_collection,{user_id},obj)"
                >
                  <template v-slot:prepend-action>
                    <v-btn
                        :loading="agent_collection.loading"
                        :disabled="agent_collection.loading"
                        color="error"
                        @click.stop="_remove_item_on_collection(agent_collection,{user_id:id})"
                    >
                      delete
                    </v-btn>
                  </template>
                </auto-form>
              </app-dialog>
              <app-dialog
                  v-else
                  v-model="agentDialog"
                  title="Add Agent"
              >
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                      v-on="on"
                      v-bind=attrs
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                    Agent
                    <v-icon>
                      mdi-face-agent
                    </v-icon>
                  </v-btn>
                </template>
                <auto-form
                    :loading-action="agent_collection.loading"
                    :form-fields="agent_collection.formFields"
                    :exclude="['user_id']"
                    @submit="(obj)=>_create_item_on_collection(
                        agent_collection,{user_id:id,...obj}
                        )"
                ></auto-form>
              </app-dialog>
            </v-col>
            <v-col cols="6" class="d-flex flex-column">
              <app-dialog
                  v-if="user_driver?.length>0"
                  v-model="driverDialog"
                  title="Edit Driver"
              >
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                      v-on="on"
                      v-bind=attrs
                  >
                    driver
                    <v-icon>
                      mdi-taxi
                    </v-icon>
                  </v-btn>
                </template>
                <auto-form
                    :loading-action="driver_collection.loading"
                    v-if="user_driver?.length>0"
                    :form-fields="driver_collection.formFields"
                    :form-data="user_driver[0]"
                    @submit="({user_id,...obj})=>_update_collection(driver_collection,{user_id},obj)"
                >
                  <template v-slot:prepend-action>
                    <v-btn
                        :loading="driver_collection.loading"
                        :disabled="driver_collection.loading"
                        color="error"
                        @click.stop="_remove_item_on_collection(driver_collection,{user_id:id})"
                    >
                      delete
                    </v-btn>
                  </template>
                </auto-form>
              </app-dialog>
              <app-dialog
                  v-else
                  v-model="driverDialog"
                  title="Add Driver"
              >
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                      v-on="on"
                      v-bind=attrs
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                    driver
                    <v-icon>
                      mdi-taxi
                    </v-icon>
                  </v-btn>
                </template>
                <auto-form
                    :loading-action="driver_collection.loading"
                    :form-fields="driver_collection.formFields"
                    :exclude="['user_id']"
                    @submit="(obj)=>_create_item_on_collection(
                        driver_collection,{user_id:id,...obj}
                        )"
                ></auto-form>
              </app-dialog>
            </v-col>
          </v-row>
        </v-container>
        <auto-form
            :loading-action="collection.loading"
            :form-data="item"
            :formFields="editCollectionFields"
            :exclude="['id','user_agent','user_driver']"
            @submit="({username,...obj})=>_update_item({username},obj)"
        >
          <template v-slot:prepend-action>
            <v-btn
                :loading="collection.loading"
                :disabled="collection.loading"
                color="error" @click.stop="_remove_item({id})">
              delete
            </v-btn>
          </template>
        </auto-form>
      </template>
    </app-data-table>
  </app-basic-layout>
</template>

<script>
import {Collection} from "@/plugins/collection";
import AppDataTable from "@/components/organisms/AppDataTable";
import appBasicLayout from "@/components/templates/appBasicLayout";
import autoForm from "@/plugins/AutoForms/AutoForm";
import AppDialog from "@/components/molecules/appDialog";
import {FormFieldItem, PASSWORD_FIELD} from "@/plugins/AutoForms/tools";
import {user_account, user_agent, user_agent_basic, user_driver, user_driver_basic} from "@/api/data/user";

export default {
  name: "ApplicationUserAccountView",
  components: {AppDialog, AppDataTable, appBasicLayout, autoForm},
  computed: {
    editCollectionFields() {
      return [
        ...this.collection.formFields,
        new FormFieldItem({
          type: PASSWORD_FIELD,
          text: 'password',
          nullable: true,
        })
      ]
    },
  },
  data: () => ({
    driverDialog: false,
    agentDialog: false,
    collection: new Collection(user_account),
    agent_collection: new Collection(user_agent_basic, {limit: 0}),
    driver_collection: new Collection(user_driver_basic, {limit: 0}),
    createDialog: false,
  }),
  mounted() {
    this.agent_collection.fetchData();
    this.driver_collection.fetchData();
  },
  methods: {
    search(text) {
      if (text.trim() == '') {
        this.collection.clear_filters(['like', 'like_cols']);
      } else {
        this.collection.filter({
          like: text,
          like_cols: ['username', 'phone_number'],
        })
      }
    },
    clear() {
      this.collection.clearData();
    },
    openCreate() {
      this.createDialog = true;
    },
    create(obj) {
      this.collection.create(obj).then(() => {
        this.collection.fetchData();
        this.createDialog = false;
      });
    },
    _remove_item(filter) {
      this.collection.remove(filter).then(res => {
        this.collection.fetchData();
        this.$refs['appDataTable'].editDialog = false;
      })
    },
    _update_collection(collection, filter, obj) {
      collection.update(
          filter, obj
      ).then(res => {
        this.collection.fetchData();
        this.agentDialog = false;
        this.driverDialog = false;
        this.$refs['appDataTable'].editDialog = false;
      })
    },
    _create_item_on_collection(collection, obj) {
      collection.create(
          obj
      ).then(res => {
        this.collection.fetchData();
        this.agentDialog = false;
        this.driverDialog = false;
        this.$refs['appDataTable'].editDialog = false;
      })
    },
    _remove_item_on_collection(collection, filter) {
      collection.remove(filter).then(res => {
        this.collection.fetchData();
        this.agentDialog = false;
        this.driverDialog = false;
        this.$refs['appDataTable'].editDialog = false;
      })
    },
    _update_item(filter, obj) {
      this.collection.update(
          filter, obj
      ).then(res => {
        this.collection.fetchData();
        this.agentDialog = false;
        this.driverDialog = false;
        this.$refs['appDataTable'].editDialog = false;
      })
    }
  }
}
</script>

<style scoped>

</style>