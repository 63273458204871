import {
    ConfigDefinition,
    DefineMethod,
    DefineMethodsModifiers,
    ExtendTableDefinition,
    FormDefinition,
    Table
} from "@/api/Table";
import {dateHelper, timeHelper, timeStampHelper} from "@/plugins/filters";
import {extend, order_by} from "@/api/helper";

export const basic_incidence = new Table(
    '/app',
    'incidences',
    new ConfigDefinition({
            ...extend(
                'inserted_by.username',
                'user_appointed.username'
            ),
            ...order_by('-date', '-moment')
        }
    ),
    new ExtendTableDefinition(
        ['observations','inserted_by', 'user_appointed'],
        {
            'inserted_by.username': {
                rename: 'inserted_by'
            },
            'user_appointed.username': {
                rename: 'user_appointed'
            },
            date: {
                ...dateHelper()
            },
            moment: {
                ...timeHelper()
            },
            created_at: {
                ...timeStampHelper()
            },
            resolved_at: {
                ...timeStampHelper()
            },
        }),
    new DefineMethodsModifiers(
        new DefineMethod(
            new ConfigDefinition(extend()),
            null,
            [
                'inserted_by.username',
                'user_appointed.username'
            ],
            {
                date: {
                    cols: {
                        md: 6
                    },
                }, moment: {
                    cols: {
                        md: 6
                    },
                },
            },
            new FormDefinition(
                [
                    'date',
                    'moment',
                ]
            )
        )
    )
)
