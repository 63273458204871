<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="innerValue"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="activator=='btn'"
        v-bind="attrs"
        v-on="{...on,...$on}"
        :disabled="disabled"
      >
        {{ humanValue }}
        <v-icon v-if="icon">
          mdi-calendar
        </v-icon>
      </v-btn>
      <v-text-field
        dense
        filled
        v-on="{...on,...$on}"
        v-else
        :value="humanValue"
        :prepend-icon="icon? 'mdi-calendar':''"
        readonly
        scrollable
        :label="label"
        :clearable="!required"
        :disabled="disabled"
        v-bind="{...attrs,...$attrs}"
      >
      </v-text-field>
    </template>
    <v-date-picker
      v-model="innerValue"
      scrollable
      :range="range"
      v-bind="$attrs"
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="modal = false"
      >
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="setNow"
      >
        setNow
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="save"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>

import {monthFormat, dateFormat} from "@/plugins/filters";


export default {
  name: "dateField",
  props: {
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    activator: {
      type: String,
      default: () => "textfield"
    },
    range: {},
    icon: {},
    formData: {
      type: Object,
    },
    keyData: String,
    autoField: Object, // este es el tipo que tiene dentro las cosas de autoField
    value: [Object, String, Boolean, Array, Number],
    rules: {
      type: Object | String,
    },
    label: {
      type: String
    }
  },
  inheritAttrs: false,
  data:
    () => ({
      modal: false,
      innerValue: ""
    }),
  beforeMount() {
    this.innerValue = this.value;
  },
  computed: {
    humanValue() {
      if (!this.value)
        return ''
      if (this.$attrs.type == 'month')
        return monthFormat(this.value)
      if (this.value.constructor == Array) {
        return this.value.map(dateFormat).join(' hasta ')
      }
      return dateFormat(this.value)
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal, oldValue) {
      if (this.range && newVal?.length == 2) {
        if (oldValue != null) {
          let [a, b] = newVal
          let [c, d] = oldValue
          if (a == c && b == d && b != null && a != null) {
            return;
          }
          newVal = newVal.sort(
            (a, b) => {
              const date1 = new Date(a)
              const date2 = new Date(b)
              return date1 - date2;
            }
          )
        }
      }
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  methods: {
    save() {
      this.$refs.dialog.save(this.innerValue);
      this.$emit("input", this.innerValue);
      this.$emit("change", this.innerValue);
    },
    setNow() {
      this.innerValue =
        (
          new Date(
            Date.now() - (new Date()).getTimezoneOffset() * 60000
          )
        ).toISOString().substr(
          0, 10
        )
    }
  }
}
</script>

<style scoped>

</style>