<template>
  <v-col
      cols="12"
      v-bind="field?.extend?.cols??{}"
      class="d-flex flex-column py-0 overflow-auto"
      :class="{'px-0':$vuetify.breakpoint.smAndDown}"
  >
    <div class="d-flex flex-row">
      <slot>

      </slot>
      <div
          v-if="!disabled && field?.extend?.right"
          class="d-flex flex-column"
      >
        <div
            class="justify-center flex-grow-1 pl-1 d-flex flex-row justify-space-around flex-shrink-0 flex-wrap"
        >
          <div class="d-flex flex-column"
               v-for="item in field?.extend?.right"
               style="min-width: 5.3em"
          >
            <v-btn
                class="ma-auto"
                :disabled="disabled"
                rounded
                :key="item.text"
                @click='$emit("input",item.action(value))'>
              {{ item.text }}
            </v-btn>
          </div>
        </div>
        <div class="v-text-field__details">
        </div>
      </div>
    </div>
    <div
        v-if="!disabled && field?.extend?.down?.length>0"
        class="d-flex flex-column"
    >
      <div class="d-flex flex-row justify-space-between flex-wrap flex-grow-1"
      >
        <div class="flex-grow-1 py-1 d-flex flex-column"
             v-for="item in field?.extend?.down"
             style="min-width: 6em"
        >
          <v-btn
              class="ma-auto"
              :disabled="disabled"
              rounded
              :key="item.text"
              @click='$emit("input",item.action(value))'>
            {{ item.text }}
          </v-btn>
        </div>
      </div>
      <div class="v-text-field__details">
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "app-basic-row",
  props: {
    field: {},
    disabled: {
      type: Boolean,
      default: () => false
    },
    value: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>