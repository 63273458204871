<template>
  <v-container v-if="driver_id" class="flex-grow-1" fluid>
    <v-row class="mb-1 ma-1">
      <v-col
          :cols="$vuetify.breakpoint.xsOnly? 12:null"
      >
        <ValueCircle
            :loading="is_loading"
            :value="deposit"
            text="Deposit"/>
      </v-col>
      <v-col
          :cols="$vuetify.breakpoint.xsOnly? 12:null"
      >
        <ValueCircle
            :color="factures>0? 'error':'success'"
            :loading="is_loading"
            :value="factures"
            operator="-"
            text="Debt"/>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xsOnly? 12:null">
        <ValueCircle
            :color="_driver_debt>0? 'error':'success'"
            :loading="is_loading"
            :value="_driver_debt"
            operator="-"
            text="Debt Service"/>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xsOnly? 12:null">
        <ValueCircle
            :color="service_un_pay>0? 'error':'success'"
            :loading="is_loading"
            :value="service_un_pay"
            operator="-"
            text="Service Un Pay"/>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xsOnly? 12:null"
             :style="$vuetify.breakpoint.xsOnly? {borderTop:'solid 1px black'}:{}">
        <ValueCircle
            :color="total-_driver_debt<0? 'error':'success'"
            :loading="is_loading"
            :value="total-_driver_debt"
            operator="="
            text="Total"/>
      </v-col>
      <v-col v-if="is_loading" class="ma-0 pa-0" cols="12">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import {basic_service, services} from "@/api/data/services";
import {facture, deposit_simple} from "@/api/data/facture";
import {agg, extend, group_by, order_by, SUM} from "@/api/helper";
import ValueCircle from "@/components/molecules/valueCircle.vue";
import {driver_facture_resume} from "@/api/data/user";

export default {
    name: 'app-facture-plugin',
    components: {ValueCircle},
    data: () => ({
        deposit: 0,
        factures: 0,
        service_un_pay: 0
    }),
    props: {
        driver_debt: {
            required: true,
            default: () => 0,
        },
        driver_id: {
            required: true,
            type: Number
        }
    },
    watch: {
        driver_id(newValue, oldValue) {
            this.load_data();
        }
    },
    computed: {
        _driver_debt() {
            if (this.driver_debt === '')
                return 0
            if (this.driver_debt)
                return Number.parseFloat(this.driver_debt)
            return 0
        },
        total() {
            return this.deposit - this.factures - this.service_un_pay
        }
    },
    methods: {
        load_data() {
            this.loadingOn(
                driver_facture_resume.get(
                    {user_id: this.driver_id}
                ).then(({items}) => {
                        if (items.length === 1) {
                            let item = items[0]
                            this.deposit = item['deposit.value'] ?? 0;
                            this.factures = item['calc_debt.value'] ?? 0;
                            this.service_un_pay = item['calc_service_unclose.value'] ?? 0;
                        }
                    }
                )
            )
        }
    },
    beforeMount() {
        this.load_data();
    }
}
</script>