<template>
  <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="innerValue"
      persistent
      width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-if="activator=='btn'"
          v-bind="attrs"
          v-on="{...on,...$on}"
          :disabled="disabled"
      >
        {{ humanValue }}
        <v-icon v-if="icon">
          mdi-calendar
        </v-icon>
      </v-btn>
      <v-text-field
          dense
          filled
          v-on="{...on,...$on}"
          v-else
          :value="humanValue"
          prepend-icon="mdi-clock-outline"
          readonly
          scrollable
          :label="label"
          :disabled="disabled"
          v-bind="{...attrs,...$attrs}"
      ></v-text-field>
    </template>
    <v-time-picker
        format="24hr"
        :range="range"
        v-model="innerValue"
        scrollable
        v-bind="$attrs"
    >
      <v-spacer></v-spacer>
      <v-btn
          text
          color="primary"
          @click="modal = false"
      >
        Cancel
      </v-btn>
      <v-btn
          text
          color="primary"
          @click="setNow"
      >
        setNow
      </v-btn>
      <v-btn
          text
          color="primary"
          @click="save"
      >
        OK
      </v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
import moment from "moment/moment";
import {time} from "@/plugins/filters";

export default {
  name: "timestampField",
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    activator: {
      type: String,
      default: () => "textfield"
    },
    range: {}, icon: {},
    formData: {
      type: Object,
    },
    keyData: String,
    autoField: Object, // este es el tipo que tiene dentro las cosas de autoField
    value: [Object, String, Boolean, Array, Number],
    rules: {
      type: Object | String,
    },
    label: {
      type: String
    }
  },
  watch: {
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  computed: {
    humanValue() {
      if (!this.value)
        return ''
      return time(this.value)
    }
  },
  data: () => ({
    modal: false,
    innerValue: "",
  }),
  beforeMount() {
    this.innerValue = this.value;
  },
  methods: {
    save() {
      this.$refs.dialog.save(this.innerValue);
      this.$emit("input", this.innerValue);
      this.$emit("change", this.innerValue);
    },
    setNow() {
      this.innerValue = (moment().format('HH:mm:ss'))
    }
  }
}
</script>

<style scoped>

</style>