import {
    ConfigDefinition,
    DefineMethod,
    DefineMethodsModifiers,
    ExtendTableDefinition,
    FormDefinition,
    Table
} from "@/api/Table";
import {monetize, timeStampHelper, yesNo} from "@/plugins/filters";

import {extend, nested, order_by} from "@/api/helper";

export const facture = new Table(
    '/app',
    'facture',
    new ConfigDefinition(
        extend(
            'facture',
            'user_id.username',
            'parent_id.value'
        )
    ),
    new ExtendTableDefinition([
            'id',
            'user_id',
            'parent_id',
            'deposit_id',
        ], {
            user: {
                filter: (obj) => obj.username,
            },
            value: {
                filter: monetize

            }, introduced_at: {
                rename: 'created_at',
                ...timeStampHelper(),
            }
        }
    ),
    new DefineMethodsModifiers(
        new DefineMethod(
            new ConfigDefinition(extend())
        )
    )
);

export const transaction = new Table(
    "/app",
    "transaction",
    new ConfigDefinition(
        {
            ...extend(
                "user_id.username"
            ),
            ...nested(
                'driver_id'
            ),
            ...order_by(
                "-created_at"
            )
        }
    ),
    new ExtendTableDefinition(
        ["id", "driver_id", "user_id"],
        {
            driver: {
                rename: 'driver',
                filter: ({code, name}) => code ? `(${code}) ${name}` : `${name}`,
            },
            value: {
                filter: monetize
            },
            'deposit.value': {
                filter: monetize,
                rename: "deposit"
            },
            created_at: {
                rename: 'inserted at',
                ...timeStampHelper(),
            },
            'user_id.username': {
                rename: "attended_by"
            },
            "driver_id.code": {
                rename: 'code'
            }
        }
    ),
    new DefineMethodsModifiers(
        new DefineMethod(
            new ConfigDefinition({
                    ...nested(),
                    ...extend()
                }
            ),
            {},
            [
                "driver_id.code",
                "user_id.username",
                "deposit.value",
                "driver",
                "id"
            ],
            {
                value: {
                    cols: {
                        md: 6
                    }
                }, no: {
                    cols: {
                        md: 6
                    }
                },
                created_at: {
                    nullable: true,
                }
            },
            new FormDefinition(
                [
                    'value',
                    'no',
                    'driver_id',
                    'user_id',
                ]
            )
        ),
    )
);

export const deposit = new Table(
    '/settings',
    'user_driver',
    new ConfigDefinition(
        {
            ...extend(
                'deposit.value',
                'attended_by.name'
            ),
            ...order_by('code')
        }
    ),
    new ExtendTableDefinition(
        ['id', 'user_id', 'attended_by'],
        {
            isActive: {
                filter: yesNo
            },
            isPremium: {
                filter: yesNo,
                colStyle: {
                    type: "icon",
                    match: {
                        true: "mdi-star",
                        false: "mdi-star-off-outline"
                    }
                }
            },
            'deposit.value': {
                filter: monetize
            }
        }
    ),
    new DefineMethodsModifiers(
        new DefineMethod(
            new ConfigDefinition(
                nested()
            ),
            null,
            ['isActive', 'isPremium', 'user_id']
        )
    )
);
export const deposit_simple = new Table(
    '/app',
    'deposit',
);


export const facture_operations = new Table(
    '/app',
    'facture-pay-all'
)
