import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi' || 'mdiSvg' || 'fa' || 'fa4' || 'faSvg',  //'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#1870b9',
                secondary: '#28aee4',
                tertiary: colors.blueGrey.lighten5,
                accent: '#fec010',
                alert:colors.pink,
                'info': '#28aee4',
                'success': '#92d050',
                'warning': "#fec010",
                'dark': '#0e0e0e',
                'grey-1': '#404040',
                'grey-2': '#808080',
                'grey-3': '#a6a6a6',
                'light': '#f2f2f2',
                error: '#b71c1c',
                danger: '#b71c1c'
            },
            dark: {
                primary: colors.blue.lighten1,
                secondary: colors.cyan.lighten1,
                tertiary: colors.blueGrey.darken3,
                accent: colors.amber.darken1,
                error: colors.red.darken4,
                background: colors.grey.darken4 // Este es un color de fondo general, puedes cambiarlo a lo que desees.
            }
        }
    }
});

