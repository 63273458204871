<script>
import {defineComponent} from 'vue'
import AppBasicLayout from "@/components/templates/appBasicLayout.vue";
import {service_for_resume} from "@/api/data/services";
import ServiceResume from "@/views/home/appServiceResume.vue";
import {session} from "@/api/auth";
import AppToolbarBtn from "@/views/home/AppToolbarBtn.vue";
import {dateFromNowUtc} from "@/plugins/filters";
import moment from "moment";
import {SubscriptionMixin} from "@/mixins/bazaarSubscriptionMixin";

function set_header(item) {
    return {
        type: "header",
        data: item.header,
    }
}

function set_item(item) {
    return {
        type: "item",
        data: item,
    }
}


export default defineComponent({
    name: "appMyServicesView",
    components: {AppToolbarBtn, ServiceResume, AppBasicLayout},
    mixins: [SubscriptionMixin],
    data: () => ({
        services: [],
        services_archives: [],
        filters: {},
    }),
    beforeMount() {
        this.set_default_filters();
        this.connect_ws();
    },
    computed: {
        headers() {
            if (this.data.length === 0)
                return []

            const result = [];
            let [init_element, ...data] = this.data;
            result.push(set_header(init_element));
            result.push(set_item(init_element));
            for (const item of data) {
                if (item.header !== result[result.length - 1].data.header) {
                    result.push(set_header(item));
                }
                result.push(set_item(item));
            }
            return result;
        },
        data() {
            return this.services.map(
                service => ({
                    moment: moment(service.created_at),
                    header: dateFromNowUtc(service.created_at),
                    ...service
                })
            );
        }
    },
    methods: {
        connect_ws() {
            session.reconnect();
            this.multikey_subscribe(
                [
                    `app_service_cancel_driver#${session.username}`,
                    `app_service_cancel_client#${session.username}`,
                    `app_service_turn_done_driver#${session.username}`,
                    `app_service_turn_done_client#${session.username}`,
                ],
                (data) => this.removeServiceFromList(data)
            );
        },
        removeServiceFromList() {
            this.fetchData();
        },
        set_done_filters() {
            this.filters = {
                is_done: true,
                canceled_at__isnull: true
            }
            this.fetchData();
        },
        set_canceled_filters() {
            this.filters = {
                canceled_at__isnull: false,
            }
            this.fetchData();
        },
        set_default_filters() {
            this.filters = {
                canceled_at__isnull: true,
                is_done: false
            }
            this.fetchData();
        },
        fetchData() {
            let base = {
                agent_id__isnull: true,
                interaction_id__isnull: false,
            };
            if (session.isSuperUser || session.isAgent) {
                base = {
                    inserted_by: session.me.id,
                    ...base
                }
            } else if (session.isDriver) {
                base = {
                    driver_id: session.me.id,
                    ...base
                }
            }
            this.loadingOn(
                service_for_resume.get(
                    {
                        ...base,
                        ...this.filters
                    }
                ).then(({items}) => {
                    this.services = items;
                })
            );
        }
    }
})
</script>

<template>
  <app-basic-layout :bottom="true" :loading="is_loading">
    <template v-slot:head>
      <h2> Tus viajes</h2>
    </template>
    <div class="fill-height d-flex flex-column overflow-auto">
      <v-list class="flex-grow-1 overflow-auto">
        <v-list-item v-if="headers.length==0" class="px-0" :key="data.id">
          <span class="text-subtitle-1 mx-auto">Nada que mostrar</span>
        </v-list-item>
        <template v-for="{type,data} in headers">
          <v-subheader v-if="type==='header'" :key="data">
            {{ data }}
          </v-subheader>
          <v-list-item v-else class="px-0" :key="data.id">
            <service-resume
                @removeService="removeServiceFromList"
                :collapsable="true" :service="data">
            </service-resume>
          </v-list-item>
        </template>
      </v-list>
      <v-toolbar
          height="100"
          class="flex-grow-0 flex-shrink-0 d-block"
      >
        <div class="d-flex flex-row  flex-grow-1 justify-space-around">
          <app-toolbar-btn color="primary" icon="mdi-archive" text="archivados" @click="set_done_filters"/>
          <app-toolbar-btn color="orange" icon="mdi-face-agent" text="en camino" @click="set_default_filters">
            <template v-slot:icon="{color,classStyle,icon}">
              <v-avatar style="overflow: visible" class="pr-3 my-auto mx-auto">
                <v-icon
                    :color="color" class="going-to-left">
                  mdi-run-fast
                </v-icon>
                <v-icon
                    :color="color">mdi-taxi
                </v-icon>
              </v-avatar>
            </template>
          </app-toolbar-btn>
          <app-toolbar-btn color="red" icon="mdi-cancel" text="cancelados" @click="set_canceled_filters"/>
        </div>
      </v-toolbar>
    </div>
  </app-basic-layout>
</template>