<template>
  <app-basic-layout @add="create_dialog = true">
    <template v-slot:head>
      <h2>Procedure</h2>
    </template>
    <v-data-iterator
      class="flex-grow-1 fill-height"
      :items="procedures_collection.items"
    >
      <template v-slot:default="{ items }">
        <v-expansion-panels>
          <v-expansion-panel
            v-for="({ id, isActive, ...item }, i) in items"
            :key="i"
          >
            <v-expansion-panel-header>
              <div class="d-flex flex-row">
                <v-simple-checkbox
                  :value="isActive"
                  @click="
                    procedures_collection.update(
                      { id },
                      { isActive: !isActive },
                      true
                    )
                  "
                ></v-simple-checkbox>
                <h3 class="my-auto">{{ item.name | startCase }}</h3>
                <v-spacer />
                <v-btn
                  class="mr-2"
                  link
                  :to="{ name: 'Operations', params: { procedure_id: id } }"
                >
                  see more
                  <v-icon right> mdi-send</v-icon>
                </v-btn>
                <app-protected-btn @submit="openDialog(id)">
                  run
                  <v-icon right dark> mdi-motion-play-outline</v-icon>
                </app-protected-btn>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <auto-form
                :loading-action="procedures_collection.loading"
                :form-data="{ isActive, ...item }"
                :exclude="['id']"
                :form-fields="procedures_collection.formFields"
                @submit="
                  (obj) => procedures_collection.update({ id }, obj, true)
                "
              >
                <template v-slot:prepend-action>
                  <v-btn
                    :loading="procedures_collection.loading"
                    :disabled="procedures_collection.loading"
                    color="error"
                    @click.stop="_remove_item({ id })"
                  >
                    delete
                  </v-btn>
                </template>
              </auto-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-data-iterator>
    <app-dialog v-model="create_dialog" title="create new access">
      <auto-form
        :loading-action="procedures_collection.loading"
        :form-fields="procedures_collection.formFields"
        :exclude="['id']"
        @submit="create"
      />
    </app-dialog>
    <app-dialog v-model="run_dialog" title="run procedure">
      <auto-form
        :loading-action="isRunning"
        :form-fields="procedures_form"
        :form-data="procedures_data"
        @submit="run"
      />
    </app-dialog>
  </app-basic-layout>
</template>

<script>
import AppBasicLayout from "@/components/templates/appBasicLayout";
import { Collection } from "@/plugins/collection";
import { procedures, operations } from "@/api/data/procedures";
import autoForm from "@/plugins/AutoForms/AutoForm";
import AppDialog from "@/components/molecules/appDialog";
import { FormFieldItem } from "@/plugins/AutoForms/tools";
import api from "@/api/data";
import AppProtectedBtn from "@/components/organisms/appProtectedBtn.vue";

export default {
  name: "ProceduresView",
  components: { AppProtectedBtn, AppDialog, AppBasicLayout, autoForm },
  data: () => ({
    isRunning: false,
    procedures_collection: new Collection(procedures),
    operations_collection: new Collection(operations),
    procedures_form: [],
    procedures_data: {},
    create_dialog: false,
    run_dialog: false,
  }),
  beforeMount() {
    this.procedures_collection.fetchData();
  },
  methods: {
    create(obj) {
      this.procedures_collection.create(obj).then(() => {
        this.procedures_collection.fetchData();
        this.create_dialog = false;
      });
    },
    _remove_item(filter) {
      this.procedures_collection.remove(filter).then((res) => {
        this.procedures_collection.fetchData();
        this.create_dialog = false;
      });
    },
    run(data) {
      this.isRunning = true;
      this.loadingOn();
      let { id, ...obj } = data;
      api
        .run_procedure(id, obj)
        .catch(() => {
          this.$alert.error("Error executing procedure");
        })
        .then(() => {
          this.run_dialog = false;
          this.$alert.success("Procedure executed successfully");
        })
        .finally(() => {
          this.loadingOff();
          this.isRunning = false;
        });
    },
    openDialog(id) {
      const procedure_selected = this.procedures_collection.items.find(
        (procedure) => procedure.id === id
      );
      const variables = procedure_selected.filters;
      this.procedures_form = [
        ...Object.entries(variables).map(
          ([key, value]) =>
            new FormFieldItem({
              text: key,
              type: value,
            })
        ),
        new FormFieldItem({
          text: "id",
          disabled: true,
          nullable: false,
          hidden: true,
        }),
      ];
      this.procedures_data = { id };
      this.run_dialog = true;
    },
  },
};
</script>

<style scoped></style>
