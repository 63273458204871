<script>
import AppDialog from "@/components/molecules/appDialog.vue";
import appInteractionAccept from "@/views/bazaar/components/AppInteractionAccept.vue";
import {session} from "@/api/auth";

export default {
  name: "appBazaarInteraction",
  components: {appInteractionAccept, AppDialog},
  props: {
    color: {},
    chat: {
      required: true,
      type: Object
    },
    interaction: {
      required: true,
      type: Object
    },
    activeAccept: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    timeText() {
      if (this.is_a_driver_interaction)
        return `en ${this.interaction.description['onTime']} min`;
      return '';
    },
    is_agent() {
      return session.isAgent || session.isSuperUser;
    },
    is_canceled() {
      return !!this.interaction.canceled_at
    },
    text() {
      if (this.are_you_seller) {
        return "Cambiaste el precio del viaje";
      }
      if (this.is_seller_interaction) {
        return "El cliente cambio el precio";
      }
      if (this.is_you) {
        return "To propuesta de precio";
      }
      if (this.is_a_driver_interaction) {
        if (this.is_canceled) {
          return "El chofer a declinado esta oferta";
        }
        return "Un chofer esta dispuesto a realizar esta viaje";
      }
      if (this.is_an_admin_interaction) {
        return "Un gestor le sugiere cambiar el precio";
      }
    },
    is_an_admin_interaction() {
      return this.interaction.user.is_superuser || this.interaction.user.is_agent;
    },
    is_seller_interaction() {
      return this.interaction.user_id == this.chat.seller;
    },
    is_you() {
      return session.me.id == this.interaction.user_id;
    },
    are_you_seller() {
      return session.me.id == this.chat.seller && this.interaction.user_id == this.chat.seller;
    },
    is_a_driver_interaction() {
      return this.interaction.user.is_driver;
    }
  }
}

</script>

<template>
  <v-timeline-item
    v-if="interaction"
    :color="color"
    small
    elevation="1"
    class="my-1 pa-1"
  >
    <v-card
      :disabled="!!interaction.canceled_at"
      shaped
      elevation="0"
      :class="{
                'flex-row justify-start':$vuetify.breakpoint.mdAndUp,
                'flex-column':$vuetify.breakpoint.smAndDown,
            }"
      class="d-flex pr-4">
      <v-card>
        <v-card-subtitle>
          <h3 class="text--accent-2" :class="{'text-decoration-line-through':interaction.canceled_at}">
            {{ interaction.description['price'] |monetize }} {{ timeText }}
          </h3>
          <span>{{ text }}</span>
          <slot
            name="text"
            v-bind="{
              is_canceled:!!interaction.canceled_at,
              interaction:interaction,
              price:interaction.description['price']
          }"></slot>
          <div>
            <template v-if="is_agent">
              <b>@{{ interaction.user.username }}</b> |
            </template>
            <span>{{ interaction.sended_at|timeStamp }}</span>
          </div>
        </v-card-subtitle>
      </v-card>
      <v-spacer
        v-if="activeAccept && $vuetify.breakpoint.mdAndUp"></v-spacer>
      <div
        v-if="activeAccept"
        class="my-auto pr-3 pt-2 justify-end"
      >
        <v-btn small @click="$emit('accept',interaction)">
          acepto
        </v-btn>
      </div>
    </v-card>
  </v-timeline-item>
</template>

<style scoped>

</style>