<template>
    <app-basic-layout :hide-header=true>
        <app-data-table
                :editWidth="600"
                :hide-header="false"
                :collection="collection"
                ref="appDataTable"
                @onSearch="search"
        >
            <template v-slot:head>
                <h2>Log System</h2>
            </template>
            <template v-slot:edit-dialog="{item}">
                <auto-form
                        :form-data="item"
                        :formFields="collection.formFields"
                        disabled
                />
            </template>
        </app-data-table>
    </app-basic-layout>
</template>

<script>
import {Collection} from "@/plugins/collection";
import AppDataTable from "@/components/organisms/AppDataTable";
import appBasicLayout from "@/components/templates/appBasicLayout";
import autoForm from "@/plugins/AutoForms/AutoForm";
import AppDialog from "@/components/molecules/appDialog";
import {logs_system} from "@/api/data/logs";

export default {
    name: "ApplicationLogsView",
    components: {AppDialog, AppDataTable, appBasicLayout, autoForm},
    // mixins: [collection(user_driver)],
    data: () => ({
        collection: new Collection(logs_system),
    }),
    beforeMount() {
        this.collection.fetchData()
    },
    methods: {
        search(text) {
            if (text.trim() == '') {
                this.collection.clear_filters(['like', 'like_cols']);
            } else {
                this.collection.filter({
                    like: text,
                    like_cols: ['username', 'method', 'tablename'],
                })
            }
        },
        clear() {
            this.collection.clearData();
        },
        openCreate() {
            this.createDialog = true;
        }
    }
}
</script>

<style scoped>

</style>