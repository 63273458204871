<template>
  <router-view v-if="user_has_be_loaded"></router-view>
  <v-progress-circular
      v-else
      indeterminate
      size="50"
      style="position: absolute;top: 50%;right: 50%"
  >
  </v-progress-circular>
</template>

<script>
import {session} from "@/api/auth";

export default {
    name: "App",
    data: () => ({
        user_has_be_loaded: false
    }),
    created() {
        if (window.location.href.includes('/login')) {
            this.user_has_be_loaded = true
        } else {
            session.try_login().catch(
                () => {
                    session.logout()
                    this.$router.push('/login')
                }
            ).finally(() =>
                this.user_has_be_loaded = true
            )
        }
    },
}
</script>

<style lang="scss">

* {
  box-sizing: border-box;
}

@for $i from 0 through 16 {
  .v-expansion-panel-content.px-#{$i} > .v-expansion-panel-content__wrap {
    padding-left: $i * 4px;
    padding-right: $i * 4px;
  }
  .v-expansion-panel-content.py-#{$i} > .v-expansion-panel-content__wrap {
    padding-top: $i * 4px;
    padding-bottom: $i * 4px;
  }
  .v-expansion-panel-content.pa-#{$i} > .v-expansion-panel-content__wrap {
    padding: $i * 4px;
  }
}

</style>
