<template>
  <v-container class="mx-0 px-0">
    <v-row
        :class="operator? 'justify-space-between':'justify-space-around'"
        class="mx-0 d-flex flex-row flex-nowrap"
    >
      <v-col v-if="operator" class="grow-shrink-0 mx-0 px-0">
        <h1 class="value-circle-operator text-center  mx-1">{{ operator }}</h1>
      </v-col>
      <v-col
          class="d-flex flex-column align-end pa-0 ma-0"
      >
        <h2 :class="`${color||'success'}--text`" class="title value-circle">
          {{ value|round|monetize }}
        </h2>
        <p
            class="text-end value-circle-descriptor subtitle-1 mb-1"
            :class="[`${color||'success'}--text`]">
          {{ text }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ValueCircle",
  props: {
    color: String,
    value: Number,
    loading: Boolean,
    text: {
      type: String,
      required: true
    },
    operator: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      //to give a fill animation to the progress circular elements on init
      progress: 0
    }
  },
  computed: {
    xs_viewing() {
      return this.$vuetify.breakpoint.xs
    },
    breakpoint() {
      return this.$vuetify.breakpoint
    }
  },
  mounted() {
    setTimeout(() => {
      this.progress = 100
    }, 400)
  }
}
</script>

<style scoped>
@media (min-width: 600px) and (max-width: 959px) {
  .value-circle {
    font-size: 1.2em;
  }
}
</style>
