import Vue from 'vue';
import '@/plugins';
import '@/mixins';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

// import "echarts"
// import VueECharts from "vue-echarts"

// import {registerMap} from "echarts/core";

// import usaJson from "@/data/USA";

require('@/assets/stiles.css');
require('@/assets/animate.min.css');

// Vue.config.productionTip = false
// Vue.component('v-chart', VueECharts);

new Vue({
    router,
    vuetify,
    beforeMount() {
        // registerMap('USA', usaJson, {
        //     Alaska: {
        //         left: -131,
        //         top: 25,
        //         width: 15
        //     },
        //     Hawaii: {
        //         left: -110,
        //         top: 28,
        //         width: 5
        //     },
        //     'Puerto Rico': {
        //         left: -76,
        //         top: 26,
        //         width: 2
        //     }
        // });
    },
    render: h => h(App)
}).$mount('#solarte-app')

