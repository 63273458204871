import {_axios, _axiosBlob} from "@/plugins/axios";

export class DefineMethodsModifiers {
  method_post//: DefineMethod;
  method_put//: DefineMethod;
  method_delete//: DefineMethod;

  constructor(
    method_post,
    method_put,
    method_delete
  ) {
    this.method_post = method_post;
    this.method_put = method_put;
    this.method_delete = method_delete;
  }
}

export class FormDefinition {
  order//: Array;

  constructor(order) {
    this.order = order;
  }
}

export class DefineMethod {
  config//: Object; ConfigDefinition
  refactor//: Object;
  exclude//: Array;
  extend//: Object;
  form //:FormDefinition

  constructor(config, refactor, exclude, extend, form) {
    this.config = config ?? {};
    this.refactor = refactor; /*convierte columnas en objetos*/
    this.exclude = exclude ?? [];/* Elimina estos elementos del objeto que se va a enviar*/
    this.extend = extend ?? {};/* Modifica el comportamiento del formulario de creación por campo*/
    this.form = form ?? new FormDefinition();/* Modifica el comportamiento del formulario de creación*/
  }
}

export class ConfigDefinition {
  nested
  extend
  order_by
  limit
  offset

  constructor(
    {
      nested,
      extend,
      limit,
      offset,
      order_by,
      headers,
    }
  ) {
    this.nested = nested ?? [];
    this.extend = extend ?? [];
    this.limit = limit ?? 20;
    this.offset = offset ?? 0;
    this.order_by = order_by;
    this.headers = headers;
  }
}

export class ExtendTableDefinition {
  extend//: Object;
  exclude//: Array<String>;

  constructor(
    exclude, // excluye estas columnas si aparecen
    extend, // toda configuración extra será agregada aquí
  ) {
    this.exclude = exclude ?? [/*colNames*/];
    this.extend = extend ?? {/*createHeader Options*/}; //  TODO: falta por hacer la logica
  }
}

function do_refactor(refactor, input) {
  const result = {...input}
  for (let key of Object.keys(refactor)) {
    let sub_obj = {}
    for (let sub_attributes of refactor[key]) {
      let value = result[sub_attributes];
      delete result[sub_attributes];
      sub_obj[sub_attributes] = value
    }
    result[key] = sub_obj;
  }
  return result
}

export class Table {
  get axios_instance() {
    return _axios;
  }

  get axios_blob_instance() {
    return _axiosBlob;
  }

  methods_modifiers//: DefineMethodsModifiers;
  __tablename__//: String;
  config//: ConfigDefinition;
  extend//: ExtendTableDefinition;
  prefix//: String;
  count//: Number;

  constructor(
    module,
    table_name,
    config,
    extend,
    methods_modifiers
  ) {
    this.prefix = module;
    this.__tablename__ = table_name;
    this.config = config ?? new ConfigDefinition({});
    this.extend = extend ?? new ExtendTableDefinition();
    this.methods_modifiers = methods_modifiers ?? new DefineMethodsModifiers();
  }

  get limit() {
    return this.config.limit ?? 100;
  }

  get table_name() {
    return [this.prefix, this.__tablename__].join('/');
  }

  get path() {
    return this.axios_instance.defaults.baseURL + this.table_name
  }

  get_headers(headers) {
    if (headers) {
      return headers.map(h => {
        let {value} = h;
        if (value in this.extend.extend) {
          h.extend = this.extend.extend[value];
        }
        return h
      })
    }
    console.warn('Not have headers describe')
    // TODO: eso se ejecuta si los headers no se pasa
  }

  get_by_id(id) {
    return this.axios_instance.get(
      [this.table_name, id].join('/'), {params: this.config}
    ).then((res) => res.data);
  }

  get(filters, user_blob = false) {
    filters = filters ?? {}
    filters = Object.keys(filters).reduce(
      (carr, key) => {
        if (filters[key] === '' || filters[key] == null) {
          // no lo agregues
        } else {
          carr[key] = filters[key]
        }
        return carr
      }, {}
    )

    if (user_blob) {
      return this.axios_blob_instance.get(
        this.table_name,
        {
          params: {
            ...this.config,
            ...filters
          }
        }
      ).then((res) => res.data)
    }
    return this.axios_instance.get(
      this.table_name, {params: {...this.config, ...filters}}
    ).then((res) => res.data);
  }

  get order_on_create() {
    let {method_post, ...rest} = this.methods_modifiers ?? {};
    if (method_post) {
      return method_post.form.order ?? [];
    }
    return []
  }

  extend_on_create(value) {
    let {method_post, ...rest} = this.methods_modifiers ?? {};
    if (method_post) {
      return method_post.extend[value] ?? {};
    }
    return {}
  }

  skip_on_create(value, skip_on_create) {
    // console.log('skip_on_create', value, skip_on_create)
    if (skip_on_create) {
      return true
    }
    let {method_post, ...rest} = this.methods_modifiers ?? {};
    if (method_post) {
      let exclude = method_post.exclude;
      // console.log("exclude", exclude);
      if (exclude) {
        // console.log("return", exclude.includes(value));
        return exclude.includes(value)
      }
    }
    return false
  }

  create(input, filters) {
    const {config, _input} = this.prepare_create(input);
    const {limit, offset, order_by, page, ...params} = config;
    return this.axios_instance.post(
      this.table_name,
      _input,
      {
        params: {
          ...params,
          ...filters
        }
      }
    ).then((res) => res.data);
  }

  remove_by_id(id) {
    return this.axios_instance.delete(
      [this.table_name, id].join('/'),
    ).then(
      res => res.data
    );
  }

  remove(input) {
    return this.axios_instance.delete(
      this.table_name,
      {params: input}
    ).then(
      res => res.data
    );
  }

  update(filter, input) {
    const {config, _input} = this.prepare_update(input);
    const pre_params = {...filter, ...config}
    const {order_by, limit, offset, page, ...params} = pre_params;
    return this.axios_instance.put(
      this.table_name,
      _input,
      {params}
    ).then((res) => res.data);
  }

  prepare_update(input) {
    let {method_put, method_post, ...rest} = this.methods_modifiers ?? {};
    method_put = method_put ?? method_post;
    let config = this.config;
    let _input = input
    if (method_put) {
      config = method_put.config ?? config;
      let refactor = method_put.refactor;
      _input = refactor ? do_refactor(refactor, input) : input;
      let exclude = method_put.exclude;
      if (exclude) {
        _input = Object.keys(input).filter(k => !exclude.includes(k)).reduce(
          (carr, curt) => {
            carr[curt] = input[curt];
            return carr;
          }, {}
        );
      }
    }
    return {config, _input};
  }

  prepare_create(input) {
    let {method_post, ...rest} = this.methods_modifiers ?? {};
    let config = this.config;
    let _input = input
    if (method_post) {
      config = method_post.config ?? config;
      let refactor = method_post.refactor;
      _input = refactor ? do_refactor(refactor, input) : input;
      let exclude = method_post.exclude;
      if (exclude) {
        _input = Object.keys(input).filter(k => !exclude.includes(k)).reduce(
          (carr, curt) => {
            carr[curt] = input[curt];
            return carr;
          }, {}
        );
      }
    }
    return {config, _input};
  }

  update_by_id(id, input) {
    const {config, _input} = this.prepare_update(input);

    return this.axios_instance.put(
      [this.table_name, id].join('/'),
      _input, {params: config}
    ).then((res) => res.data);
  }
}

export class TableBlob extends Table {
  get axios_instance() {
    return _axiosBlob;
  }

  constructor(
    module,
    table_name,
    config,
    extend,
    methods_modifiers
  ) {
    super(
      module,
      table_name,
      config,
      extend,
      methods_modifiers
    );
  }
}

export class ApiSelect {
  constructor(table, id_selector, text, fn_template, config) {
    this.table = table;
    this.isCallable = id_selector.constructor === Function;
    this.id_selector = id_selector;
    this.text = text;
    this.fn_template = fn_template;
    let {admit_new} = (config ?? {});
    admit_new = admit_new ?? false;
    this.config = {admit_new};
    this.loading = false;
  }

  get isCollectionText() {
    return Array.isArray(this.text)
  }


  get(filters) {
    this.loading = true;
    const values_list = this.isCallable ? [] : [this.id_selector];
    let {like, id} = filters
    if (like == '') {
      like = null;
    }


    if (this.isCollectionText) {
      values_list.push(...this.text)
    } else {
      values_list.push(this.text)
    }

    let _filters = {}
    if (like != null) {
      _filters = {
        like,
      }
    }
    if (id != null) {
      if (this.isCallable) {

      } else {
        _filters[this.id_selector] = id
      }
    }
    let {admit_new, ...config} = this.config

    filters = Object.keys(filters).reduce(
      (carr, key) => {
        if (filters[key] === '' || filters[key] == null) {
          // no lo agregues
        } else {
          carr[key] = filters[key]
        }
        return carr
      }, {}
    )

    return this.table.get({
      ..._filters,
      ...config,
      limit: 20,
      values_list,
      distinct: true,
      like_cols: values_list
    }).then(({items, ...rest}) => {
      return items.map(item => ({
        id: this.isCallable ? this.id_selector(item) : item[this.id_selector],
        text: this.fn_template ? this.fn_template(item) : item[this.text]
      }))
    }).finally(() => {
      this.loading = false;
    });
  }
}

export class SecureTableRow {
  isActive = false;
  method = 'OPTION';
  isLoading = false;
  driver = 'DB';

  constructor(method, isActive, driver) {
    this.method = method;
    this.isActive = isActive;
    this.driver = driver;
  }
}