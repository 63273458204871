// assets
import { taxi, whistle } from "@/assets";

// data
import { session } from "@/api/auth";
import { chat, chatInteractions } from "@/api/data/bazaar";

// template
import appBasicLayout from "@/components/templates/appBasicLayout.vue";

// plugins
import { dateFormat } from "@/plugins/filters";

// components
import AppDialog from "@/components/molecules/appDialog.vue";
import AppChat from "@/views/bazaar/components/bazzarServiceRequest/appChat.vue";
import appChatBox from "@/views/bazaar/sub-views/appChatBox.vue";
import { SubscriptionMixin } from "@/mixins/bazaarSubscriptionMixin";
import AppCreateNewBazzarDialogContent from "@/views/bazaar/components/AppCreateNewBazzarDialogContent.vue";

export default {
  name: "AppChatRoom",
  filters: { dateFormat },
  mixins: [SubscriptionMixin],
  components: {
    AppCreateNewBazzarDialogContent,
    AppChat,
    AppDialog,
    appBasicLayout,
    appChatBox,
  },
  computed: {
    listeners() {
      return this.active_tab === 0
        ? {
            add: () => {
              this.newBazaarDialog = true;
            },
          }
        : {};
    },
    connection() {
      return session;
    },
  },
  data: () => ({
    // ui
    active_tab: 0,
    accept_request: false,
    request_data: {},

    newBazaarDialog: false,

    subscriptions: [],
    interactionsBy_bazaar_id: {},
    interactionSelected: null,

    max_fetched: 0,
    bazaars_dict: {},
    chatId_opened: [],
  }),
  created() {
    this.loadingOn(
      chatInteractions
        .get({
          "bazaar_id.closed_at__isnull": true,
          limit: -1,
        })
        .then(({ items }) => {
          if (items.length > 0) this.max_fetched = items[items.length - 1].id;
          else this.max_fetched = 0;
          this.create_groups(items);
        }),
      chat
        .get({
          closed_at__isnull: true,
        })
        .then((res) => {
          this.bazaars_dict = res.items.reduce((chat_dict, chat) => {
            chat_dict[chat.id] = chat;
            return chat_dict;
          }, {});
        })
    ).then(this.connect_ws);
  },
  methods: {
    create_groups(interactions) {
      let group_by = {};
      for (let interaction of interactions) {
        let chat_group = group_by[interaction.bazaar_id] ?? {};
        chat_group[interaction.user_id] = interaction;
        group_by[interaction.bazaar_id] = chat_group;
      }
      this.interactionsBy_bazaar_id = group_by;
    },
    insert_into_group(interaction) {
      if (interaction.bazaar_id in this.interactionsBy_bazaar_id) {
        this.$set(
          this.interactionsBy_bazaar_id[interaction.bazaar_id],
          interaction.user_id,
          interaction
        );
      } else {
        this.$set(this.interactionsBy_bazaar_id, interaction.bazaar_id, {
          [interaction.user_id]: interaction,
        });
      }
    },
    close(chat_id) {
      let index = this.chatId_opened.findIndex((item) => item === chat_id);
      if (index >= 0) {
        let index_tab = this.active_tab;
        if (index + 1 === index_tab) {
          // got to home
          this.active_tab = 0;
        } else if (index + 1 > index_tab) {
        } else {
          this.active_tab = index_tab - 1;
        }
        this.chatId_opened.splice(index, 1);
      }
    },
    openChat(chat) {
      let index = this.chatId_opened.findIndex((item) => item === chat.id);
      if (index < 0) {
        this.chatId_opened.push(chat.id);
        this.active_tab = this.chatId_opened.length;
      } else {
        index = this.chatId_opened.findIndex((item) => item === chat.id);
        this.active_tab = index + 1;
      }
    },
    connect_ws() {
      let self = this;
      session.reconnect();
      // bazaar subscriptions:
      this.multikey_subscribe(
        [
          `new_bazaar#${session.username}`,
          "new_bazaar_drivers",
          "new_bazaar_admins",
        ],
        function onNewBazaar(new_bazaar) {
          console.log("onNewBazaar", new_bazaar);
          self.bazaars_dict = {
            ...self.bazaars_dict,
            [new_bazaar.id]: new_bazaar,
          };
          taxi.play();
        }
      );
      this.multikey_subscribe(
        [
          `update_bazaar#${session.username}`,
          "update_bazaar_drivers",
          "update_bazaar_admins",
        ],
        function onUpdateBazaar(bazaar) {
          self.bazaars_dict = {
            ...self.bazaars_dict,
            [bazaar.id]: bazaar,
          };
          // taxi.play();
        }
      );

      this.multikey_subscribe(
        [
          `close_bazaar#${session.username}`,
          "close_bazaar_drivers",
          "close_bazaar_admins",
        ],
        function onCloseBazaar(bazaar) {
          self.close(bazaar.id);
          self.$delete(self.bazaars_dict, bazaar.id);
          self.$delete(self.interactionsBy_bazaar_id, bazaar.id);
          // TODO: poner un sonido para cuando se cierren los chats
          // taxi.play();
        }
      );

      // interaction subscriptions
      this.multikey_subscribe(
        [
          "bazaar_interactions_admins",
          "bazaar_interactions_drivers",
          `bazaar_interactions#${session.username}`,
        ],
        self.onNewInteractionMessage.bind(self)
      );
    },
    onNewInteractionMessage(newInteraction) {
      this.max_fetched = newInteraction.id;
      this.insert_into_group(newInteraction);
      whistle.play();
    },
    addNewBazaar(data, newBazaarDialogController) {
      chat.create(data).then((res) => {
        this.newBazaarDialog = false;
        newBazaarDialogController.flush();
        return res;
      });
    },
  },
};
