<template>
  <v-snackbar
      app
      class="v-application"
      :timeout="timeout_"
      :color="color"
      :bottom="y === 'bottom'"
      :top="y === 'top'"
      :left="x === 'left'"
      :right="x === 'right'"
      :multi-line="multiLine"
      :vertical="vertical"
      v-model="active"
      :class="{ pointer: dismissible }"
      @click="dismiss"
      :style="style"
  >
    <v-icon dark left>
      {{ icon }}
    </v-icon>
    {{ message }}
  </v-snackbar>
</template>

<script>
let alertsCount = 1;
let alertBottom = 1;
let timeoutForDecreaseAlertCount = null;
export default {
  data: () => ({
    active: false,
    timeout_: 6000,
  }),
  props: {
    x: {type: String, default: "right"},
    y: {type: String, default: "bottom"},
    color: {type: String, default: "info"},
    icon: {type: String},
    message: {type: String},
    timeout: {type: Number, default: 3000},
    dismissible: {type: Boolean, default: true},
    multiLine: {type: Boolean, default: false},
    vertical: {type: Boolean, default: false},
    pined: {type: Boolean, default: false},
  },
  beforeMount() {
    this.active = true;
    this.timeout_ = this.pined ? 60000 : this.timeout;
    timeoutForDecreaseAlertCount = setTimeout(
        () => {
        },
        this.timeout_
    );
  },
  computed: {
    style() {
      alertBottom = (alertsCount - 1) * 50 + 10;
      return {
        position: "absolute",
        bottom: `${alertBottom}px`,
      };
    },
  },
  methods: {
    dismiss() {
      if (this.dismissible) {
        clearTimeout(timeoutForDecreaseAlertCount);
        this.active = false;
      }
    },
  },
};
</script>
