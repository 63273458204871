<template>
  <app-basic-layout :hide-header="true" @add="openDialog=true">
    <app-data-table
        :hide-header="false"
        :collection="collection"
        ref="appDataTable"
    >
      <template v-slot:head>
        <h2>Incidences</h2>
      </template>
      <template v-slot:edit-dialog="{item:{id,...item}}">
        <auto-form
            :form-fields="collection.formFields"
            :exclude="['inserted_by','created_at','id']"
            :form-data="item"
            @submit="(obj)=>updateIncidence({id},obj)"
            :loading-action="collection.loading"
        >
          <template v-slot:prepend-action>
            <v-btn color="error" @click="deleteIncidence({id})"
              :loading="collection.loading"
              :disabled="collection.loading"
              >
              delete
            </v-btn>
          </template>
        </auto-form>
      </template>
    </app-data-table>
    <app-dialog v-model="openDialog" title="create a new Incidence">
      <auto-form :form-fields="collection.formFields"
                 :exclude="['inserted_by','created_at','id']"
                 @submit="createIncidence"
                 :loading-action="collection.loading"
      >
      </auto-form>
    </app-dialog>
  </app-basic-layout>
</template>

<script>
import AppBasicLayout from "@/components/templates/appBasicLayout.vue";
import AppDataTable from "@/components/organisms/AppDataTable.vue";
import {Collection} from "@/plugins/collection";
import {basic_incidence} from "@/api/data/incidencias";
import AutoForm from "@/plugins/AutoForms/AutoForm.vue";
import AppDialog from "@/components/molecules/appDialog.vue";

export default {
  name: "AppIncidencesView",
  components: {AppDialog, AutoForm, AppDataTable, AppBasicLayout},
  data: () => ({
    collection: new Collection(
        basic_incidence,
        {}, {},
    ),
    openDialog: false
  }),
  methods: {
    updateIncidence(filter, obj) {
      this.collection.update(
          filter, obj, true
      ).then(() => {
            this.$refs['appDataTable'].editDialog = false;
          }
      )
    },
    deleteIncidence(fitler) {
      this.collection.remove(
          fitler, true
      ).then(() => {
            this.$refs['appDataTable'].editDialog = false;
          }
      )
    },
    createIncidence(obj, autoform) {
      this.collection.create(
          obj, true
      ).then(() => {
            this.openDialog = false;
            autoform.loadInnerValue()
          }
      )
    }
  }
}
</script>

<style scoped>

</style>