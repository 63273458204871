import {BOOLEAN_TYPE_FIELD, DATE_FIELD, FormFieldItem, INTEGER_TYPE_FIELD} from "@/plugins/AutoForms/tools";
import {extend, nested, order_by, values_list} from "@/api/helper";

export const downloadParams = {
    ...extend(
        'driver_id',
        'payed_service',
        'agent_id',
        "inserted_by",
        'locations'
    ),
    ...nested(),
    ...values_list(
        "id",
        "price",
        "driver_debt",
        "date",
        "moment",
        "created_at",
        "observations",
        "canceled_at",
        "pax",
        "is_done",
        "driver_id.isPremium",
        "driver_id.code",
        "agent_id.name",
        "origen.name",
        "destino.name",
        "inserted_by.username",
        "agent_id.is_collaborator",
        "agent_id.constalation_name",
        "payed_service.payed_at"
    ),
    ...order_by("date", "moment"),
};

export const serviceFormFilters = [
    new FormFieldItem({
        type: DATE_FIELD,
        range: true,
        text: 'date'
    }), new FormFieldItem({
        multiple: true,
        text: 'origen',
        type: INTEGER_TYPE_FIELD,
        foreign_key: {table: "locations", column: "id"}
    }), new FormFieldItem({
        multiple: true,
        text: 'destino',
        type: INTEGER_TYPE_FIELD,
        foreign_key: {table: "locations", column: "id"}
    }), new FormFieldItem({
        multiple: true,
        text: 'agent name',
        keyData: 'agent_id',
        type: INTEGER_TYPE_FIELD,
        foreign_key: {table: "user_agent", column: "id"}
    }), new FormFieldItem({
        multiple: true,
        text: 'inserted by',
        keyData: 'inserted_by',
        type: INTEGER_TYPE_FIELD,
        foreign_key: {table: "user_agent", column: "id"}
    }), new FormFieldItem({
        multiple: true,
        text: 'driver code',
        keyData: 'driver_id',
        type: INTEGER_TYPE_FIELD,
        foreign_key: {table: "user_driver", column: "id"}
    }), new FormFieldItem({
        text: 'is lock',
        keyData: 'is_lock',
        type: BOOLEAN_TYPE_FIELD,
    }), new FormFieldItem({
        text: 'is Premium',
        keyData: 'driver_id.isPremium',
        type: BOOLEAN_TYPE_FIELD,
    }), new FormFieldItem({
        text: 'was canceled',
        keyData: 'canceled_at__isnull',
        type: BOOLEAN_TYPE_FIELD,
    }), new FormFieldItem({
        text: 'was payed',
        keyData: 'payed_service__isnull',
        type: BOOLEAN_TYPE_FIELD,
    }), new FormFieldItem({
        text: 'payed_at',
        keyData: 'payed_service.payed_at',
        type: DATE_FIELD,
        range: true,
        dependes_on: ({payed_service__isnull}) => payed_service__isnull === true
    }), new FormFieldItem({
        text: 'is done',
        keyData: 'is_done',
        type: BOOLEAN_TYPE_FIELD,
    }), new FormFieldItem({
        text: 'is collaborator',
        keyData: 'is_collaborator',
        type: BOOLEAN_TYPE_FIELD,
    })
];

export function filter_services(self /*Collection*/, {
    date,
    origen,
    destino,
    agent_id,
    driver_id,
    is_lock,
    payed_service__isnull,
    canceled_at__isnull,
    is_done,
    inserted_by,
    is_collaborator,
    ...rest
}) {
    let is_premium = rest['driver_id.isPremium'];
    rest['driver_id.isPremium'] = null
    delete rest['driver_id.isPremium'];

    let payed_at = rest['payed_service.payed_at'];
    rest['payed_service.payed_at'] = null
    delete rest['payed_service.payed_at'];


    for (let r of Object.keys(rest)) {
        console.warn(`${r} wasn't skipped from filters`);
    }
    agent_id = agent_id == null || agent_id.length == 0 ? null : agent_id
    driver_id = driver_id == null || driver_id.length == 0 ? null : driver_id
    inserted_by = inserted_by == null || inserted_by.length == 0 ? null : inserted_by

    let _filters = {
        date,
        origen,
        destino,
        agent_id,
        driver_id,
        is_lock,
        is_done,
        inserted_by,
        payed_service__isnull,
        canceled_at__isnull,
        'driver_id.isPremium': is_premium
    }
    if (payed_service__isnull && (payed_at.length??0)>1){
        _filters['payed_service.payed_at'] = payed_at
    }


    self.active_filters = {
        ..._filters,
        is_collaborator,
    };

    if (is_collaborator === undefined) {
        _filters = {
            ..._filters,
            "user_agent.is_collaborator": null,
            user_agent__isnull: null
        }
    } else {
        _filters = {
            ..._filters,
            "user_agent.is_collaborator": is_collaborator
        }
        if (is_collaborator) {
            _filters = {
                ..._filters,
                user_agent__isnull: false
            }
        } else {
            _filters = {
                ..._filters,
                user_agent__isnull: null
            }
        }
    }

    self.service_collection.filter(_filters,
        /*between */['date','payed_service.payed_at'],
        /*autoload*/ true,
        /* not */[
            'payed_service__isnull',
            'canceled_at__isnull'
        ]);
    self.$refs['appDataTable'].filterDialog = false;
}

