<template>
  <v-textarea
      v-bind="modifiers"
      v-on="$listeners"
      v-model="innerValue"
      :type="dtype"
      :counter="rules.max_length"
      auto-grow
      rows="1"
      dense
      filled
  />
</template>

<script>
import {keyFormData} from "@/plugins/AutoForms/autoFieldMixin";

export default {
    inheritAttrs: false,
    name: "textareaField",
    mixins: [keyFormData],
    computed: {
        modifiers() {
            const {type, ...rest} = this.$attrs;
            if (this.rules && (
                this.rules.decimal
                || this.rules.double
            )) {
                rest['step'] = '0.01'
            }
            return rest;
        }
    }
}
</script>

<style scoped>

</style>