<template>
  <v-sheet :elevation="10" :id="componentName" class="fill-height">
    <v-data-table
      :group-by="groupBy"
      v-model="items_selected"
      v-bind="$attrs"
      :loading="loading || collection.loading"
      :items="_items"
      :headers="_headers"
      :items-per-page="collection.limit"
      fixed-header
      :mobile-breakpoint="0"
      :height="height_"
      ref="datatable"
      :options="{ page: this.page }"
      @click:row="openEdit"
      hide-default-footer
      :item-class="_itemClass"
      :server-items-length="collection.count"
    >
      <template v-slot:top v-if="!hideHeader">
        <v-toolbar
          ref="header"
          class="white--text"
          elevation="2"
          color="primary"
        >
          <v-row class="ma-0 d-flex flex-nowrap">
            <div v-if="!xs_viewing">
              <slot name="head"></slot>
            </div>
            <v-spacer></v-spacer>
            <div
              class="grow-shrink-0 d-flex flex-row"
              v-if="$listeners.onSearch"
            >
              <like-btn
                class="my-auto"
                @onSearch="(v) => $emit('onSearch', v)"
                :lazy="800"
              >
              </like-btn>
            </div>
            <div class="grow-shrink-0" v-if="'filter-dialog' in $slots">
              <v-btn icon @click="filterDialog = true">
                <v-badge
                  color="alert"
                  :content="collection.filters_keys.length"
                  v-model="collection.filters_keys.length"
                >
                  <v-icon color="white"> mdi-filter</v-icon>
                </v-badge>
              </v-btn>
            </div>
            <div class="grow-shrink-0" v-if="allowDownload">
              <v-btn icon @click="downloadFile" dark>
                <v-icon> mdi-download</v-icon>
              </v-btn>
              <template v-for="action in $attrs.actionSelected">
                <v-tooltip bottom v-if="!action.hidden">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      @click="run_action(action.action)"
                      dark
                    >
                      <v-icon>
                        {{ action.icon }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ action.text }}</span>
                </v-tooltip>
              </template>
            </div>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:group.header="{ group, headers, isOpen, toggle, items }">
        <td :colspan="headers.length">
          <div
            class="d-flex flex-row align-center"
            @click.stop="toggle"
            style="cursor: pointer"
          >
            <v-icon>
              <template v-if="isOpen"> mdi-minus-circle-outline</template>
              <template v-else> mdi-plus-circle</template>
            </v-icon>
            <h3 class="mx-3">{{ group }}</h3>
            <span>{{ groupResume ? groupResume(items) : "" }}</span>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="loading || collection.loading"
              v-if="$listeners.actionOnGroup"
              @click.stop="$emit('actionOnGroup', items)"
              >pay all
            </v-btn>
          </div>
        </td>
      </template>

      <template
        v-slot:[`header.${header.value}`]="{}"
        v-for="header in _headers"
      >
        <span
          class="d-flex flex-row flex-nowrap"
          :style="{ minWidth: header?.extend?.minWidth || '0px' }"
        >
          {{ fixColName(header) }}
          <v-icon
            size="14"
            color="primary"
            v-if="
              collection?.filters_keys.includes(fixColName(header)) ||
              collection?.filters_keys.includes(header.extend?.root) ||
              collection?.filters_keys.includes(header.value)
            "
          >
            mdi-filter
          </v-icon>
        </span>
      </template>
      <template
        v-slot:[`item.${modifiers.value}`]="{ value }"
        v-for="modifiers in col_modifiers"
      >
        <td-cell
          :col-style="modifiers.colStyle"
          :extend="modifiers.extend"
          :value="value"
        />
      </template>
      <template v-slot:header.data-table-select></template>
      <template v-slot:item.data-table-select="{ isSelected, item }">
        <v-simple-checkbox
          :value="isSelected"
          @input="append_item(item)"
          :disabled="!isSuperUser && itemDisabled(item)"
        >
        </v-simple-checkbox>
      </template>
      <template v-slot:footer="{ props }">
        <v-data-footer
          :items-per-page-options="[collection.limit]"
          show-current-page
          @update:options="pageChange"
          disable-items-per-page
          ref="footer"
          :pagination="props.pagination"
          @pagination:update="pageChange"
          :options="props.options"
        >
          <template v-slot:prepend>
            <div v-if="hideHeader" class="d-flex flex-row flex-grow-1">
              <div class="grow-shrink-0" v-if="'filter-dialog' in $slots">
                <v-btn icon @click="filterDialog = true">
                  <v-badge
                    color="alert"
                    :content="collection.filters_keys.length"
                    v-model="collection.filters_keys.length"
                  >
                    <v-icon> mdi-filter</v-icon>
                  </v-badge>
                </v-btn>
              </div>
              <div class="grow-shrink-0" v-if="allowDownload">
                <v-btn icon @click="downloadFile">
                  <v-icon> mdi-download</v-icon>
                </v-btn>
                <template v-for="action in $attrs.actionSelected">
                  <v-tooltip bottom v-if="!action.hidden">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        icon
                        @click="run_action(action.action)"
                        dark
                      >
                        <v-icon>
                          {{ action.icon }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ action.text }}</span>
                  </v-tooltip>
                </template>
              </div>
              <div
                class="grow-shrink-0 pb-1 d-flex flex-row"
                v-if="$listeners.onSearch"
              >
                <like-btn
                  class="my-auto"
                  :dark="false"
                  @onSearch="(v) => $emit('onSearch', v)"
                  :lazy="800"
                >
                </like-btn>
              </div>
              <div class="flex-grow-1"></div>
            </div>
          </template>
        </v-data-footer>
      </template>
    </v-data-table>
    <app-dialog
      icon="mdi-file-edit"
      v-if="$scopedSlots['edit-dialog']"
      title="Edit"
      v-model="editDialog"
      :width="editWidth"
    >
      <slot
        name="edit-dialog"
        v-bind="{ item: editing_item, headers: _headers }"
      >
      </slot>
    </app-dialog>
    <app-dialog
      icon="mdi-filter"
      title="filter"
      v-model="filterDialog"
      :width="editWidth"
    >
      <slot name="filter-dialog"></slot>
    </app-dialog>
  </v-sheet>
</template>
<script>
import tdCell from "@/components/molecules/tdCell.vue";
import AppDialog from "@/components/molecules/appDialog";
import { Collection } from "@/plugins/collection";
import likeBtn from "@/components/molecules/likeBtn";
import AutoForm from "@/plugins/AutoForms/AutoForm";
import { session } from "@/api/auth";

export default {
  name: "app-data-table",
  components: { AutoForm, likeBtn, AppDialog, tdCell },
  inheritAttrs: false,
  computed: {
    xs_viewing() {
      return this.$vuetify.breakpoint.xs;
    },
    isSuperUser() {
      return session.isSuperUser;
    },
    _itemClass() {
      return (item) => {
        let itemClass = this.itemClass;
        let itemDisabled = this.itemDisabled;
        let base = "";
        if (itemClass && itemClass(item)) {
          base += itemClass(item) + " ";
        } else if (itemDisabled) {
          base += (itemDisabled(item) ? "text--disabled" : " ") + " ";
        }
        return base;
      };
    },
    col_modifiers() {
      return this._headers.filter((h) => h.colStyle || h.extend);
    },
    _items() {
      if (Object.keys(this.calcCols).length > 0) {
        return (this.items ?? this.collection.items).map((item) => ({
          ...item,
          ...Object.keys(this.calcCols).reduce(
            (carr, curt) => ({
              ...carr,
              [curt]: this.calcCols[curt](item),
            }),
            {}
          ),
        }));
      }
      return this.items ?? this.collection.items;
    },
    _headers() {
      if (Object.keys(this.calcCols).length > 0) {
        return [
          ...(this.headers ?? this.collection.headers),
          ...Object.keys(this.calcCols).map((k) => ({
            text: k,
            value: k,
            sortable: false,
            extend: this.extendCols[k] ?? {},
          })),
        ];
      }
      return this.headers ?? this.collection.headers;
    },
  },
  data: () => ({
    items_selected: [],
    height_: "100%",
    page: 1,
    interval: null,
    editing_item: {},
    editDialog: false,
    filterDialog: false,
  }),
  props: {
    extendCols: {
      type: Object,
      default: () => ({}),
    },
    calcCols: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    groupResume: {
      type: Function,
    },
    groupBy: {},
    itemClass: {
      type: Function,
      default: () => "",
    },
    itemDisabled: {
      type: Function,
      default: () => false,
    },
    hideHeader: { default: () => true, type: Boolean },
    allowDownload: { default: () => false, type: Boolean },
    downloadParams: {
      default: () => null,
      type: Object,
    },
    height: { type: Number | String, default: () => null },
    editWidth: {},
    collection: {
      type: Collection,
    },
    headers: {},
    items: {},
    componentName: { type: String, default: () => Date.now().toString() },
  },
  beforeMount() {
    this.fetchData();
  },
  methods: {
    append_item(item) {
      let index = this.items_selected.indexOf(item);
      if (index > -1) {
        this.items_selected.splice(index, 1);
      } else {
        this.items_selected.push(item);
      }
    },
    run_action(action) {
      action(this.items_selected)?.then((res) => {
        this.items_selected = [];
      });
    },
    downloadFile() {
      this.collection.downloadFile(this.downloadParams);
    },
    fixColName(header) {
      return header.extend?.rename ?? header.value;
    },
    fetchData() {
      this.editDialog = false;
      return this.collection.filter({ page: this.page });
    },
    pageChange({ page, ...option }) {
      this.page = page;
      this.fetchData();
    },
    openEdit(row) {
      this.editing_item = row;
      this.$emit("openEdit", row);
      this.editDialog = true;
    },
    getWindowHeight() {
      if (this.height > 0) {
        this.height_ = `${this.height}px`;
        return;
      }

      let component = document.getElementById(this.componentName);
      const footer = this.$refs["footer"];
      const header = this.$refs["header"];
      if (component) {
        let footer_size = footer ? footer.$el.clientHeight : 0;
        // let header_size = header ? header.clientHeight : 0;
        let header_size = header ? header.$el.clientHeight : 0;
        const availableSpace = component.clientHeight;
        this.height_ = `${availableSpace - footer_size - header_size}px`;
      }
    },
  },
  mounted() {
    this.interval = setInterval(this.getWindowHeight, 333);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
