import {
  ConfigDefinition,
  DefineMethod,
  DefineMethodsModifiers,
  ExtendTableDefinition,
  FormDefinition,
  Table
} from "@/api/Table";
import {dateHelper, monetize, timeHelper, timeStampHelper, yesNo} from "@/plugins/filters";
import {extend, limit, nested, order_by} from "@/api/helper";


export const basic_service = new Table(
  '/app',
  'services'
)


const locations = ['origen', 'destino'].reduce((carr, curt) => ([
  ...carr,
  `${curt}.name`,
  `${curt}.code`,
]), [])

export const service_for_resume = new Table(
  '/app',
  'services',
  new ConfigDefinition(
    {
      ...limit(
        -1
      ),
      ...extend(
        [
          ...locations
          , 'driver_id.name'
          , 'driver_id.code'
          , 'agent_id.name'
        ]
      ),
      ...nested(),
      ...order_by(
        '-date'
      )
    }
  )
)


export const services = new Table(
  '/app',
  'services',
  new ConfigDefinition(
    {
      ...nested('origen', 'destino'),
      ...extend(
        'user_driver.code',
        'user_agent.name',
        'user_agent.is_collaborator',
        'driver_id.isPremium',
        'inserted_by.username',
        'payed_service.payed_at'
      )
    }
  ),
  new ExtendTableDefinition(
    [
      'observations',
      'inserted_by',
      'agent_id',
      'driver_id',
      'updated_at',
      'origen',
      'destino',
      'held_by'
    ], {
      is_lock: {
        colStyle: {
          type: "icon",
          match: {
            true: "mdi-lock",
            false: "mdi-lock-open-variant-outline"
          }
        }
      },
      'driver_id.isPremium': {
        rename: 'is_premium',
        colStyle: {
          type: "icon",
          match: {
            true: "mdi-star",
            false: "mdi-star-off-outline"
          }
        }
      },
      'interaction_id': {
        rename: 'Desde el Chat',
        minWidth: '7em',
        colStyle: {
          type: "icon",
          match: (value) => value != null ? "mdi-chat-outline" : "mdi-face-agent"
        }
      },
      'created_at': {
        ...timeStampHelper(),
      },
      "inserted_by.username": {
        rename: "inserted_by"
      },
      "user_agent.is_collaborator": {
        rename: "is_collaborator",
        filter: yesNo
      },
      origen_nested: {
        filter: (obj) => obj.name,
        rename: 'origen',
      },
      destino_nested: {
        filter: (obj) => obj.name,
        rename: 'destino',
      },
      'user_driver.code': {
        rename: "driver code",
        root: 'driver_id',
        minWidth: '6em'
      },
      'user_agent.name': {
        rename: 'agent name',
        root: 'agent_id'
      },
      is_done: {
        filter: yesNo
      },
      price: {
        filter: monetize
      },
      canceled_at: {
        ...timeStampHelper(),
      },
      driver_debt: {
        filter: monetize
      },
      date: {
        ...dateHelper(),
      },
      moment: {
        ...timeHelper()
      },
      'payed_service.payed_at': {
        ...timeStampHelper(),
      }
    }
  ), new DefineMethodsModifiers(
    new DefineMethod(
      new ConfigDefinition({
          ...nested(),
          ...extend()
        }
      ),
      null,
      [
        'agency',
        'user_driver.code',
        'user_agent.name',
        'updated_at',
        'created_at',
        "origen_nested",
        "destino_nested",
        "is_lock",
        "inserted_by",
        "inserted_by.username",
        'payed_service.payed_at',
        "user_agent.is_collaborator",
        'driver_id.isPremium',
        'interaction_id',
        'held_by',
      ],
      {
        price: {
          cols: {
            md: 4
          }
        }, driver_debt: {
          cols: {
            md: 8
          }, right: [{
            text: '15%',
            action: (
              {price, driver_debt, ...rest}
            ) => ({
              price, driver_debt: price * 0.15, ...rest
            })
          }, {
            text: '10%',
            action: (
              {price, driver_debt, ...rest}
            ) => ({
              price, driver_debt: price * 0.1, ...rest
            })
          }, {
            text: '5%',
            action: (
              {price, driver_debt, ...rest}
            ) => ({
              price, driver_debt: price * 0.05, ...rest
            })
          }]
        }, date: {
          cols: {
            md: 6
          },
        }, moment: {
          cols: {
            md: 6
          },
        }, 'origen': {
          cols: {
            md: 6
          },
        }, 'destino': {
          cols: {
            md: 6
          },
        },
      },
      new FormDefinition(
        [
          'driver_id',
          'price',
          'driver_debt',
          'date',
          'moment',
          'origen',
          'destino'
        ]
      )
    ),
    new DefineMethod(
      new ConfigDefinition(extend()),
      null,
      [
        'agency',
        'driver_id.isPremium',
        'user_driver.code',
        'user_agent.name',
        'updated_at',
        'created_at',
        "origen_nested",
        "destino_nested",
        "inserted_by",
        "inserted_by.username",
        'payed_service.payed_at',
        "user_agent.is_collaborator",
        "interaction_id"
      ],
    )
  )
);

export const service_operation = new Table(
  '/app',
  'services-opt'
)
export const service_take_off = new Table(
  '/app',
  'services-take-off'
)