<template>
    <app-basic-layout
            :hideHeader="true"
            :hide-header="true"
    >
        <app-data-table
                :allow-download="true"
                ref="appDataTable"
                :collection="collection"
                :hide-header="false"
                @onSearch="search"
                :item-class="(item)=>item['deposit.value']<=100? 'error white--text':( item['deposit.value']<=1000? 'warning':'success')"
        >
            <template v-slot:head>
                <h2>Deposit</h2>
            </template>
            <template
                    v-slot:edit-dialog="{item:{user_id,...item}}"
            >
                <auto-form
                        :protected-submit="true"
                        :form-fields="collection.formFields"
                        :exclude="['user','updated_at']"
                        :form-data="{...item,user_id}"
                        :loading-action="collection.loading"
                />
            </template>
        </app-data-table>
    </app-basic-layout>
</template>

<script>
import appBasicLayout from '@/components/templates/appBasicLayout.vue'
import AppDataTable from "@/components/organisms/AppDataTable.vue";
import {Collection} from "@/plugins/collection";
import {deposit} from "@/api/data/facture";
import AppDialog from "@/components/molecules/appDialog.vue";
import AutoForm from "@/plugins/AutoForms/AutoForm.vue";
import {extend, nested, values_list} from "@/api/helper";
import {session} from "@/api/auth";
import AppProtectedBtn from "@/components/organisms/appProtectedBtn.vue";

export default {
    name: "AppDepositView",
    components: {
        AppProtectedBtn,
        AutoForm,
        AppDialog,
        AppDataTable,
        appBasicLayout
    },
    computed: {
        isSuperUser() {
            return session.isSuperUser
        }
    },
    data: () => ({
        createDialog: false,
        collection: new Collection(
            deposit, {
              deposit__isnull: false
          }
        ),
        downloadParams: {
            ...extend('user_id.code', 'user_id.name'),
            ...nested()
        }
    }),
    methods: {
        search(text) {
            if (text.trim() == '') {
                this.collection.clear_filters(['like', 'like_cols']);
            } else {
                this.collection.filter({
                    like: text,
                    like_cols: [
                        'code',
                        'name',
                      'attended_by.name'
                    ],
                })
            }
        },
        updateDeposit(filter, obj) {
            this.collection.update(filter, obj).then(
                () => {
                    this.$refs['appDataTable'].fetchData();
                }
            )
        },
        deleteDeposit(filter) {
            this.collection.remove(
                filter
            ).then(() => {
                this.$refs['appDataTable'].fetchData();
            })
        },
        createNewDeposit(obj, autoForm) {
            this.collection.create(obj).then(() => {
                this.createDialog = false;
                autoForm.loadInnerValue();
                this.$refs['appDataTable'].fetchData();
            });
        }
    }
}
</script>

<style>

</style>