import {DOUBLE_PRECISION_TYPE_FIELD, FormFieldItem, INTEGER_TYPE_FIELD,BOOLEAN_TYPE_FIELD} from "@/plugins/AutoForms/tools";


export const text_field = [
  new FormFieldItem({
    text: 'Nuevo precio',
    type: DOUBLE_PRECISION_TYPE_FIELD,
    keyData: 'price',
    extend: {
      down: [
        {
          text: '+1000',
          action: (
            {price, ...rest}
          ) => ({
            price: 1000 + Number.parseFloat(price), ...rest
          })
        }, {
          text: '+500',
          action: (
            {price, ...rest}
          ) => ({
            price: 500 + Number.parseFloat(price), ...rest
          })
        }, {
          text: '+100',
          action: (
            {price, ...rest}
          ) => ({
            price: 100 + Number.parseFloat(price), ...rest
          })
        }, {
          text: '+50',
          action: (
            {price, ...rest}
          ) => ({
            price: 50 + Number.parseFloat(price), ...rest
          })
        },
      ]
    },
  }),
  new FormFieldItem({
    text: 'Tiempo estimado en minutos',
    type: INTEGER_TYPE_FIELD,
    keyData: 'onTime',
    default_value:()=>10,
    extend: {
      down: [
        {
          text: '+10',
          action: (
            {onTime, ...rest}
          ) => ({
            onTime: 10 + Number.parseFloat(onTime), ...rest
          })
        }, {
          text: '+5',
          action: (
            {onTime, ...rest}
          ) => ({
            onTime: 5 + Number.parseFloat(onTime), ...rest
          })
        },
      ]
    },

  }),
]

export const request_fields = [
  new FormFieldItem(
    {
      keyData: 'interaction_id',
      hidden: true,
    }
  ),
  new FormFieldItem({
    text: 'price',
    type: DOUBLE_PRECISION_TYPE_FIELD,
    extend: {
      cols: {sm: 4},
    }
  }),
  new FormFieldItem({
    text: 'En cuantos minutos llegas ?',
    type: INTEGER_TYPE_FIELD,
    keyData: "onTime",
    extend: {
      cols: {sm: 4},
    }
  }),
  new FormFieldItem({
    text: 'Pax',
    type: INTEGER_TYPE_FIELD,
    keyData: "pax",
    extend: {
      cols: {sm: 4},
    }
  }),
  new FormFieldItem({
    text: 'Dir. Origen',
    type: "VARCHAR(255)",
    keyData: "dir_origen",

  }),
  new FormFieldItem({
    text: 'Dir. Destino',
    type: "VARCHAR(255)",
    keyData: "dir_destino"
  }),
  new FormFieldItem({
    text: 'Origen',
    type: INTEGER_TYPE_FIELD,
    keyData: "origen",
    foreign_key: {table: "locations", column: "id"},
    extend: {
      cols: {sm: 6}
    }
  }),
  new FormFieldItem({
    text: 'Destino',
    type: INTEGER_TYPE_FIELD,
    keyData: "destino",
    foreign_key: {table: "locations", column: "id"},
    extend: {
      cols: {sm: 6}
    }
  }),
  new FormFieldItem({
    text: 'driver',
    type: INTEGER_TYPE_FIELD,
    foreign_key: {
      table: "user_driver",
    },
    keyData: "driver_id"
  }),
]