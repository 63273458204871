<template>
  <div class="my-auto">
    <v-chip
        v-if="colStyleType.type && colStyleType.type ==='color'"
        :color="colStyleType.match[value]"
        class="px-3 white--text"
        :style="colStyleType.style"
        style="justify-content: center"
    >
      {{ _value }}
    </v-chip>
    <v-icon
        v-else-if="colStyleType.type && colStyleType.type ==='icon'"
        class="px-3"
        :style="colStyleType.style"
        style="justify-content: center"
    >
      {{ _match }}
    </v-icon>
    <span v-else>{{ _value }}</span>
  </div>
</template>
<script>

class Modifier {
    colStyle
    extend

    constructor() {
    }
}

export default {
    name: 'tdCell',
    props: {
        modifier: {type: Modifier},
        extend: {
            default: () => ({})
        },
        value: {},
        colStyle: {}
    },
    computed: {
        _match() {
            let match = this.colStyleType?.match;
            if (match) {
                if (match.constructor === Function) {
                    return match(this.value);
                } else if (match.constructor === Object) {
                    return match[this.value];
                }
            }
            return null
        },
        _value() {
            let {filter} = this.extend
            if (filter) {
                return filter(this.value);
            }
            return this.value
        },
        colStyleType() {
            let {colStyle} = this.extend
            return this.modifier?.colStyle ?? this.colStyle ?? colStyle ?? {};
        }
    }
}
</script>