<template>
  <v-avatar
    v-if="is_urgent"
    color="danger"
    class="white--text"
    size="50"
  >
    <v-icon dark>mdi-ambulance</v-icon>
  </v-avatar>
  <v-avatar
    v-else
    color="primary"
    class="white--text"
    size="50"
  >
    <v-icon dark>mdi-taxi</v-icon>
  </v-avatar>
</template>
<script>
export default {
  name: 'app-chat-avatar',
  props: {
    is_urgent: {}
  }
}
</script>