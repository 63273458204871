<template>
    <app-basic-layout @add="openCreate">
        <template v-slot:head>
            <h2>Images</h2>
        </template>
        <app-data-table
                :collection="collection"
                ref="appDataTable"
                @openEdit="OpenEdit"
        />
        <app-dialog
                v-model="createDialog"
        >
            <api-image-field
                    :form-fields="collection.formFields"
                    @change="_create_item"
                    apiImage="imagesBlob"
            />
        </app-dialog>
    </app-basic-layout>
</template>

<script>
import {apiImageTables, files} from "@/api/data";

const {imagesBlob} = apiImageTables;
import {Collection} from "@/plugins/collection";
import ApiImageField from "@/plugins/AutoForms/FormFields/apiImageField";
import AppDataTable from "@/components/organisms/AppDataTable";
import appBasicLayout from "@/components/templates/appBasicLayout";
import autoForm from "@/plugins/AutoForms/AutoForm";
import AppDialog from "@/components/molecules/appDialog";

const collection = new Collection(files);

export default {
    name: "ImagesView",
    components: {AppDialog, ApiImageField, AppDataTable, appBasicLayout, autoForm},
    // mixins: [collection(images)],
    data: () => ({collection, createDialog: false}),
    methods: {
        OpenEdit(row) {
            console.log({...row})
        },
        openCreate() {
            this.createDialog = true;
        },
        create(obj) {
            this.collection.create(obj).then(() => {
                this.collection.fetchData();
                this.createDialog = false;
            })
        },
        _remove_item(filename) {
            imagesBlob.remove_by_id(
                filename
            ).then(res => {
                this.collection.fetchData();
                this.$refs['appDataTable'].editDialog = false;
            })
        },
        _update_item({filename, file}) {
            const formData = new FormData();
            formData.append('file', file);
            imagesBlob.update_by_id(
                filename,
                formData
            ).then(res => {
                this.collection.fetchData();
                this.$refs['appDataTable'].editDialog = false;
            });
        },
        _create_item({file}) {
            const formData = new FormData();
            formData.append('file', file);
            imagesBlob.create(
                formData
            ).then(res => {
                this.createDialog = false;
                this.collection.fetchData();
            });
        }
    }
}
</script>

<style scoped>

</style>