<template>
  <div>
    <p class="text-center">Por defecto se muestran solo los datos del usuario autentificado</p>
    <p class="text-center error white--text py-2">Los cancelados ser verán en rojo</p>
    <p class="text-center grey-1 white--text py-2">Los servicios para devolver el dinero de un servicio mal insertado</p>
    <p class="text-center">Para una búsqueda mas detallada usa el filtro
      <v-icon>mdi-filter</v-icon>
    </p>
  </div>
</template>
<script>
import AppDialog from "@/components/molecules/appDialog.vue"

export default {
  name: 'app-service-help-dialog',
  components: {AppDialog},
}
</script>