<template>
  <app-basic-layout @add="openCreate" :hideHeader="true">
    <app-data-table
        ref="appDataTable"
        :collection="collection"
        @onSearch="search"
        :hideHeader="false"
        :allow-download="true"
    >
      <template v-slot:head>
        <h2 class="ma-auto">Agents</h2>
      </template>
      <template v-slot:edit-dialog="{item:{user_id,...item}}">
        <auto-form
            :loading-action="collection.loading"
            :form-data="item"
            :formFields="formFields"
            @submit="(obj)=>_update_item({user_id},obj)"
        >
          <template v-slot:prepend-action>
            <v-btn
                :disabled="collection.loading"
                :loading="collection.loading"
                color="error"
                @click.stop="_remove_item({user_id})">
              delete
            </v-btn>
          </template>
        </auto-form>
      </template>
    </app-data-table>
    <app-dialog
        v-model="createDialog"
        title="New Agent"
    >
      <auto-form
          :loading-action="collection.loading"
          text="create"
          :form-data="collection.formData"
          :formFields="formFields"
          :exclude="['id']"
          @clear="clear"
          @submit="create"
      />
    </app-dialog>
  </app-basic-layout>
</template>

<script>
import {Collection} from "@/plugins/collection";
import AppDataTable from "@/components/organisms/AppDataTable";
import appBasicLayout from "@/components/templates/appBasicLayout";
import autoForm from "@/plugins/AutoForms/AutoForm";
import {user_agent} from "@/api/data/user";
import AppDialog from "@/components/molecules/appDialog";
import {FormFieldItem} from "@/plugins/AutoForms/tools";

const constalation_name_field = new FormFieldItem({
    text: 'constalation_name',
    keyData: "constalation_name",
    type: 'VARCHAR(100)',
    foreign_key: {table: "constelation_ext"},
})


export default {
    name: "UsersView",
    components: {AppDialog, AppDataTable, appBasicLayout, autoForm},
    // mixins: [collection(user_agent)],
    data: () => ({
        collection: new Collection(user_agent),
        createDialog: false,
    }),
    computed: {
        formFields() {
            return this.collection.formFields.map(
                item => {
                    if (item.keyData == 'constalation_name') {
                        return constalation_name_field
                    }
                    return item
                }
            )
        },

    },
    methods: {
        search(text) {
            if (text.trim() == '') {
                this.collection.clear_filters(['like', 'like_cols']);
            } else {
                this.collection.filter({
                    like: text,
                    like_cols: [
                        'name',
                        'constalation_name',
                        'user_account.username',
                        'user_account.phone_number'
                    ],
                })
            }
        },
        clear() {
            this.collection.clearData();
        },
        create(obj) {
            this.collection.create(obj).then(() => {
                this.collection.fetchData();
                this.createDialog = false;
            });
        },
        openCreate() {
            this.createDialog = true;
        },
        _remove_item(filter) {
            this.collection.remove(filter).then(res => {
                this.collection.fetchData();
                this.$refs['appDataTable'].editDialog = false;
            })
        },
        _update_item(filter, obj) {
            this.collection.update(filter, obj).then(res => {
                this.collection.fetchData();
                this.$refs['appDataTable'].editDialog = false;
            })
        }
    }
}
</script>

<style scoped>

</style>