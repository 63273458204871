import {session} from "@/api/auth";

export const SubscriptionMixin = {
    data: () => ({
        subscriptions_ids: [],
    }),
    beforeDestroy() {
        this.disconnect_ws();
    },
    methods: {
        multikey_subscribe(keys, callback) {
            keys.forEach(
                key => this.mixinSubscribe(key, callback)
            )
        },
        mixinSubscribe(key, callback) {
            const subscribe_id = session.subscribe(
                key,
                callback
            )
            this.subscriptions_ids.push(
                subscribe_id
            );
        },
        disconnect_ws() {
            this.subscriptions_ids.forEach(
                subscription => {
                    session.unsubscribe(subscription)
                }
            )
        },
    }
};

