import {ApiSelect} from "@/api/Table";
import {user_account, user_agent, user_agent_basic, user_driver} from "@/api/data/user";
import {procedures} from '@/api/data/procedures';
import {locations} from "@/api/data/locations";

export function apiSelectTables(selector_name) {
    if (selector_name == "user_agent")
        return new ApiSelect(
            user_agent,
            'user_id',
            ['constalation_name', 'name'],
            (item) => `(${item['constalation_name']}) ${item['name']}`
        );
    if (selector_name == 'user_driver')
        return new ApiSelect(
            user_driver, 'user_id',
            ['code', 'name'],
            ({code, name}) => `(${code}) ${name}`
        )
    if (selector_name == 'locations')
        return new ApiSelect(
            locations, 'id', ['name', 'code'],
            ({name, code}) => code ? `(${code}) ${name}` : name
        )
    if (selector_name == 'procedures')
        return new ApiSelect(
            procedures, 'id', 'name'
        )
    if (selector_name == 'user_account')
        return new ApiSelect(
            user_account, 'id', ['username', 'phone_number'],
            ({username, phone_number}) => phone_number ? `(${phone_number}) ${username}` : username
        )
    if (selector_name == 'locations_ext')
        return new ApiSelect(
            locations, ({id, name}) => `${id},${name}`, ['id', 'name', 'code'],
            ({name, code}) => code ? `(${code}) ${name}` : name
        )
    if (selector_name == 'constelation_ext')
        return new ApiSelect(
            user_agent_basic,
            ({constalation_name}) => `${constalation_name}`,
            ["constalation_name"],
            ({constalation_name}) => constalation_name,
            {admit_new: true}
        )
    return null;
}