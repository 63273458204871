<template>
  <v-text-field
    dense
    filled
    v-on="{...$listeners,...$on}"
    v-bind="modifiers"
    v-model="innerValue"
    :type="dtype"
    :counter="rules.max_length">

  </v-text-field>
</template>

<script>
import {keyFormData} from "@/plugins/AutoForms/autoFieldMixin";

export default {
  inheritAttrs: false,
  name: "textField",
  mixins: [keyFormData],
  computed: {
    modifiers() {
      const {type, ...rest} = this.$attrs;
      if (this.rules && (
        this.rules.decimal
        || this.rules.double
      )) {
        rest['step'] = '0.01'
      }
      return rest;
    }
  }
}
</script>

<style scoped>

</style>