<template>
  <v-card-title class="py-0 d-flex flex-row flex-nowrap">
    <v-avatar style="overflow: visible" class="pr-3 my-auto">
      <v-icon
          color="red" v-if="service.canceled_at">mdi-cancel
      </v-icon>
      <v-icon
          color="green" v-else-if="service.is_done">mdi-cash-check
      </v-icon>
      <template v-else>
        <v-icon
            color="orange" class="going-to-left">
          mdi-run-fast
        </v-icon>
        <v-icon
            color="orange">mdi-taxi
        </v-icon>
      </template>
    </v-avatar>
    <div class="py-2 d-flex flex-md-row flex-column px-3 flex-grow-1 flex-md-grow-0 justify-center">
      <h5 class="flex-shrink-0 flex-grow-0 mx-auto ">
        {{ service['date']|dateFormat }}
        {{ service.moment |time }}
      </h5>
      <div v-if="$vuetify.breakpoint.mdAndUp" class="px-3">|</div>
      <h5 class="flex-shrink-0 flex-grow-0 mx-auto">
        {{ service['origen.name'] }}
        <v-icon>
          mdi-arrow-right
        </v-icon>
        {{ service['destino.name'] }}
      </h5>
    </div>
  </v-card-title>
</template>
<script>
export default {
    name: 'app-service-resume-header',
    props: {
        service: {},
    }
}
</script>

<style>

</style>