<template>
  <div
    class="overflow-auto d-flex flex-column align-stretch justify-start"
    style="height: 100%"
  >
    <div class="grow-shrink-0" v-if="!hideHeader">
      <v-toolbar class="ma-2">
        <slot name="head" />
        <v-spacer />
        <template v-if="$listeners.add">
          <app-protected-btn
            v-if="protectedAdd"
            color="primary"
            @submit="(...args) => $emit('add', ...args)"
          >
            add
            <v-icon> mdi-plus </v-icon>
          </app-protected-btn>
          <v-btn
            v-else
            color="primary"
            @click.stop="(...args) => $emit('add', ...args)"
          >
            add
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </template>
        <slot name="head:left" />
        <v-progress-circular indeterminate v-if="loading"></v-progress-circular>
      </v-toolbar>
    </div>
    <div
      v-if="!loading"
      class="flex-grow-1 overflow-auto px-2 d-flex flex-column"
      :class="{ 'mt-2': hideHeader, 'pb-2': !bottom }"
    >
      <slot> </slot>
      <v-fab-transition>
        <template v-if="$listeners.add && hideHeader">
          <app-protected-btn
            v-if="protectedAdd"
            color="primary"
            fixed
            fab
            large
            dark
            bottom
            right
            @submit="(...args) => $emit('add', ...args)"
            style="bottom: 4em; right: 1.5em"
          >
            <v-icon>mdi-plus</v-icon>
          </app-protected-btn>
          <v-btn
            color="primary"
            fixed
            fab
            large
            dark
            bottom
            right
            @click.stop="(...args) => $emit('add', ...args)"
            style="bottom: 4em; right: 1.5em"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </v-fab-transition>
    </div>
  </div>
</template>

<script>
import AppProtectedBtn from "@/components/organisms/appProtectedBtn.vue";

export default {
  name: "appBasicLayout",
  components: { AppProtectedBtn },
  props: {
    bottom: {
      type: Boolean,
      default: () => false,
    },
    hideHeader: {},
    loading: {},
    protectedAdd: {},
  },
};
</script>
