<template>
  <div>
    <v-text-field
        readonly
        dense
        filled
        prepend-inner-icon="mdi-currency-usd" label="precio" :value="service.price"
        class="d-flex flex-row flex-nowrap">
    </v-text-field>
    <div class="d-flex flex-row">
      <v-text-field
          readonly
          filled
          dense
          label="origen" :value="service['origen.name']"
          append-outer-icon="mdi-arrow-right"></v-text-field>
      <v-text-field
          readonly
          filled
          dense
          label="destino"
          :value="service['destino.name']"
      ></v-text-field>
    </div>
    <v-text-field
        readonly
        dense
        filled
        label="Chofer"
        prepend-inner-icon="mdi-taxi"
        :value="`${service['driver_id.code']}(${service['driver_id.name']})`"
    ></v-text-field>
    <v-text-field
        readonly
        dense
        filled
        v-if="service['agent_id.name']"
        label="Agente"
        prepend-inner-icon="mdi-account-tie"
        :value="service['agent_id.name']"
    ></v-text-field>
    <v-textarea
        readonly
        filled
        auto-grow
        label="Observations:" :value="service['observations']"></v-textarea>
    <div class="d-flex flex-row">
      <v-spacer></v-spacer>
      <v-btn
          icon
          :to="{name:'service_details',params:{service_id:service.id}}"
      >
        <v-icon>
          mdi-arrow-right
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>

export default {
    name: 'app-service-resume-content',
    props: {
        service: {}
    },
}
</script>