<template>
  <div
      class="d-flex flex-wrap flex-row py-2 pb-3 mt-2"
      style="box-sizing: content-box;position: relative"
  >
    <span class="text--secondary caption" style="position: absolute;top: -1em;left: 0">{{ label }}</span>
    <v-radio-group
        class="ma-0 pa-0"
        row
        hide-details
        v-model="innerValue"
        v-bind="$attrs"
        v-on="$listeners"
    >
      <v-radio
          label="yes"
          :value="true"
      ></v-radio>
      <v-radio
          label="no"
          :value="false"
      ></v-radio>
      <v-radio
          v-if="!rules.required"
          label="None"
          :value="null"
      ></v-radio>
    </v-radio-group>
    <!--    <span class="px-2 my-auto" style="min-width: 8em">{{ label }} :</span>-->
  </div>
</template>

<script>

import {keyFormData} from "@/plugins/AutoForms/autoFieldMixin";

export default {
    name: "radioField",
    mixins: [keyFormData],
    props: {
        label: String
    }
}
</script>

<style scoped>

</style>