var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{ref:"observer",staticStyle:{"display":"block"},attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function({ valid, invalid }){return [_c('form',{staticClass:"d-flex flex-column pa-0 ma-0",staticStyle:{"position":"relative"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"flex-grow-1 d-flex",class:[_vm.inline ? 'flex-row' : 'flex-column']},[_vm._t("default",null,null,_vm.innerValue),(invalid)?_c('span',{staticClass:"red--text"},[_vm._v(" * Campos requeridos ")]):_vm._e(),_c('v-row',{staticClass:"flex-grow-1 overflow-auto ma-0 pa-1"},[_vm._l((_vm._formFields),function(field){return [(field.dependes_on(_vm.innerValue))?_c('app-basic-row',{key:`${field.keyData}||${
              _vm.readOnly ||
              _vm.readOnlyArray.includes(field.keyData) ||
              field.disabled ||
              !_vm.$listeners.submit
            }`,staticClass:"px-1",attrs:{"field":field,"disabled":_vm.isDisabledForm || _vm.readOnly},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},[_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"vid":field.keyData,"rules":field.rules},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('auto-field',_vm._b({staticClass:"flex-grow-1",attrs:{"errorMessages":errors,"disabled":_vm.isDisabledForm ||
                  _vm.readOnly ||
                  _vm.readOnlyArray.includes(field.keyData) ||
                  field.disabled ||
                  !_vm.$listeners.submit},model:{value:(_vm.innerValue[field.keyData]),callback:function ($$v) {_vm.$set(_vm.innerValue, field.keyData, $$v)},expression:"innerValue[field.keyData]"}},'auto-field',field,false)),_c('span',[_vm._v(_vm._s(field.description))])]}}],null,true)})],1):_vm._e()]})],2),_c('v-row',[_vm._t("append",null,null,_vm.innerValue)],2),(!_vm.isDisabledForm)?_c('div',{staticClass:"my-auto grow-shrink-0 d-flex flex-row pt-3"},[_vm._t("prepend-action",null,{"item":_vm.innerValue}),_c('v-spacer'),(_vm.$listeners.clear)?_c('v-btn',{staticClass:"mr-4",attrs:{"text":""},on:{"click":_vm.clear}},[_vm._v(" clear ")]):_vm._e(),(_vm.$listeners.submit && _vm.onValidateFunction)?[(_vm.protectedSubmit)?_c('app-protected-btn',{attrs:{"color":_vm._actionColor,"loading":_vm.loadingAction,"disabled":_vm.loadingAction || invalid || !valid || _vm.isDisabledForm},on:{"submit":_vm.submit}},[_vm._v(" "+_vm._s(_vm._actionName)+" ")]):_c('v-btn',{attrs:{"color":_vm._actionColor,"type":"submit","loading":_vm.loadingAction,"disabled":_vm.loadingAction || invalid || !valid || _vm.isDisabledForm}},[_vm._v(" "+_vm._s(_vm._actionName)+" ")])]:_vm._e()],2):_vm._e()],2)])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }