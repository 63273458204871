<template>
  <div v-if="is_loading" class="d-flex flex-column" style="height:20em">
    <v-progress-circular indeterminate size="20" class="ma-auto"></v-progress-circular>
  </div>
  <v-expansion-panels v-else v-model="showImage">
    <v-expansion-panel>
      <v-expansion-panel-header>
        Image
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div style="position: relative;min-height: 2em">
          <v-img
              contain
              v-if="imgUrl||srcImage"
              :src="file? imgUrl:srcImage"
          />
          <v-slide-x-transition>
            <div
                class="d-flex flex-row pa-3"
                :class="imgUrl||srcImage? 'justify-end':'justify-center'"
                style="position: absolute;width: 100%; bottom: 0;gap:1ch"
            >
              <v-btn
                  v-if="!file"
                  color="gray"
                  small
                  dark
                  fab
                  @click="bypass_click"
              >
                <v-icon>mdi-image-edit</v-icon>
              </v-btn>
              <template
                  v-else
              >
                <v-btn
                    fab
                    dark
                    small
                    color="green"
                    @click="_update_item"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
                <v-btn
                    fab
                    dark
                    small
                    color="red"
                    @click="()=>file = null"
                >
                  <v-icon>mdi-cancel</v-icon>
                </v-btn>
              </template>
            </div>
          </v-slide-x-transition>
          <input
              type="file"
              v-show="false"
              @change="(event)=>{file=event.target.files[0]}"
              ref="fileInput"
          />
        </div>

      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="item">
      <v-expansion-panel-header>
        show description
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <auto-form
            :disabled="true"
            :form-data="item"
            :formFields="formFields"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import {apiImageTables} from "@/api/data";
import AutoForm from "@/plugins/AutoForms/AutoForm";

export default {
  name: "apiImageField",
  components: {AutoForm},
  props: ["item", "apiImage", 'formFields'],
  data: () => ({
    showImage: 0,
    file: null,
    srcImage: null
  }), computed: {
    imageCollection() {
      if (this.apiImage) {
        return apiImageTables[this.apiImage]
      }
      return null;
    },
    imgUrl() {
      if (this.file)
        return URL.createObjectURL(this.file);
      return null
    },
  },
  beforeMount() {
    if (this.item ?? false) {
      this.loadingOn(
          this.loadImg(this.item.filename)
      );
    }
  },
  methods: {
    bypass_click() {
      const fileInput = this.$refs['fileInput'];
      fileInput.click();
    },
    async loadImg(filename) {
      const file = await this.imageCollection.get_by_id(filename);
      this.srcImage = URL.createObjectURL(file);
    },
    _remove_item(filter) {
      this.remove(filter).then(res => {
        this.$emit('change', {})
      })
    },
    _update_item() {
      const file = this.file;
      const {filename} = this.item ?? {};
      this.$emit('change', {filename, file})
      this.file = null;
    }
  }
}
</script>
