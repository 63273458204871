<template>
  <v-app id="app">
    <v-navigation-drawer
        fixed dark app
        v-model="drawer"
        color="primary"
        width="300"
        :clipped="$vuetify.breakpoint.lgAndUp"
        :mini-variant="mini"
        :mini-variant-width="70"
    >
      <v-list-item @click="mini = !mini">
        <v-list-item-icon>
          <keep-alive>
            <v-icon v-if="mini">mdi-chevron-right</v-icon>
            <v-icon v-else>mdi-chevron-left</v-icon>
          </keep-alive>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Menu</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <template
          v-for="{icon, name,children,model} in links"
      >
        <v-list>
          <template v-if="!children">
            <v-tooltip right :disabled="!mini">
              <template v-slot:activator="{ on }">
                <v-list-item
                    :key="icon"
                    link
                    v-on="on"
                    :to="{name}"
                >
                  <v-list-item-icon>
                    <v-icon>{{ icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <span>{{ name }}</span>
            </v-tooltip>
          </template>
          <template v-else>
            <v-tooltip right :disabled="!mini">
              <template v-slot:activator="{on}">
                <v-list-group
                    active-class="white"
                    :value="model"
                    :prepend-icon="icon"
                    v-on="on"
                >
                  <template v-slot:activator>
                    <v-list-item-title>{{ name }}</v-list-item-title>
                  </template>
                  <template v-for="({icon, name}, i) in children">
                    <v-tooltip right :disabled="!mini">
                      <template v-slot:activator="{on}">
                        <v-list-item
                            :key="i"
                            style="border-left: white solid 1em"
                            link
                            active-class="white"
                            :to="{name}"
                            v-on="on"
                        >
                          <v-list-item-icon>
                            <v-icon v-text="icon"/>
                          </v-list-item-icon>
                          <v-list-item-title v-text="name"></v-list-item-title>
                        </v-list-item>
                      </template>
                      <span>{{ name }}</span>
                    </v-tooltip>
                  </template>
                </v-list-group>
              </template>
              <span>{{ name }}</span>
            </v-tooltip>
          </template>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-app-bar
        app fixed
        clipped-right
        clipped-left
        color="primary"
    >
      <v-toolbar-title
          class="d-flex flex-row flex-grow-1"
      >
        <div
            class="d-flex flex-row"
            @click.stop="drawer = !drawer"
        >
          <v-icon class="ma-auto mr-3" color="white">
            mdi-menu
          </v-icon>
          <img
              alt="logo"
              src="@/assets/logo.png"
              class="hidden-xs-only"
              style="height:55px;margin-top: .33em"
          >
        </div>
        <v-spacer></v-spacer>
        <app-alert-menu/>
        <div class="my-auto d-flex flex-row">
          <app-user-menu/>
        </div>
      </v-toolbar-title>
    </v-app-bar>
    <v-main class="d-block overflow-auto">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import {internal_routers} from "@/router";
import {session} from "@/api/auth";
import {FormFieldItem, PASSWORD_FIELD, TEXT_FIELD} from "@/plugins/AutoForms/tools";
import AutoForm from "@/plugins/AutoForms/AutoForm";
import AppDialog from "@/components/molecules/appDialog";
import AppAlertMenu from "@/views/app/AppAlertMenu.vue";
import AppUserMenu from "@/views/app/AppUserMenu.vue";

function filter_hidden(router_list) {
    return router_list.filter(
        _route => !_route.hidden
    ).map(_route => {
        if (_route.children && _route.children.length > 0) {
            _route.children = filter_hidden(_route.children)
        }
        return _route
    })
}

export default {
    name: 'App',
    components: {AppUserMenu, AppAlertMenu, AppDialog, AutoForm},
    data: () => ({
        drawer: true,
        mini: false,
        links: filter_hidden(internal_routers),
        isUpdating: false,
    }),
    methods: {
        drawer_change(value) {
            this.drawer = !value
        },
        drawer_input(value) {
            this.drawer = value
        },
    }
};
</script>

<style lang="scss">

.app-icon {
  width: 70px !important;
  height: 70px !important;
}

* {
  box-sizing: border-box;
}

main {
  height: 100vh !important;
  width: 100vw !important;
}

//bg-colors
$colors: (1: orange, 2: red, 3: green, 4: purple);
$sizes: ("": 1px, "-md": 0.3em, "-lg": 1em);

@each $name-s, $size in $sizes {
  @each $name-c, $color in $colors {
    .bg#{$name-c}#{$name-s} {
      border: solid $size $color;
    }
  }
}

.v-main__wrap {
  height: 100% !important;
  width: 100% !important;
  overflow: auto;
}

</style>
