<template>
  <v-app id="app">
    <v-main class="d-flex flex-row">
      <div
        class="fill-height d-flex flex-column justify-space-around align-center"
      >
        <v-card :elevation="2" class="mx-2 py-2">
          <v-card-title class="d-flex flex-column pb-0"></v-card-title>
          <v-card-text>
            <auto-form
              :loading-action="isLogin"
              :formFields="formFields"
              @submit="signup"
              :action-name="() => 'Registrarse'"
            >
              <v-card-title class="px-0"
                ><h2>Creando nuevo usuario:</h2></v-card-title
              >
              <template v-slot:prepend-action>
                <v-btn @click="$router.back()"> Ir atrás</v-btn>
              </template>
            </auto-form>
          </v-card-text>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import AutoForm from "@/plugins/AutoForms/AutoForm";
import {
  FormFieldItem,
  PASSWORD_FIELD,
  TEXT_FIELD,
} from "@/plugins/AutoForms/tools";
import { session } from "@/api/auth";
import { apiAlert } from "@/plugins/alerts";

export default {
  name: "loginView",
  components: { AutoForm },
  data: () => ({
    isLogin: false,
    formFields: [
      new FormFieldItem({
        type: TEXT_FIELD,
        nullable: false,
        keyData: "username",
        text: "nombre de usuario",
      }),
      new FormFieldItem({
        type: TEXT_FIELD,
        nullable: false,
        keyData: "email",
        text: "correo",
        rules: {
          email: true,
        },
      }),
      new FormFieldItem({
        type: TEXT_FIELD,
        nullable: false,
        keyData: "phone_number",
        text: "numero de telephono",
      }),
      new FormFieldItem({
        type: PASSWORD_FIELD,
        nullable: false,
        keyData: "password",
        text: "contraseña",
        rules: {
          password: true,
        },
      }),
      ,
      new FormFieldItem({
        type: PASSWORD_FIELD,
        nullable: false,
        keyData: "confirm_password",
        text: "confirma contraseña",
        rules: {
          equalTo: { target: "@password" },
        },
      }),
    ],
  }),
  methods: {
    signup({ password, confirm_password, ...data }, autoform) {
      this.isLogin = true;
      session
        .signup({ password, ...data })
        .then((res) => {
          this.$router.push({name: "login"});
        })
        .catch(apiAlert.promiseError)
        .finally(() => {
          this.isLogin = false;
        });
    },
  },
};
</script>

<style scoped></style>
