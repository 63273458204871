<template >
  <v-select
      filled
      dense
      v-bind="$attrs"
      v-on="$listeners"
  />
</template>

<script>
export default {
  name: "selectField",
  props: ['rules']
}
</script>

<style scoped>

</style>