<script>
import { session } from "@/api/auth";
import AppChat from "@/views/bazaar/components/bazzarServiceRequest/appChat.vue";
import AppBazaarInteraction from "@/views/bazaar/components/appBazaarInteraction.vue";
import AutoForm from "@/plugins/AutoForms/AutoForm.vue";
import { WebsocketApp } from "@/plugins/websocketApp";
import AppDialog from "@/components/molecules/appDialog.vue";
import {
  request_fields,
  text_field,
} from "@/views/bazaar/bazzar_request_service";
import { interactions, request } from "@/api/data/bazaar";
import AppDriverthumbUp from "@/views/bazaar/sub-views/components/appDriverthumbUp.vue";
import AppAgenthumbUp from "@/views/bazaar/sub-views/components/appOwnerOrAgenthumbUp.vue";

export default {
  name: "app-chat-box",
  components: {
    AppDriverthumbUp,
    AppDialog,
    AutoForm,
    AppBazaarInteraction,
    AppChat,
    AppAgenthumbUp,
  },
  data: () => ({
    view_proposals: true,
    requestDialog: false,
    request_fields,
    text_field,
    interaction_selected: null,
    riseHandDialog: false,
    riseAgentProposalDialog: false,
    proposal: null,
  }),
  props: {
    interactions: {
      type: Object,
      required: true,
    },
    chat: {
      type: Object,
      required: true,
    },
    connection: { required: true, type: WebsocketApp },
  },
  computed: {
    chat_price() {
      return this.chat.description["price"];
    },
    ownerInteraction() {
      return Object.values(this.interactions).find(
        (item) => item.user_id === this.chat.seller
      );
    },
    yourInteraction() {
      if (session.me.id === this.chat.seller) {
        // you are the owner
        return null;
      }
      return Object.values(this.interactions).find(
        (item) => item.user_id === session.me.id
      );
    },
    agentsInteractions() {
      return Object.values(this.interactions).filter(
        (item) =>
          item.user_id != this.chat.seller &&
          item.user_id != session.me.id &&
          !item.user.is_driver &&
          (item.user.is_agent || item.user.is_superuser)
      );
    },
    driversInteractions() {
      return Object.values(this.interactions).filter(
        (item) =>
          item.user_id != this.chat.seller &&
          item.user_id != session.me.id &&
          item.user.is_driver
      );
    },
    current_user() {
      return session.me;
    },
    chat_description() {
      return this.chat.description;
    },
  },
  methods: {
    riseProposal(isAgent) {
      this.proposal = {
        price: this.chat_price,
        bazaar_id: this.chat.id,
      };
      if (isAgent) {
        this.riseAgentProposalDialog = true;
      } else {
        this.riseHandDialog = true;
      }
    },
    hiddeTrip() {
      alert("developing");
    },
    isSamePrice(price) {
      return price == this.chat_price;
    },
    onChangePriceFormActionName({ price }) {
      return this.isSamePrice(price) ? "accept" : "price";
    },
    onChangePriceFormActionColor({ price }) {
      return this.isSamePrice(price) ? "success" : "primary";
    },
    onSelectInteraction(interaction) {
      this.interaction_selected = interaction;
      this.requestDialog = true;
    },
    sendRequest({ interaction_id, ...fields }, autoform) {
      request.create({ interaction_id }).then(() => {
        this.requestDialog = false;
      });
      autoform.loadInnerValue();
    },
    post_new_price({ price, onTime, bazaar_id }, autoform) {
      interactions
        .create({ description: { price, onTime }, bazaar_id })
        .then(() => {
          this.riseHandDialog = false;
          this.riseAgentProposalDialog = false;
          if (autoform) autoform.loadInnerValue();
        });
    },
  },
};
</script>

<template>
  <div>
    <AppAgenthumbUp
      v-if="!!current_user.agent || current_user.id == chat.seller"
      @yo="riseProposal(true)"
      @cancel="hiddeTrip"
    >
    </AppAgenthumbUp>
    <AppDriverthumbUp
      v-else-if="!!current_user.driver"
      @yo="riseProposal(false)"
      @cancel="hiddeTrip"
    >
    </AppDriverthumbUp>
    <app-chat :chat="chat" :collapsable="true"></app-chat>
    <v-timeline
      align-top
      dense
      class="flex-grow-1 d-flex flex-column overflow-auto"
    >
      <!-- ownerInteraction : keep in mind app show only last interaction per user -->
      <app-bazaar-interaction
        v-if="current_user.id !== chat.seller && !!ownerInteraction"
        :interaction="ownerInteraction"
        :chat="chat"
        color="red"
        :activeAccept="false"
        @accept="onSelectInteraction"
      >
      </app-bazaar-interaction>
      <!-- yourInteraction : keep in mind app show only last interaction per user -->
      <app-bazaar-interaction
        v-if="!!yourInteraction"
        :interaction="yourInteraction"
        :chat="chat"
        color="green"
      >
      </app-bazaar-interaction>
      <v-expansion-panels>
        <v-expansion-panel class="bg2">
          <v-expansion-panel-header>
            Mostrar interacciones de los agentes ({{
              agentsInteractions.length
            }})
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-timeline
              align-top
              dense
              class="pl-0 bg1 flex-grow-1 d-flex flex-column overflow-auto"
            >
              <app-bazaar-interaction
                v-for="agentsInteraction in agentsInteractions"
                :key="agentsInteraction.id"
                :interaction="agentsInteraction"
                :chat="chat"
                :active-accept="chat.seller == current_user.id"
                @accept="
                  post_new_price({
                    price: agentsInteraction.description.price,
                    bazaar_id: chat.id,
                  })
                "
              >
              </app-bazaar-interaction>
            </v-timeline>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <template v-for="driversInteraction in driversInteractions">
        <app-bazaar-interaction
          color="orange"
          :key="driversInteraction.id"
          :interaction="driversInteraction"
          :chat="chat"
          :active-accept="chat.seller == current_user.id"
          @accept="onSelectInteraction(driversInteraction)"
        >
        </app-bazaar-interaction>
      </template>
    </v-timeline>
    <app-dialog title="Desea comenzar la solicitud" v-model="riseHandDialog">
      <auto-form
        :form-data="proposal"
        class="flex-shrink-0"
        :form-fields="text_field"
        @submit="post_new_price"
        :actionName="onChangePriceFormActionName"
        :actionColor="onChangePriceFormActionColor"
      >
        <app-chat :chat="chat"></app-chat>
      </auto-form>
    </app-dialog>
    <app-dialog
      title="Desea proponer un nuevo precio"
      v-model="riseAgentProposalDialog"
    >
      <auto-form
        :form-data="proposal"
        class="flex-shrink-0"
        :form-fields="text_field.slice(0, 1)"
        @submit="post_new_price"
        :actionName="onChangePriceFormActionName"
        :actionColor="onChangePriceFormActionColor"
      >
        <app-chat :chat="chat"></app-chat>
      </auto-form>
    </app-dialog>
    <app-dialog title="Desea comenzar la solicitud" v-model="requestDialog">
      <auto-form
        :readOnly="true"
        :editArray="['onTime']"
        :form-fields="request_fields"
        :form-data="{
          interaction_id: interaction_selected?.id,
          price: interaction_selected?.description['price'],
          dir_origen: chat_description['origen'],
          dir_destino: chat_description['destino'],
          origen: chat_description['_origen'],
          destino: chat_description['_destino'],
          pax: chat_description['pax'],
          onTime: interaction_selected?.description['onTime'],
          driver_id: interaction_selected?.user.user_id,
        }"
        @submit="sendRequest"
      >
      </auto-form>
    </app-dialog>
    <slot></slot>
  </div>
</template>

<style scoped></style>
