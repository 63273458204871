<template>
  <app-basic-layout @add="createDialog">
    <template v-slot:head>
      <h2>Resource</h2>
    </template>
    <v-data-iterator
        class="flex-grow-1 fill-height"
        :items="items"
    >
      <template v-slot:default="{items}">
        <v-expansion-panels>
          <v-expansion-panel
              v-for="(item,i) in items"
              :key="i"
          >
            <v-expansion-panel-header>
              <div class="d-flex">
                <td-cell :col-style="modifiers.colStyle.driver" :value="item.driver"/>
                <td-cell :col-style="modifiers.colStyle.table_name" :value="item.table_name"/>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <app-table-resource :table-name="item.table_name">
              </app-table-resource>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-data-iterator>
    <app-dialog
        v-model="create_dialog"
        title="Create a new access"
    >
      <auto-form
          :loading-action="isCreating"
          :form-fields="form_fields" @submit="create"/>
    </app-dialog>
  </app-basic-layout>
</template>

<script>

import appBasicLayout from "@/components/templates/appBasicLayout";
import api, {secure_table} from "@/api/data";
import appTableResource from "@/components/organisms/appTableResource";
import AutoForm from "@/plugins/AutoForms/AutoForm";
import {FormFieldItem, TEXT_FIELD, SELECT_FIELD} from "@/plugins/AutoForms/tools";
import tdCell from "@/components/molecules/tdCell.vue";
import AppDialog from "@/components/molecules/appDialog";

export default {
  name: "ResourceListView",
  components: {AppDialog, tdCell, AutoForm, appTableResource, appBasicLayout},
  data: () => ({
    items: [],
    modifiers: {},
    create_dialog: false,
    form_fields: [],
    isCreating: true,
  }),
  methods: {
    createDialog() {
      this.create_dialog = true;
    },
    create(obj) {
      this.isCreating = true;
      secure_table.create({...obj, method: 'GET', isActive: true}).then(() => {
        this.create_dialog = false;
        this.fetchData({})
      }).finally(() => {
        this.isCreating = false;
      })
    },
    fetchData({...filters}) {
      secure_table.get(
          {
            values_list: ['table_name', 'driver'],
            distinct: true,
          }
      ).then(({items, modifiers}) => {
        let {get_modifiers} = modifiers;
        this.items = items;
        this.modifiers = get_modifiers;
      });
      api.table_list.get().then(({table_names}) => {
        this.form_fields = [
          new FormFieldItem({
                type: SELECT_FIELD,
                define_field: {
                  items: table_names,
                },
                text: 'table_name'
              }
          ), new FormFieldItem({
                type: SELECT_FIELD,
                define_field: {
                  items: ['DB', 'fileSystem'],
                },
                text: 'driver'
              }
          )]
      })


    }
  },
  beforeMount() {
    this.fetchData({})
  }
}
</script>

<style scoped>

</style>