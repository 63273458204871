import { render, staticRenderFns } from "./appBazaarInteraction.vue?vue&type=template&id=5433f7e7&scoped=true&"
import script from "./appBazaarInteraction.vue?vue&type=script&lang=js&"
export * from "./appBazaarInteraction.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5433f7e7",
  null
  
)

export default component.exports