import {ConfigDefinition, Table} from "@/api/Table";
import {order_by} from "@/api/helper";

/*[ procedures, operations ]*/

export const procedures = new Table(
    '/settings',
    'procedures'
);

export const operations = new Table(
    '/settings',
    'operations',
    new ConfigDefinition(order_by('procedure_id', 'order'))
);
