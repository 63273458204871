var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-basic-layout',{scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('h2',[_vm._v("Agent Facture")])]},proxy:true}])},[(_vm.is_loading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('v-container',{staticClass:"flex-grow-1",attrs:{"fluid":""}},[_c('v-expansion-panels',{model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('v-expansion-panel',{staticClass:"my-1"},[_c('v-expansion-panel-header',{attrs:{"color":"green"}},[_c('h3',{staticClass:"white--text text-uppercase"},[_vm._v(" Payed ")])]),_c('v-expansion-panel-content',{staticClass:"pa-0"},[_c('app-data-table',{ref:"agent_payed_facture_collection",attrs:{"item-key":"id","allow-download":true,"collection":_vm.agent_payed_collection,"hide-header":false,"height":"400"}})],1)],1),_c('v-expansion-panel',{staticClass:"my-1"},[_c('v-expansion-panel-header',{attrs:{"color":"orange"}},[_c('h3',{staticClass:"white--text text-uppercase"},[_vm._v(" collaborations services ")])]),_c('v-expansion-panel-content',{staticClass:"pa-0"},[_c('app-data-table',_vm._g({ref:"collaborator_collection",attrs:{"item-key":"id","hide-header":false,"height":"400","allow-download":true,"collection":_vm.collaborator_collection,"group-by":"agency","group-resume":(items)=>_vm.monetize(items.map(i=>i.value).reduce((a,b)=>a+b,0))}},{
                ...(_vm.isSuperUser? {
                  actionOnGroup:(items)=>_vm.agent_pay(_vm.collaborator_collection,items)
                }:{})
              }))],1)],1),_c('v-expansion-panel',{staticClass:"my-1"},[_c('v-expansion-panel-header',{attrs:{"color":"blue"}},[_c('h3',{staticClass:"white--text text-uppercase"},[_vm._v(" Agents services ")])]),_c('v-expansion-panel-content',{staticClass:"pa-0"},[_c('app-data-table',_vm._g({ref:"agent_pay_collection",attrs:{"item-key":"id","hide-header":false,"height":"400","allow-download":true,"collection":_vm.agent_pay_collection,"group-by":"user_id.username","loading":_vm.is_loading,"group-resume":(items)=>_vm.monetize(items.map(i=>i.value).reduce((a,b)=>a+b,0))}},{
                ...(_vm.isSuperUser? {
                  actionOnGroup:(items)=>_vm.agent_pay(_vm.agent_pay_collection,items)
                }:{})
              }))],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }