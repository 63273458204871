<template>
  <component
    :is="type"
    v-bind="modifiers"
    v-on="$listeners"
    v-model="innerValue"
    :error-messages="errorMessages"
    :autoField="{types}"
    :style="{display:__display}"
  >
  </component>
</template>

<script>
import textField from "./FormFields/textField";
import jsonField from "./FormFields/jsonField";
import selectField from "./FormFields/selectField";
import radioField from "./FormFields/radioField.vue";
import checkboxField from "./FormFields/checkboxField.vue";
import apiSelectField from "@/plugins/AutoForms/FormFields/apiSelectField";
import {ValidationProvider} from "vee-validate";
import {keyFormData} from "@/plugins/AutoForms/autoFieldMixin";
import passwordField from "@/plugins/AutoForms/FormFields/passwordField";
import timestampField from "@/plugins/AutoForms/FormFields/timestampField";
import timeField from "@/plugins/AutoForms/FormFields/timeField";
import dateField from "@/plugins/AutoForms/FormFields/dateField";
import listField from "@/plugins/AutoForms/FormFields/listField";
import textareaField from "@/plugins/AutoForms/FormFields/textareaField.vue";


const components = {
  textField,
  jsonField,
  selectField,
  radioField,
  checkboxField,
  passwordField,
  apiSelectField,
  timestampField,
  timeField,
  dateField,
  listField,
  textareaField
}

export {components};
export default {
  name: "AutoField",
  components: {
    ...components, ValidationProvider
  },
  data: () => ({
    modifiers: {}
  }),
  mixins: [keyFormData],
  methods: {
    loadModifiers() {
      let {keyData, autoField, formData, rules} = this.$props;
      let {extend} = this.$attrs;
      // console.log("extend->>", {...extend});
      const required = (rules && rules.required) || this.required;
      this.modifiers = {
        ...this.$attrs,
        keyData,
        autoField,
        formData,
        rules,
        disabled: this.__disabled,
        label: this.required ? `* ${this.label}` : this.label,
        required,
        clearable: !required
      }
    }
  },
  beforeMount() {
    this.loadModifiers()
  },
  props: {
    hidden: {
      type: Boolean,
      default: false,
    },
    errorMessages: {},
    type: {
      type: String,
      required: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.keys(components).includes(value)
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {type: Boolean},
    display: {type: [Boolean, Function, Array]},
  },
  computed: {
    types() {
      return Object.keys(components)
    },
    __disabled() {
      return this.disabled
      // return (
      //     this.disabled &&
      //     this.disabled.length &&
      //     this.disabled.some(fn => fn(this.value))
      // ) || this.disabled
    },
    __display() {
      const isHidden = this.hidden || this.disabled &&
        this.disabled &&
        this.disabled.length &&
        this.disabled.some(fn => fn(this.value));
      return isHidden ? 'none' : ''
    }
  }
}
</script>

<style scoped>

</style>