import {FormFieldItem} from "@/plugins/AutoForms/tools";
import {blobDomLink} from "@/plugins/downloadCSV/tools";
import {SKIP_KEYS} from "@/api/helper";

function deep_clear(item) {
    if (Array.isArray(item)) return []

    switch (typeof item) {
        case "object":
            if (item == null) {
                return null
            }
            return Object.keys(
                item
            ).reduce(
                (carr, key) => (
                    {...carr, [key]: deep_clear(item[key])}
                ), {}
            )
        default:
            return "";
    }

}

function getFormData(formFields) {
    return formFields.filter(
        x => !x.skip_on_create
    ).reduce((carr, form_field) => ({
        ...carr, ...form_field.data()
    }), {});
}


class Collection {
    get limit() {
        return this.config?.limit ?? this.table.limit;
    }

    get headers() {
        const exclude = [...this.table.extend.exclude, ...this.extend?.exclude ?? []]
        return this._headers.filter(
            ({value, ..._}) => !exclude.includes(value)
        ).map(h => ({
            ...h,
            sortable: false // cols are not sorteable using vuetify at less for now
        }))
    }

    constructor(table, config, extend, filters, between, autoload) {
        this.table = table;
        this._headers = [];
        this.items = [];
        this.formFields = [];
        this.formData = {};
        this.config = config ?? {};
        this.extend = extend ?? {};
        this.filters = {};
        this.filters_keys = [];
        this.loading = false;
        if (filters)
            this.promise = this.filter(
                filters, between, autoload ?? false
            )
        else
            this.promise = Promise.all([])

    }

    getFormFields() {
        let order = this.table.order_on_create;
        let ordered = order.map(
            col_name => this._headers.find(col => col.value === col_name)
        ).filter(col => col);

        let others = this._headers.filter(
            col => !order.includes(col.value)
        )
        return [...ordered, ...others].map(config => {
            const {value, type, skip_on_create, define_field, foreign_key, nullable} = config;
            // console.log(
            //     "value:", value,
            //     "skip_on_create:", skip_on_create,
            //     "this.table.skip_on_create(value, skip_on_create):", this.table.skip_on_create(value, skip_on_create)
            // );
            return new FormFieldItem({
                type,
                define_field,
                text: value,
                skip_on_create: this.table.skip_on_create(value, skip_on_create),
                foreign_key,
                nullable,
                extend: {...this.table.extend_on_create(value)}
            })
        })
    }

    clearData() {
        this.formData = deep_clear(this.formData);
    }

    clear_filters(filters, wait = false) {
        if (filters.length === 0) {
            this.filters = {};
        } else {
            for (let filter of filters) {
                this.filters[filter] = null;
                delete this.filters[filter]
            }
        }
        // console.log("filters", this.filters)
        if (wait) {
            // not fetchData
        } else {
            return this.fetchData();
        }
    }

    filter(filters, between, autoload, not) {
        autoload = autoload ?? true
        this.filters_keys = Object.keys(
            [
                ...this.filters_keys,
                ...Object.keys(filters)
            ].filter(
                key => {
                    if (key in this.config) {
                        this.config[key] = filters[key]
                        return false
                    }
                    return !SKIP_KEYS.has(key);
                }
            ).reduce(
                (carr, curt) => ({
                    [curt]: null,
                    ...carr
                }), {}
            )
        );
        between = between ?? [];
        filters = Object.keys(
            filters
        ).filter(
            key => !(key in this.config)
        ).reduce(
            (carr, key) => {
                if (between.includes(key)) {
                    if (filters[key]?.length == 2) {
                        // ✅ all correct
                    } else {
                        filters[key] = null;
                    }
                }
                if (filters[key] == null) {
                    /* si es null entonces debo quitarlo */
                    let index = this.filters_keys.findIndex(
                        filter_key => filter_key == key
                    );
                    if (index >= 0) {
                        /* aquí lo encontre lo voy a quitar */
                        this.filters_keys.splice(index, 1);
                    }
                    /* verifico que lo tengo y lo borro */
                    this.filters[key] = null;
                    delete this.filters[key];
                    if (between.includes(key)) {
                        this.filters[`${key}__lte`] = null;
                        this.filters[`${key}__gte`] = null;
                        delete this.filters[`${key}__lte`];
                        delete this.filters[`${key}__gte`];
                    }
                }

                if (filters[key] != null) {
                    carr[key] = filters[key]
                }
                return carr
            }, {}
        );
        not = not ?? [];
        for (const key of Object.keys(filters)) {
            let value = filters[key];
            if (value == null)
                continue;

            if (between.includes(key)) {
                let [d1, d2] = value;
                this.filters = {
                    ...this.filters,
                    [`${key}__gte`]: d1,
                    [`${key}__lte`]: d2
                };
            } else {
                this.filters = {
                    ...this.filters,
                    [key]: not.includes(key) ? !value : value
                };
            }
        }
        if (autoload)
            return this.fetchData({}, between);
        return Promise.all([])
    }

    async fetchData(filters, between = []) {
        while (this.loading) {
            await this.promise.finally(() => {
                this.loading = false;
            })
        }
        this.loading = true;
        filters = this.get_filters(filters, between);
        this.promise = this.table.get(
            filters
        )
        return this.promise.then((res) => {
            const {items, headers, count} = res;
            this.items = items;
            this.count = count;
            this._headers = this.table.get_headers(
                headers
            ).map(
                h => {
                    let {value} = h;
                    if (value in this.extend) {
                        h.extend = {
                            ...(h.extend ?? {}),
                            ...this.extend[value]
                        };
                    }
                    return h
                }
            );
            this.formFields = this.getFormFields();
            this.formData = getFormData(this.formFields);
            return res;
        }).finally(() => this.loading = false);
    }

    async downloadFile(filters, between = []) {
        filters = filters ?? {};
        let {format, limit} = filters
        format = format ?? "xlsx";
        limit = limit == null ? -1 : limit;
        filters = this.get_filters({
            ...filters,
            format,
            limit
        }, between);
        this.loading = true;
        this.promise = this.table.get(
            filters,
            true
        ).finally(() => this.loading = false)
        let blob = await this.promise;
        blobDomLink(blob, this.table.table_name, format);
        return this;
    }

    get_filters(filters, between) {
        filters = filters ?? {}
        between = between ?? []

        let _filters = {
            ...filters
        };

        for (let key of between) {
            let f = _filters[key]
            _filters[key] = null
            delete _filters[key];

            if (f == null || f.length == 0) {
                f = [null, null]
            } else {
                // order
            }

            _filters = {
                ..._filters,
                [`${key}__lte`]: f[0],
                [`${key}__gte`]: f[1]
            }
        }

        let before_send = {
            ...this.filters,
            ...filters,
            ...this.config
        }
        return Object.keys(before_send).reduce(
            (carr, curt) => ({
                ...carr,
                ...(before_send[curt] == null ? {} : {
                    [curt]: before_send[curt]
                })
            }), {}
        )
    }

    create(obj, autoUpdate) {
        this.loading = true;
        let self = this;
        return this.table.create(obj).then(res => {
            this.formData = getFormData(this.formFields);
            if (autoUpdate)
                self.fetchData().catch(
                    console.log
                );
            return res
        }).finally(() => {
            this.loading = false;
        });
    }

    update(filter, obj, autoUpdate) {
        this.loading = true;
        let self = this;
        return this.table.update(filter, obj).then(res => {
            if (autoUpdate)
                self.fetchData().catch(
                    console.log
                );
            return res
        }).finally(() => {
            this.loading = false;
        });
    }

    remove(filter, autoUpdate) {
        this.loading = true;
        let self = this;
        return this.table.remove(filter).then(res => {
            if (autoUpdate)
                self.fetchData().catch(
                    console.log
                );
            return res
        }).finally(() => {
            this.loading = false;
        });
    }
}


export {
    Collection
};
