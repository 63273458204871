<template>
  <app-dialog
      :title="label"
      ref="dialog"
      v-model="modal"
      persistent
      width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          dense
          filled
          :value="humanValue"
          @click:clear="setInnerValue('T')"
          prepend-icon="mdi-calendar"
          :clearable="!required"
          readonly
          v-bind="attrs"
          :disabled="disabled"
          :label="label"
          v-on="on"
      ></v-text-field>
    </template>
    <v-sheet>
      <v-container class="d-flex flex-column">
        <v-date-picker
            :full-width="$vuetify.breakpoint.smAndUp"
            :landscape="$vuetify.breakpoint.smAndUp"
            scrollable
            v-model="date"
        />
        <v-time-picker
            format="24hr"
            class="my-2"
            :full-width="$vuetify.breakpoint.smAndUp"
            :landscape="$vuetify.breakpoint.smAndUp"
            scrollable
            v-model="time"
        />
        <v-row>
          <v-col cols="12" class="d-flex flex-row justify-space-between">
            <v-btn
                text
                color="primary"
                @click="modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="setNow"
            >
              setNow
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="save"
            >
              OK
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </app-dialog>
</template>

<script>
import {keyFormData} from "@/plugins/AutoForms/autoFieldMixin";
import moment from 'moment'
import AppDialog from "@/components/molecules/appDialog";

export default {
  name: "timestampField",
  components: {AppDialog},
  data: () => ({
    modal: false,
    date: null,
    time: null
  }),
  beforeMount() {
    this.setInnerValue(this.value)
  },
  computed: {
    innerValue() {
      if (this.date && this.time)
        return [this.date, this.time].join('T')
      return null
    },
    humanValue() {
      if (this.value)
        return moment.utc(this.value).format('LLL')
      return '-'
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
    },
    label: String,
    keyData: String,
    autoField: Object, // este es el tipo que tiene dentro las cosas de autoField
    value: [Object, String, Boolean, Array, Number],
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Object | String,
    },
  },
  watch: {
    value(newVal) {
      this.setInnerValue(newVal)
    }
  },
  methods: {
    save() {
      this.$emit("input", this.innerValue);
      this.$emit("change", this.innerValue);
      this.modal = false;
    },
    setInnerValue(value) {
      if (value) {
        let [date, time] = value.split("T");
        this.date = date;
        this.time = time
      } else {
        this.date = null;
        this.time = null;
      }
      this.save();
    },
    setNow() {
      // "2022-10-15T15:00:00"
      this.setInnerValue(moment().format('YYYY-MM-DDTHH:mm:ss'))
    }
  }
}
</script>

<style scoped>

</style>