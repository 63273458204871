import { _axios, get_websocketUrl } from "@/plugins/axios";
import {
  clean_auth_headers,
  load_auth_headers,
  set_auth_headers,
} from "@/plugins/axios/tools";
import { driver_facture_resume, user_account } from "@/api/data/user";
import { Centrifuge } from "centrifuge";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { request } from "@/api/data/bazaar";
import { message_type } from "@/const";

const TIMEOUTS = {};
const DING_DONG = "DING_DONG";
const WALLET = "WALLET";

class Session {
  get isSuperUser() {
    return !!this.me?.is_superuser;
  }

  get user_id() {
    return this.me?.id;
  }

  get isAgent() {
    return !!this.me?.agent?.isActive;
  }

  get isDriver() {
    return !!this.me?.driver?.isActive;
  }

  get username() {
    // no se puede tocar el sistema de notificaciones depende de esto
    return this.me?.username;
  }

  constructor() {
    this.me = null;
    this.token = null;
    this.centrifugo = null;
    this.subscriptions = {};
    this.subscriptions_keys = {};
    this.status = "disconnect";
    this.internal_subscriptiosns = [];
    this.notifications = [];
    this.alerts = [];
    this.alerts_procesadas = [];
    this.dingdong = false;
    this.wallet = 0;
    this.wallet_update_at = null;
  }

  async login(username, password) {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);

    let { access_token, token_type } = await _axios
      .post("/token", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data);
    set_auth_headers(token_type, access_token);
    return this.try_login();
  }

  async signup(data) {
    return await _axios.post("/signup", data).then(res => res.data);
  }

  async updateCurrentUser(obj) {
    await user_account.update(
      {
        username: this.username,
      },
      obj
    );
    await this.get_me();
    this.reconnect();
  }

  reconnect(data) {
    this.disconnect();

    this.centrifugo = new Centrifuge(get_websocketUrl(), { data });
    this.subscriptions = {};
    this.subscriptions_keys = {};

    const self = this;

    this.centrifugo
      .on("connecting", function onConnecting(ctx) {
        self.status = "connecting";
      })
      .on("connected", function onConnected(ctx) {
        self.status = "connected";
      })
      .on("disconnected", function onDisconnected(ctx) {
        self.status = "disconnect";
      })
      .connect();
    this.request_subscriptions();
  }

  disconnect() {
    this.centrifugo?.disconnect();
  }

  unsubscribe(subscription_id) {
    if (subscription_id in this.subscriptions_keys) {
      const [subscription, internalCall] =
        this.subscriptions_keys[subscription_id];
      subscription.off("publication", internalCall);
      delete this.subscriptions_keys[subscription_id];
    }
  }

  request_subscriptions() {
    let self = this;

    // le llegan a los choferes
    this.internal_subscriptiosns.push(
      session.subscribe(
        `bazaar_request_driver#${session.username}`,
        (request) => {
          this.onRequestAlert({
            channel: "bazaar_request_driver",
            data: request,
          });
        }
      ),
      session.subscribe(
        `bazaar_request_driver_accept#${session.username}`,
        (request) => {
          this.onRequestMessage({
            channel: "bazaar_request_driver_accept",
            data: request,
          });
        }
      ),
      session.subscribe(
        `app_service_cancel_driver#${session.username}`,
        (request) => {
          this.onServicesMessage({
            channel: "app_service_cancel_driver",
            data: request,
          });
        }
      ),
      session.subscribe(
        `bazaar_request_driver_reject#${session.username}`, // este es el que le llega al chofer cundo rechaza
        (request) => {
          this.onRequestMessage({
            channel: "bazaar_request_driver_reject",
            data: request,
          });
        }
      )
    );

    // le llegan a los sellers
    this.internal_subscriptiosns.push(
      // request subscriptions
      session.subscribe(
        `bazaar_request_seller#${session.username}`, // este es el que le llega al seller cuando lanza un request
        (request) => {
          this.onRequestMessage({
            channel: "bazaar_request_seller",
            data: request,
          });
        }
      ),
      session.subscribe(
        `app_service_cancel_client#${session.username}`, // este es el que le llega al seller cuando chofer cancela un servicio
        (request) => {
          this.onServicesMessage({
            channel: "app_service_cancel_client",
            data: request,
            type: "driver_service_cancel",
          });
        }
      ),
      session.subscribe(
        `bazaar_request_seller_accept#${session.username}`,
        (request) => {
          this.onRequestAlert({
            channel: "bazaar_request_seller_accept",
            data: request,
          });
          this.onRequestMessage({
            channel: "bazaar_request_seller_reject",
            data: request,
          });
        }
      ),
      session.subscribe(
        `bazaar_request_seller_reject#${session.username}`,
        (request) => {
          this.onRequestAlert({
            channel: "bazaar_request_seller_reject",
            data: request,
          });
          this.onRequestMessage({
            channel: "bazaar_request_seller_reject",
            data: request,
          });
        }
      )
    );
  }

  onServicesMessage({ channel, data }) {
    console.log(channel, data);
  }

  onRequestMessage({ channel, data }) {
    console.log(channel, channel);
    this.notifications.push({
      is_done: false,
      data,
      type: message_type(data),
    });
    this.dingdong = true;
    setTimeout(() => {
      this.dingdong = false;
    }, 1500);
  }

  onRequestAlert({ channel, data }) {
    console.log(channel, data);
    this.alerts.push({
      channel,
      data,
      type: message_type(data),
    });
  }

  subscribe(key, callback) {
    const subscription_id = uuidv4();
    const internalCall = ({ channel, data }) => callback(data, channel);

    if (!(key in this.subscriptions)) {
      this.subscriptions[key] = this.centrifugo.newSubscription(key);
    }
    this.subscriptions_keys[subscription_id] = [
      this.subscriptions[key],
      internalCall,
    ];

    this.subscriptions[key].on("publication", internalCall);
    return subscription_id;
  }

  get_me() {
    return _axios.get("/me").then((res) => {
      this.me = res.data;
      this.get_alert();
      if (this.isDriver) {
        this.update_wallet();
        this.run_wallet_auto_update();
      }
    });
  }

  update_wallet() {
    return driver_facture_resume
      .get({
        user_id: this.user_id,
      })
      .then(({ items }) => {
        if (items.length == 1) {
          let item = items[0];
          let deposit = item["deposit.value"] ?? 0;
          let calc_debt = item["calc_debt.value"] ?? 0;
          let calc_service_unclose = item["calc_service_unclose.value"] ?? 0;
          this.wallet = deposit - (calc_debt + calc_service_unclose);
          this.wallet_update_at = moment().format("YYYY-MM-DDTHH:mm:ss");
        }
      });
  }

  get_alert() {
    return request.get().then(({ items }) => {
      let { alerts, notifications } = items.reduce(
        ({ alerts, notifications }, item) => {
          if (item.bazaar.seller == this.user_id) {
            notifications.push(item);
          } else if (item.rejected_at == null) {
            alerts.push(item);
          } else {
            notifications.push(item);
          }
          return {
            alerts,
            notifications,
          };
        },
        { alerts: [], notifications: [] }
      );
      this.alerts = alerts.map((data) => ({
        data,
        type: message_type(data),
      }));
      this.notifications = notifications.map((data) => ({
        data,
        type: message_type(data),
      }));
    });
  }

  run_wallet_auto_update() {
    if (TIMEOUTS[WALLET]) {
      return;
    }
    const self = this;
    const to_call = self.update_wallet.bind(self);
    TIMEOUTS[WALLET] = setTimeout(() => {
      to_call();
      TIMEOUTS[WALLET] = null;
      self.run_wallet_auto_update();
    }, 60000);
  }

  async try_login() {
    let auth_info = load_auth_headers();
    if (auth_info) {
      let { token } = auth_info;
      if (token) {
        this.token = token;
        return await this.get_me()
          .then(() => this.reconnect())
          .catch((...err) => {
            this.token = null;
            return Promise.reject(...err);
          });
      }
    }
    this.token = null;
    return Promise.reject();
  }

  logout() {
    clean_auth_headers();
    this.me = null;
  }

  process_alert(alert_attended_id) {
    let index = this.alerts.findIndex(
      ({ data }) => data.id === alert_attended_id
    );
    if (index >= 0) {
      this.alerts_procesadas.push(this.alerts.splice(index, 1));
    }
  }
}

export const session = new Session();
