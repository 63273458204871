<template>
  <div>
    <v-menu
        offset-y
        rounded="b-xl"
    >
      <template v-slot:activator="{on}">
        <v-btn
            v-on="on"
            :elevation="0"
            color="primary"
        >
          <span class="mr-2">
            {{ session.username }}
          </span>
          <v-icon color="amber" v-if="session.isSuperUser"> mdi-chess-queen</v-icon>
          <v-icon v-else-if="session.isDriver"> mdi-taxi</v-icon>
          <v-icon v-else> mdi-account
          </v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
            :disabled="is_loading"
            v-if="session.isDriver"
            @click.stop="update_wallet"
        >
          <v-list-item-icon>
            <v-progress-circular v-if="is_loading" indeterminate></v-progress-circular>
            <v-icon :color="textWalletColor" v-else>mdi-wallet</v-icon>
          </v-list-item-icon>
          <v-list-item-title :class="`${textWalletColor}--text`" class="d-flex flex-column">
            <span>{{ session.wallet|monetize }}</span>
            <span>{{ session.wallet_update_at|fromNow }}</span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="user_info_dialog=true">
          <v-list-item-icon>
            <v-icon>mdi-account-settings</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Configuración</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Desconectar</v-list-item-title>
        </v-list-item>
      </v-list>

    </v-menu>
    <template v-if="session.me">
      <app-dialog
          v-model="user_info_dialog"
          title="User Information"
          width="290"
      >
        <auto-form
            :loading-action="is_loading"
            :form-fields="formFields"
            :form-data="session.me"
            :exclude="['id','username']"
            @submit="updateCurrentUser"
        >
        </auto-form>
      </app-dialog>
    </template>
  </div>
</template>
<script>
import {FormFieldItem, PASSWORD_FIELD, TEXT_FIELD} from "@/plugins/AutoForms/tools";
import AutoForm from "@/plugins/AutoForms/AutoForm.vue";
import AppDialog from "@/components/molecules/appDialog.vue";
import {session} from "@/api/auth";
import {dateFromNow} from "@/plugins/filters";
import moment from "moment/moment";

export default {
    name: 'app-user-menu',
    components: {AppDialog, AutoForm},
    computed: {
        textWalletColor() {
            return session.wallet > 1000 ? 'green' : (session.wallet < 0 ? 'red' : 'orange')
        }
    },
    filters: {
        fromNow(_moment) {
            return moment(_moment).fromNow();
        }
    },
    methods: {
        update_wallet() {
            this.loadingOn(
                session.update_wallet()
            );
        },
        dateFromNow,
        logout() {
            session.logout();
            this.$router.push({name: 'login'});
        },
        updateCurrentUser(formData) {
            let {
                password2,// drop the password2 'cause only use in re-type
                password,
                agent,
                driver,
                inspire_to,
                ...obj
            } = formData;

            if (password) {
                obj = {
                    password,
                    ...obj
                }
            }
            this.loadingOn(
                session.updateCurrentUser(
                    obj
                ).then(() =>
                    this.user_info_dialog = false
                ).catch(
                    console.log
                )
            )
        }
    },
    data: () => ({
        session,
        user_info_dialog: false,
        formFields: [
            new FormFieldItem({
                type: TEXT_FIELD,
                nullable: true,
                rules: {
                    email: true,
                },
                text: 'email',
            }),
            new FormFieldItem({
                type: TEXT_FIELD,
                nullable: true,
                text: 'phone_number',
            }),
            new FormFieldItem({
                nullable: true,
                type: PASSWORD_FIELD,
                text: 'password',
            }),
            new FormFieldItem({
                type: PASSWORD_FIELD,
                nullable: true,
                rules: {
                    equalTo: {target: '@password'}
                },
                keyData: 'password2',
                text: 'type again',
            })
        ]
    }),
}
</script>
<style lang="scss">

</style>