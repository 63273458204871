export const keyFormData = {
  data: () => ({
    innerValue: ""
  }),
  computed: {
    dtype() {
      if (this.rules && (
        this.rules.numeric
        || this.rules.decimal
        || this.rules.digits
        || this.rules.double
      )) {
        return 'number'
      }
      return 'text';
    },
  },
  props: {
    formData: {
      type: Object,
    },
    keyData: String,
    autoField: Object, // este es el tipo que tiene dentro las cosas de autoField
    value: [Object, String, Boolean, Array, Number],
    rules: {
      type: Object | String,
      default: () => ({})
    },
  },
  beforeMount() {
    this.innerValue = this.value ?? this.$attrs.default_value();
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      if (newVal == null) {
      } else {
        newVal = newVal.trim == null ? newVal : newVal.trim();
        if (newVal.constructor == Array) {
          //     nothing
        } else if (this.dtype == "number" && newVal == newVal && !Number.isNaN(newVal)) {
          let intValue = Number.parseInt(newVal)
          let floatValue = Number.parseFloat(newVal)
          if (floatValue == intValue) {
            newVal = intValue
          } else {
            newVal = floatValue;
          }
        }
      }

      this.$emit("input", newVal);
      this.$emit("change", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
}