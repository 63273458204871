<template>
  <v-app id="app">
    <v-main class="d-flex flex-row">
      <div
        class="fill-height d-flex flex-column justify-space-around align-center"
      >
        <v-card
          :elevation="2"
          class="mx-2 d-flex py-2 "
          :class="[
            $vuetify.breakpoint.height >= 500 ? 'flex-column' : 'flex-row pr-0',
          ]"
        >
          <v-card-title class="d-flex flex-column pb-0">
            <div class="align-self-center my-auto">
              <img
                v-if="$vuetify.breakpoint.mdAndUp"
                src="../../assets/logo.png"
              />
              <img
                v-else-if="$vuetify.breakpoint.smAndDown"
                src="../../assets/logo-alt.png"
              />
            </div>
          </v-card-title>
          <v-card-text>
            <auto-form
              :loading-action="isLogin"
              :formFields="formFields"
              @submit="login"
            >
              <v-card-title class="px-0"><h2>Credenciales</h2></v-card-title>
              <template v-slot:prepend-action="data">
                <v-btn
                  :to="{name:'register'}"
                  text
                  color="primary"
                >
                  Registrarse
                </v-btn>
              </template>
            </auto-form>
          </v-card-text>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import AutoForm from "@/plugins/AutoForms/AutoForm";
import {
  FormFieldItem,
  PASSWORD_FIELD,
  TEXT_FIELD,
} from "@/plugins/AutoForms/tools";
import { session } from "@/api/auth";
import { apiAlert } from "@/plugins/alerts";

export default {
  name: "loginView",
  components: { AutoForm },
  data: () => ({
    isLogin: false,
    formFields: [
      new FormFieldItem({
        type: TEXT_FIELD,
        nullable: false,
        keyData: "username",
        text: "nombre de usuario",
      }),
      new FormFieldItem({
        type: PASSWORD_FIELD,
        nullable: false,
        keyData: "password",
        text: "contraseña",
      }),
    ],
  }),
  methods: {
    login({ username, password }) {
      this.isLogin = true;
      session
        .login(username, password)
        .then((res) => {
          this.$router.push("/");
        })
        .catch(apiAlert.promiseError)
        .finally(() => {
          this.isLogin = false;
        });
    },
  },
};
</script>

<style scoped></style>
