<template>
  <v-expansion-panels v-if="collapsable" class="pt-1 px-2">
    <v-expansion-panel>
      <v-expansion-panel-header class="pa-0 pr-3">
        <app-service-resume-header :service="service"/>
      </v-expansion-panel-header>
      <v-container v-if="!service.is_done && !service.canceled_at">
        <v-row class="d-flex flex-row">
          <v-col cols="6">
            <app-cancel-service
                @submit="$emit('removeService',service)"
                small
                :service="service"
            ></app-cancel-service>
          </v-col>
          <v-col cols="6" class="d-flex flex-row justify-end">
            <app-turn-done-service
                @submit="$emit('removeService',service)"
                small
                :service="service"
            ></app-turn-done-service>
          </v-col>
        </v-row>
      </v-container>
      <v-expansion-panel-content>
        <app-service-resume-content :service="service"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-hover v-else v-slot="{ hover }">
    <v-card
        :elevation="hover ? 3 : 1"
    >
      <app-service-resume-header :service="service"/>
      <v-card-text>
        <app-service-resume-content :service="service"/>
      </v-card-text>
    </v-card>
  </v-hover>
</template>
<script>
import {session} from "@/api/auth";
import AppServiceResumeHeader from "@/views/home/AppServiceResumeHeader.vue";
import AppServiceResumeContent from "@/views/home/AppServiceResumeContent.vue";
import appCancelService from "@/components/molecules/appCancelService.vue";
import AppTurnDoneService from "@/components/molecules/appTurnDoneService.vue";

export default {
    name: 'ServiceResume',
    components: {
        AppTurnDoneService,
        appCancelService,
        AppServiceResumeContent,
        AppServiceResumeHeader,
    },
    computed: {
        can_edit() {
            return session.me.is_superuser || session.me.agent?.isActive;
        }
    },
    props: {
        collapsable: {
            type: Boolean,
            default: () => false,
        },
        service: {},
    }
}
</script>

<style>

</style>
