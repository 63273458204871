<template>
  <v-dialog
      :persistent="persistent"
      :fullscreen="!mini && !$vuetify.breakpoint.lgAndUp"
      :value="value"
      :width="width"
      scrollable
      v-bind="$attrs"
      v-on="$listeners"
  >
    <template v-slot:activator="all">
      <slot name="activator" v-bind="all">

      </slot>
    </template>
    <v-card
        class="d-flex flex-column"
    >
      <div v-if="!mini" class="grow-shrink-0">
        <v-toolbar
            class="text-uppercase"
            :color="color"
            dark
        >
          <v-btn
              dark
              icon
              @click="$emit('input',false)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <slot name="title">
              {{ title }}
            </slot>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-icon large :color="colorIcon" v-if="icon" class="mr-3">
              {{ icon }}
            </v-icon>
          </v-toolbar-items>
        </v-toolbar>
      </div>
      <v-card-title
          v-if="mini"
          class="text-uppercase"
      >
        <slot name="title">
          {{ title }}
        </slot>
      </v-card-title>
      <v-card-text
          :class="[$vuetify.breakpoint.mdAndDown? 'px-2':' px-3']"
          class="py-2 flex-grow-1"
      >
        <slot v-if="value">
        </slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
    name: "appDialog",
    props: {
        color: {
            default: () => 'primary'
        },
        colorIcon: {
            default: () => ''
        },
        icon: {},
        value: {},
        width: {
            default: () => 500
        },
        title: {
            type: String,
            default: () => "Create a new Record"
        },
        mini: {
            type: Boolean,
            default: () => false
        },
        persistent: {
            type: Boolean,
            default: () => false
        }
    },
}
</script>

<style scoped>

</style>