<template>
  <ValidationObserver
    style="display: block"
    tag="div"
    ref="observer"
    v-slot="{ valid, invalid }"
  >
    <form
      @submit.prevent="submit"
      class="d-flex flex-column pa-0 ma-0"
      style="position: relative"
    >
      <div
        class="flex-grow-1 d-flex"
        :class="[inline ? 'flex-row' : 'flex-column']"
      >
        <slot v-bind="innerValue"></slot>
        <span v-if="invalid" class="red--text"> * Campos requeridos </span>
        <v-row class="flex-grow-1 overflow-auto ma-0 pa-1">
          <template v-for="field in _formFields">
            <app-basic-row
              class="px-1"
              v-if="field.dependes_on(innerValue)"
              :key="`${field.keyData}||${
                readOnly ||
                readOnlyArray.includes(field.keyData) ||
                field.disabled ||
                !$listeners.submit
              }`"
              :field="field"
              :disabled="isDisabledForm || readOnly"
              v-model="innerValue"
            >
              <ValidationProvider
                class="flex-grow-1"
                :vid="field.keyData"
                :rules="field.rules"
                v-slot="{ errors }"
              >
                <auto-field
                  class="flex-grow-1"
                  :errorMessages="errors"
                  :disabled="
                    isDisabledForm ||
                    readOnly ||
                    readOnlyArray.includes(field.keyData) ||
                    field.disabled ||
                    !$listeners.submit
                  "
                  v-bind="field"
                  v-model="innerValue[field.keyData]"
                ></auto-field>
                <span>{{ field.description }}</span>
              </ValidationProvider>
            </app-basic-row>
          </template>
        </v-row>
        <v-row>
          <slot v-bind="innerValue" name="append"></slot>
        </v-row>
        <div
          v-if="!isDisabledForm"
          class="my-auto grow-shrink-0 d-flex flex-row pt-3"
        >
          <slot name="prepend-action" v-bind:item="innerValue"></slot>
          <v-spacer />
          <v-btn v-if="$listeners.clear" text @click="clear" class="mr-4">
            clear
          </v-btn>
          <template v-if="$listeners.submit && onValidateFunction">
            <app-protected-btn
              v-if="protectedSubmit"
              :color="_actionColor"
              @submit="submit"
              :loading="loadingAction"
              :disabled="loadingAction || invalid || !valid || isDisabledForm"
            >
              {{ _actionName }}
            </app-protected-btn>
            <v-btn
              v-else
              :color="_actionColor"
              type="submit"
              :loading="loadingAction"
              :disabled="loadingAction || invalid || !valid || isDisabledForm"
            >
              {{ _actionName }}
            </v-btn>
          </template>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import autoField from "./AutoField";
import { session } from "@/api/auth";
import AppBasicRow from "@/plugins/AutoForms/app-basic-row.vue";

export default {
  name: "autoForm",
  components: {
    AppBasicRow,
    AppProtectedBtn: () => import("@/components/organisms/appProtectedBtn.vue"),
    autoField,
  },
  filters: {
    pretty: (val, indent = 2) => {
      if (typeof val !== "object") {
        try {
          val = JSON.parse(val);
        } catch (err) {
          console.warn("value is not JSON");
          return val;
        }
        return JSON.stringify(val, null, indent);
      }
      return val;
    },
  },
  props: {
    validateFunction: {
      type: Function,
      default: () => () => true,
    },
    inline: {
      type: Boolean,
      default: () => false,
    },
    protectedSubmit: {
      type: Boolean,
      default: () => false,
    },
    readOnlyArray: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: () => false,
    },
    loadingAction: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    functionDisabled: {
      type: Function,
      default: () => false,
    },
    actionName: { type: Function | String },
    formData: {
      type: Object,
      default: () => ({}),
    },
    formFields: {
      type: Array,
      required: true,
      default: () => [],
    },
    exclude: {
      type: Array,
      default: () => [],
    },
    actionColor: { type: Function || String },
  },
  data: () => ({
    innerValue: {},
  }),
  watch: {
    // Handles external model changes.
    formData(newVal) {
      this.loadInnerValue();
    },
  },
  mounted() {
    if (
      this._formFields
        .map(({ keyData }) => keyData)
        .some((keyData) => this.formData[keyData])
    ) {
      this.$refs.observer.validate();
    }
  },
  beforeMount() {
    this.loadInnerValue();
  },
  computed: {
    onValidateFunction() {
      return !!this.validateFunction(this.innerValue);
    },
    isSuperUser() {
      return session.isSuperUser;
    },

    _actionName() {
      if (typeof this.actionName === "function")
        return this.actionName(this.innerValue);
      if (
        typeof this.actionName === "string" ||
        this.actionName instanceof String
      )
        return this.actionName;
      return "submit";
    },
    _actionColor() {
      if (this.actionColor instanceof Function)
        return this.actionColor(this.innerValue);
      if (this.actionColor instanceof String) return this.actionColor;
      return "primary";
    },

    isDisabledForm() {
      if (this.disabled) return true;
      return !!this.functionDisabled?.(this.innerValue);
    },
    _formFields() {
      if (!!this.formFields) {
        return this.formFields
          .filter(({ skip_on_create }) => !skip_on_create)
          .filter(({ keyData }) => !this.exclude.includes(keyData))
          .map(({ disabled, ..._item }) => ({
            disabled: this.isDisabledForm || disabled,
            ..._item,
          }));
      }
      return [];
    },
  },
  methods: {
    loadInnerValue() {
      this.innerValue = Object.keys(this.formData)
        .filter((key) => !this.exclude.includes(key))
        .reduce(
          (carr, curt) => ({
            ...carr,
            [curt]: this.formData[curt],
          }),
          {}
        );
      for (let { keyData } of this._formFields) {
        this.innerValue[keyData] = this.innerValue[keyData] ?? null;
      }
    },
    update(name, value) {
      this.innerValue[name] = value;
    },
    submit() {
      this.$emit("submit", this.innerValue, this);
      this.$refs.observer.validate();
    },
    clear() {
      this.$emit("clear", {});
      this.$refs.observer.reset();
    },
  },
};
</script>

<style scoped>
.hidden {
  display: none;
}
</style>
