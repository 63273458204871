<template>
  <div class="pb-3 d-flex flex-column flex-grow-1">
    <div class="form-container pt-4 py-3 d-flex flex-column">
      <span class="json-field-header">
        <v-menu v-if="!disabled" offset-y rounded>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              raised
          >
            <v-icon size="14">mdi-pencil</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
            v-if="Array.isArray(innerValue)"
            @click="innerValue = innerValue.length > 0? innerValue[0] : {}"
        >
          to json {}
        </v-list-item>
        <v-list-item v-else @click="innerValue = [innerValue]">
          to array []
        </v-list-item>
      </v-list>
    </v-menu>
        {{ keyData }} {{ Array.isArray(innerValue) ? '[ ]' : '{ }' }}
      </span>
      <div
          v-if="Array.isArray(innerValue)"
          class="pa-3 d-flex flex-column overflow-auto"
      >
        <template v-for="(sub_inner_value,index) in _innerValue">
          <sub-json-field
              v-if="(!!sub_inner_value) && sub_inner_value.constructor === Object"
              v-model="innerValue[index]"

              :inverse-color="!inverseColor"
              :key="`${index}`"
              :text-header="`${keyData} - [${index}]`"
              :disabled="disabled"
              :formFields="formFields"
              :added-keys="addedKeys"
              :append-keys="!disabled && appendKeys"

              @close="innerValue.splice(index,1)"
              @pop_key="(key)=>pop_key_index(key,index)"
              @appendFormFields="appendNewKey"
          >
            <template v-if="!disabled" v-slot:head>
              <v-btn class="flex-grow-1" @click="innerValue.splice(index, 0, {})">
                add before {{ index }}
              </v-btn>
            </template>
            <v-row v-if="!disabled" class="flex-grow-1 overflow-auto ma-0 pa-1">
              <template v-for="field in _formFields">
                <app-basic-row
                    :key="`${index}-${field.keyData}`"
                    :field="field"
                    v-model="innerValue[index]"
                >
                  <ValidationProvider
                      class="flex-grow-1"
                      :vid="field.keyData"
                      :rules="field.rules"
                      v-slot="{ errors }"
                  >
                    <auto-field
                        class="flex-grow-1"
                        :errorMessages="errors"
                        v-bind="field"
                        v-model="innerValue[index][field.keyData]"
                    ></auto-field>
                  </ValidationProvider>
                </app-basic-row>
              </template>
            </v-row>
          </sub-json-field>
          <h1 v-else class="error white--text">
            esto es un error !!!
          </h1>
        </template>
        <div v-if="!disabled">
          <v-btn class="d-block" @click="add_item(innerValue.length)">
            add last
          </v-btn>
        </div>
      </div>
      <div
          v-else-if="(!!_innerValue) && _innerValue.constructor === Object"
          class="d-flex flex-column"
      >
        <good-look-json
            v-on="{...(disabled? {}:{close:pop_key})}"
            :inverse-color="!inverseColor"
            :global-format="_globalFormat"
            :data="_innerValue"
        />
        <json-edit-field
            :append-keys="!disabled && appendKeys"
            @appendKey="appendNewKey"
        >
          <v-container fluid class="pb-0 pr-1 ma-0">
            <v-row class="flex-grow-1 overflow-auto ma-0 pa-1">
              <template v-for="field in _formFields">
                <app-basic-row
                    :key="field.keyData"
                    :field="field"
                    :disabled="disabled"
                    v-model="innerValue[field.keyData]"
                    class="mr-0 pr-0"
                >
                  <div class="d-flex flex-row flex-grow-1">
                    <ValidationProvider
                        class="flex-grow-1"
                        :vid="field.keyData"
                        :rules="field.rules"
                        v-slot="{ errors }"
                    >
                      <auto-field
                          :disabled=disabled
                          :hiddenDetails="true"
                          v-bind="field"
                          v-model="innerValue[field.keyData]"
                      ></auto-field>
                    </ValidationProvider>
                    <div class="flex-shrink-0 flex-grow-0 d-flex flex-column">
                      <div>
                        <v-btn small icon @click="addedKeys.pop(field.keyData)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>

                </app-basic-row>
              </template>
            </v-row>
          </v-container>
        </json-edit-field>
      </div>
      <h1 v-else class="error white--text">
        esto es un error !!!
      </h1>
    </div>
  </div>
</template>

<script>

import {FormFieldItem} from "../tools";
import {keyFormData} from "../autoFieldMixin";
import {
    ValidationObserver,
} from 'vee-validate'
import GoodLookJson from "@/components/molecules/goodLookJson.vue";
import jsonField from "@/plugins/AutoForms/FormFields/jsonField.vue";
import SubJsonField from "@/plugins/AutoForms/FormFields/subJsonField.vue";
import AutoForm from "@/plugins/AutoForms/AutoForm.vue";
import AppBasicRow from "@/plugins/AutoForms/app-basic-row.vue";
import JsonEditField from "@/plugins/AutoForms/FormFields/jsonEditField.vue";


// TODO: el form no captura ni el clear ni el change aun
export default {
    name: "jsonField",
    components: {
        JsonEditField,
        AppBasicRow,
        AutoForm,
        SubJsonField,
        GoodLookJson,
        autoField: () => import('../AutoField.vue'),
        autoForm: () => import('../AutoForm.vue'),
        ValidationObserver,
    },
    mixins: [keyFormData],
    props: {
        disabled: {
            default: () => false,
        },
        appendKeys: {
            type: Boolean,
            default: () => false,
        },
        inverseColor: {
            type: Boolean,
            default: () => false,
        },
        formFields: {
            type: Array, default: () => []
        },
        exclude: {
            type: Array, default: () => []
        }
    },
    data: () => (
        {
            innerValue: {},
            addedKeys: []
        }
    ),
    mounted() {
        if (
            this._formFields.map(
                ({keyData}) => keyData
            ).some(keyData => this.formData[keyData])
        ) {
        }
    },
    beforeMount() {
        this.innerValue = this.value ?? {};
    },
    computed: {
        _globalFormat() {
            return Object.values(this.innerValue).some(x => (!!x) && x.constructor === Object) ?
                {
                    type: 'col'
                } : {type: 'row'};
        },
        _innerValue() {
            return Object.keys(
                this.innerValue
            ).filter(
                keyData => !(
                    this._formFields.map(
                        ({keyData}) => keyData).includes(keyData)
                )
            ).reduce(
                (carr, keyData) => (
                    {...carr, [keyData]: this.innerValue[keyData]}
                ), {}
            )
        },
        _formFields() {
            const fields = this.appendKeys ? this.addedKeys : [];
            if (!!this.formFields) {
                return [
                    ...fields,
                    ...this.formFields.filter(
                        ({keyData}) => !this.exclude.includes(keyData)
                    )
                ]
            } else {
                return [
                    ...fields,
                    ...Object.keys(this.innerValue).filter(
                        (keyData) => !this.exclude.includes(keyData)
                    ).map(keyData => ({
                        keyData,
                        type: this.innerValue[keyData]
                    }))
                ]
            }
        }
    },
    methods: {
        add_item(index) {
            this.innerValue.splice(index, 0, {});
        },
        jsonField() {
            return jsonField
        },
        pop_key(key) {
            this.$delete(this.innerValue, key)
            this.addedKeys[0] = this.addedKeys[0].filter(
                ({keyData}) => keyData !== key
            )
        },
        pop_key_index(key, index) {
            this.$set(this.innerValue[index], key, null);
            this.$delete(this.innerValue[index], key);
            this.addedKeys = this.addedKeys.filter(
                ({keyData}) => keyData !== key
            );
        },
        appendNewKey(newKeyTypePair) {
            let {name, type} = newKeyTypePair;
            const formFieldItem = new FormFieldItem({
                text: name,
                type: type,
                extend: {
                    inverseColor: !this.inverseColor
                }
            });
            this.addedKeys.push(
                formFieldItem
            )
        }
    }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/colors';

.form-container {
  border: solid map-get($colors, base) 1px;
  border-radius: 1ch;
  box-sizing: border-box !important;
  position: relative;
  margin-top: 16px;
}

.json-field-header {
  border-radius: 1ch;
  background-color: white;
  color: darkgray;
  box-sizing: border-box;
  position: absolute;
  top: -18px;
  left: .5em;
  padding: 0 .15em;
}
</style>