<template>
  <v-text-field
      dense
      filled
      v-bind="modifiers"
      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
      :type="show1 ? 'text' : 'password'"
      @click:append="show1 = !show1"
      v-on="$listeners"
      v-model="innerValue"
  />
</template>

<script>


import {keyFormData} from "@/plugins/AutoForms/autoFieldMixin";

export default {
  inheritAttrs: false,
  name: "passwordField",
  mixins: [keyFormData],
  data: () => ({
    show1: false
  }),
  computed: {
    modifiers() {
      const {type, ...rest} = this.$attrs;
      return rest;
    }
  }
}
</script>

<style scoped>

</style>