export function extName(baseName, ext = "csv") {
  return `${baseName}-${new Date().toLocaleDateString()}.${ext}`;
}

export function blobDomLink(_blob, fileName, ext, type = "application/octet-stream") {
  const blob = new Blob([_blob], { type });
  const link = document.createElement("a");
  link.download = extName(fileName, ext);
  link.href = window.URL.createObjectURL(blob);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function downloadBlob(blob, fileName, ext) {
  const link = document.createElement("a");
  link.download = extName(fileName, ext);
  link.href = window.URL.createObjectURL(blob);
  document.body.appendChild(link);
  link.click();
  link.remove();
}
