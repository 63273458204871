<template>
  <app-basic-layout @add="createDialog=true">
    <template v-slot:head>
      <h2>Operations</h2>
    </template>
    <v-data-iterator
        v-if="operations_collection"
        class="flex-grow-1 fill-height"
        :items="operations_collection.items"
    >
      <template v-slot:default="{items}">
        <v-expansion-panels v-model="open_panel">
          <v-expansion-panel
              v-for="({id,isActive,...item},i) in items"
              :key="i"
          >
            <v-expansion-panel-header>
              <div class="d-flex flex-row">
                <v-simple-checkbox
                    :value="isActive"
                    @click="operations_collection.update({id},{isActive:!isActive},true)"
                ></v-simple-checkbox>
                <h3 class="my-auto">{{ item.order }} {{ item.name |startCase }}</h3>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-2">
              <auto-form
                  :exclude="['id']"
                  :loading-action="operations_collection.loading"
                  ref="updateForm"
                  :form-data="{...item,isActive}"
                  :form-fields="operations_collection.formFields"
                  @submit="(obj)=>_update_item({id},obj,true)"
              >
                <template v-slot:prepend-action="{item}">
                  <v-btn
                      :loading="operations_collection.loading"
                      :disabled="operations_collection.loading"
                      class="mr-3"
                      color="error"
                      @click="_remove_item({id})"
                  >
                    delete
                  </v-btn>
                  <v-spacer/>
                </template>
              </auto-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-data-iterator>
    <app-dialog
        title="create a new operation"
        v-model="createDialog"
    >
      <auto-form
          :loading-action="operations_collection.loading"
          :form-fields="operations_collection.formFields"
          :exclude="['procedure_id','id']"
          @submit="create"
      >
      </auto-form>
    </app-dialog>
  </app-basic-layout>
</template>

<script>
import AppBasicLayout from "@/components/templates/appBasicLayout";
import {Collection} from "@/plugins/collection";
import {operations} from "@/api/data/procedures";
import AppDialog from "@/components/molecules/appDialog.vue";
import autoForm from "@/plugins/AutoForms/AutoForm.vue"

export default {
  name: "ProcedureOperationsView",
  components: {AppDialog, AppBasicLayout, autoForm},
  data: () => ({
    open_panel:0,
    operations_collection: null,
    procedure_id: null,
    createDialog: false,
  }),
  watch: {
    '$route.params.procedure_id'(newValue, oldValue) {
      if (newValue != this.procedure_id) {
        this.procedure_id = newValue
        this.fetchData({procedure_id: this.procedure_id})
      }
    }
  },
  beforeMount() {
    this.procedure_id = this.$route.params.procedure_id;
    this.operations_collection = new Collection(operations, {
      procedure_id: this.procedure_id
    })
    this.operations_collection.fetchData()
  },
  methods: {
    create(obj) {
      this.operations_collection.create(
          {
            ...obj,
            procedure_id: this.procedure_id
          }
      ).then(() => {
        this.operations_collection.fetchData()
        this.createDialog = false;
      });
    },
    _update_item(filter, obj) {
      this.operations_collection.update(filter, obj).then(res => {
        this.operations_collection.fetchData()
      });
    },
    _remove_item(filters) {
      this.operations_collection.remove(filters).then(res => {
        this.operations_collection.fetchData()
      })
    }
  }
}
</script>

<style scoped>

</style>