import Alert from "./Alert.vue";
import Vue from "vue";
import {isDevelopmentMode} from "@/const";
import vuetify from "@/plugins/vuetify";

let id = 0;

export function showAlert(message, options = {}) {
    options.message = message;
    const app = document.getElementById("app");
    const _id = `app-alert-${id}`;
    id++;

    const alert_div = document.createElement('div');
    alert_div.setAttribute("id", _id);
    if (app) {
        app.appendChild(
            alert_div
        )
        new Vue({
            vuetify,
            render: h => h(Alert, {props: options})
        }).$mount(`#${_id}`);
        setTimeout(() => {
            alert_div.remove();
        }, 10000);
    }
}

function error(message, options) {
    return showAlert(message, {color: "error", icon: "mdi-block-helper", ...options})
}

function success(message, options) {
    return showAlert(message, {color: "success", icon: "mdi-check", ...options})
}

function info(message, options) {
    return showAlert(message, {color: "info", icon: "mdi-info", ...options})
}

function warning(message, options) {
    return showAlert(message, {color: "warning", icon: "mdi-warning", ...options})
}

export const apiAlert = {
    promiseError: (err) => {
        if (isDevelopmentMode) {
            console.log(err)
        }
        err.then(({data: {detail}}) => {
            apiAlert.error(detail)
        });
        return Promise.reject(err)
    },
    error,
    success,
    info,
    warning
};


Vue.use({
    install: (Vue, options) => {
        Vue.prototype.$alert = Object.assign(showAlert, apiAlert);
        Vue.$alert = Object.assign(showAlert, apiAlert);
    }
});
