<template>
  <app-basic-layout @add="openCreate" :hide-header=true>
    <app-data-table
        :hide-header="false"
        :collection="collection"
        ref="appDataTable"
        @onSearch="search"
        :allow-download="true"
    >
      <template v-slot:head>
        <h2>Locations</h2>
      </template>
      <template v-slot:edit-dialog="{item:{id,...item}}">
        <auto-form
            :loading-action="collection.loading"
            :form-data="item"
            :formFields="collection.formFields"
            :exclude="['id']"
            @submit="(obj)=>_update_item({id},obj)"
        >
          <template v-slot:prepend-action>
            <v-btn :loading="collection.loading" :disabled="collection.loading" color="error" @click.stop="_remove_item({id})">
              delete
            </v-btn>
          </template>
        </auto-form>
      </template>
    </app-data-table>
    <app-dialog
        v-model="createDialog"
    >
      <auto-form
          :loading-action="collection.loading"
          text="create"
          :form-data="collection.formData"
          :formFields="collection.formFields"
          :exclude="['id']"
          @clear="clear"
          @submit="create"
      />
    </app-dialog>
  </app-basic-layout>
</template>

<script>
import {Collection} from "@/plugins/collection";
import AppDataTable from "@/components/organisms/AppDataTable";
import appBasicLayout from "@/components/templates/appBasicLayout";
import autoForm from "@/plugins/AutoForms/AutoForm";
import AppDialog from "@/components/molecules/appDialog";
import {locations} from "@/api/data/locations";

export default {
  name: "LocationsView",
  components: {AppDialog, AppDataTable, appBasicLayout, autoForm},
  // mixins: [collection(user_driver)],
  data: () => ({
    collection: new Collection(locations),
    createDialog: false,
  }),
  beforeMount() {
    this.collection.fetchData()
  },
  methods: {
    search(text) {
      if (text.trim() == '') {
        this.collection.clear_filters(['like', 'like_cols']);
      } else {
        this.collection.filter({
          like: text,
          like_cols: ['name', 'code'],
        })
      }
    },
    clear() {
      this.collection.clearData();
    },
    openCreate() {
      this.createDialog = true;
    },
    create(obj) {
      this.collection.create(obj).then(() => {
        this.collection.fetchData();
        this.createDialog = false;
      });
    },
    _remove_item(filter) {
      this.collection.remove(filter).then(res => {
        this.collection.fetchData();
        this.$refs['appDataTable'].editDialog = false;
      })
    },
    _update_item(filter, obj) {
      this.collection.update(filter, obj).then(res => {
        this.collection.fetchData();
        this.$refs['appDataTable'].editDialog = false;
        return res;
      })
    }
  }
}
</script>

<style scoped>

</style>