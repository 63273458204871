<template>
  <app-basic-layout
      :hideHeader="true"
      :hide-header="true"
      v-on="{
        ...(isSuperUser? {
          add:()=>createDialog=true
        }:{})
      }"
      :protected-add="true"
  >
    <app-dialog v-model="createDialog">
      <auto-form
          :form-fields="collection.formFields"
          :exclude="['user','updated_at']"
          :loading-action="collection.loading"
          @submit="createNewTransaction"
      ></auto-form>
    </app-dialog>
    <app-data-table
        :allow-download="true"
        ref="appDataTable"
        :collection="collection"
        :hide-header="false"
        @onSearch="search"
        :download-params="downloadParams"
    >
      <template v-slot:head>
        <h2>Transaction</h2>
      </template>
      <template
          v-slot:edit-dialog="{item:{id,user_id,...item}}"
      >
        <auto-form
            :protected-submit="true"
            :form-fields="collection.formFields"
            :exclude="['user','updated_at']"
            :form-data="{...item,user_id}"
            read-only-array="['value','driver_id','user_id']"
            :loading-action="collection.loading"
            @submit="({value,driver_id,user_id,...obj})=>updateTransaction({id},obj)"
        >
          <template
              v-if="isSuperUser"
              v-slot:prepend-action>
            <app-protected-btn
                color="error"
                @submit="deleteTransaction({id})"
                :disabled="collection.loading"
                :loading="collection.loading">
              delete
            </app-protected-btn>
          </template>
        </auto-form>
      </template>
    </app-data-table>
  </app-basic-layout>
</template>

<script>
import appBasicLayout from '@/components/templates/appBasicLayout.vue'
import AppDataTable from "@/components/organisms/AppDataTable.vue";
import {Collection} from "@/plugins/collection";
import {transaction} from "@/api/data/facture";
import AppDialog from "@/components/molecules/appDialog.vue";
import AutoForm from "@/plugins/AutoForms/AutoForm.vue";
import {session} from "@/api/auth";
import AppProtectedBtn from "@/components/organisms/appProtectedBtn.vue";
import GoodLookJson from "@/components/molecules/goodLookJson.vue";
import {extend, nested} from "@/api/helper";

export default {
    name: "AppTransactionView",
    components: {
        GoodLookJson,
        AppProtectedBtn,
        AutoForm,
        AppDialog,
        AppDataTable,
        appBasicLayout
    },
    computed: {
        isSuperUser() {
            return session.isSuperUser
        }
    },
    data: () => ({
        createDialog: false,
        collection: new Collection(
            transaction
        ),
        downloadParams: {
            ...extend('driver_id.code', 'user_id.username'),
            ...nested(),
        }
    }),
    methods: {
        search(text) {
            if (text.trim() == '') {
                this.collection.clear_filters(['like', 'like_cols']);
            } else {
                this.collection.filter({
                    like: text,
                    like_cols: [
                        'no',
                        'observation',
                        'driver_id.code',
                        'user_id.username'
                    ],
                })
            }
        },
        updateTransaction(filter, obj) {
            this.collection.update(filter, obj).then(
                () => {
                    this.$refs['appDataTable'].fetchData();
                }
            )
        },
        deleteTransaction(filter) {
            this.collection.remove(
                filter
            ).then(() => {
                this.$refs['appDataTable'].fetchData();
            })
        },
        createNewTransaction(obj, autoForm) {
            this.collection.create(obj).then(() => {
                this.createDialog = false;
                autoForm.loadInnerValue();
                this.$refs['appDataTable'].fetchData();
            });
        }
    }
}
</script>

<style>

</style>