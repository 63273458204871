<template>
  <app-basic-layout :hideHeader="true" @add="openCreate">
    <template
        v-if="!!service_collection"
    >
      <app-data-table
          :collection="service_collection"
          ref="appDataTable"
          :edit-width="dialogWith"
          :hideHeader="false"
          :allow-download="true"
          :download-params="downloadParams"
          :show-select="isSuperUser"
          :item-disabled="({is_lock,...rest})=>is_lock||!!rest['payed_service.payed_at']"
          :item-class="({canceled_at,driver_debt})=>canceled_at? 'error white--text':(driver_debt<0? 'grey-1 white--text':'')"
          :actionSelected="[
            {
              icon:'mdi-lock',text:'lock',
              action:(items)=>turn_lock(items,true), hidden:!isSuperUser
            },
            {
              icon:'mdi-lock-open-variant-outline',text:'unlock',
              action:(items)=>turn_lock(items,false), hidden:!isSuperUser
            },
            {
              icon:'mdi-help',text:'help',
              action:showHelp, hidden:false
            },
          ]"
      >
        <template v-slot:head>
          <h2>Services</h2>
        </template>
        <template v-slot:edit-dialog="{item:{id,is_lock,...item}}">
          <auto-form
              :loading-action="service_collection.loading"
              ref="updateForm"
              :form-data="item"
              :form-fields="service_collection.formFields"
              :exclude="['id']"
              :disabled="!can_edit_or_create||is_lock||!!item['payed_service.payed_at']"
              @submit="(obj)=>_update_item({id},obj)"
          >
            <app-protected-btn
                v-if="item['payed_service.payed_at']"
                :loading="service_collection.loading"
                :disabled="service_collection.loading"
                color="error"
                @submit="takeoff({id})">
              <template
                  v-slot:top
              >
                <p>Este procedimiento se usa cuando se detecta un servicios que ya se cerró y es necesario
                  "des-pagarlo". Dado que ya ese servicios se pago, es necesario que se genere un servicios idéntico
                  pero este servicio tendra la deuda en negativo para que los cálculos se hagan a favor del chofer que
                  al final es el afectado en este caso que se le asigno/genero una deuda falsa.</p>
              </template>
              take off
            </app-protected-btn>
            <template v-slot:prepend-action="{item}">
              <app-protected-btn
                  :loading="service_collection.loading"
                  :disabled="service_collection.loading"
                  class="mr-3"
                  color="error"
                  @submit="_remove_item({id})"
              >
                delete
              </app-protected-btn>
              <v-spacer/>
            </template>
          </auto-form>
        </template>
        <template v-slot:filter-dialog>
          <auto-form
              :loading-action="service_collection.loading"
              ref="filterForm"
              :form-data="active_filters"
              :form-fields="filter_form"
              @clear="clearFilters"
              @submit="filters"
              action-name="filter"
          />
        </template>
      </app-data-table>
      <app-dialog
          title="New Services"
          :width="dialogWith"
          v-model="createDialog"
      >
        <auto-form
            :disabled="!can_edit_or_create"
            :loading-action="service_collection.loading"
            ref="createDialog"
            text="create"
            :form-data="service_collection.formData"
            :form-fields="service_collection.formFields"
            :exclude="['id']"
            @clear="clearData"
            @submit="_create_new_item">
          <template v-slot="{driver_id,driver_debt}">
            <app-facture-plugin v-if="driver_id" :driver_debt="driver_debt" :driver_id="driver_id"/>
          </template>
        </auto-form>
      </app-dialog>
    </template>
    <app-dialog
        title="Help"
        v-model="show_help">
      <app-service-help-dialog/>
    </app-dialog>
  </app-basic-layout>
</template>

<script>

import {Collection} from "@/plugins/collection";
import AppDataTable from "@/components/organisms/AppDataTable.vue";
import appBasicLayout from "@/components/templates/appBasicLayout.vue";
import autoForm from "@/plugins/AutoForms/AutoForm.vue";
import {service_take_off, services} from "@/api/data/services";
import AppDialog from "@/components/molecules/appDialog.vue";
import {session} from "@/api/auth"
import {order_by} from "@/api/helper";
import AppFacturePlugin from "@/components/organisms/appFacturePlugin.vue";
import {filter_services, serviceFormFilters, downloadParams} from "@/views/home/services_list/servicesViewConfig";
import AppServiceHelpDialog from "@/views/home/services_list/AppServiceHelpDialog.vue";
import AppProtectedBtn from "@/components/organisms/appProtectedBtn.vue";

export default {
    name: "AppServiceList",
    components: {
        AppProtectedBtn,
        AppServiceHelpDialog,
        AppFacturePlugin,
        AppDialog,
        AppDataTable,
        appBasicLayout,
        autoForm
    },
    data: () => ({
        downloadParams: downloadParams,
        show_help: false,
        createDialog: false,
        service_collection: null,
        dialogWith: 800,
        active_filters: {},
        filter_form: serviceFormFilters
    }),
    computed: {
        current_user() {
            return session?.me
        },
        isSuperUser() {
            return session.isSuperUser;
        },
        can_edit_or_create() {
            return session.isAgent || session.isSuperUser
        }
    },
    beforeMount() {
        if (this.isSuperUser) {
            this.active_filters = {};
        } else if (this.current_user.agent) {
            this.active_filters = {
                agent_id: [
                    this.current_user?.id,
                    ...this.current_user["inspire_to"]
                ]
            }
        } else {
            this.active_filters = {};
        }

        this.service_collection = new Collection(services, {
            ...order_by('-date', '-moment'),
        }, null, {
            ...this.active_filters
        }, null, false)
    },
    methods: {
        fillFormData() {
            this.service_collection.formData = {
                agent_id: this.current_user?.agent?.user_id,
                is_done: false
            };
        },
        filters(filters) {
            filter_services(this, filters)
        },
        showHelp() {
            this.show_help = true;
        },
        async turn_lock(items_selected, value) {
            let ids = items_selected.reduce((carr, {id}) => [...carr, id], [])
            if (ids.length > 0) {
                return this.service_collection.update(
                    {id: ids},
                    {is_lock: value},
                    true
                )
            }
            return Promise.resolve();
        },
        clearFilters() {
            this.filters({});
        },
        clearData() {
            this.fillFormData();
            // this.$refs['createDialog'].loadInnerValue();
        },
        _create_new_item(obj) {
            this.service_collection.formData = obj;
            this.service_collection.create(
                obj,
                true
            ).then((res) => {
                this.createDialog = false;
                setTimeout(
                    this.fillFormData,
                    300
                )
                return res;
            });
        },
        openCreate() {
            this.fillFormData();
            // this.service_collection.clearData();
            this.createDialog = true;
        },
        _update_item(filter, obj) {
            this.service_collection.update(
                filter,
                obj
            ).then(res => {
                this.$refs['appDataTable'].fetchData();
                return res
            });
        },
        takeoff({id}) {
            service_take_off.create(
                {}, {service_id: id}
            ).then((res) => {
                this.$refs['appDataTable'].fetchData();
                return res;
            });
        },
        _remove_item(filters) {
            this.service_collection.remove(
                filters
            ).then(res => {
                this.$refs['appDataTable'].fetchData();
                return res
            })
        }
    }
    ,
}
;


</script>

