<template>
  <app-basic-layout @add="openCreate">
    <template v-slot:head="">
      <h2>ResourceView</h2>
    </template>
    <app-data-table
        :collection="collection"
        ref="appDataTable"
    >
      <template v-slot:edit-dialog="{item:{id,...item}}">
        <auto-form
            :loading-action="collection.loading"
            :form-data="item"
            :formFields="collection.formFields"
            @submit="(obj)=>_update_item({id},obj)"
        >
          <template v-slot:prepend-action="{item}">
            <v-btn
                :loading="collection.loading"
                :disabled="collection.loading"
                color="error"
                @click.stop="_remove_item({id})">
              delete
            </v-btn>
          </template>
        </auto-form>
      </template>
    </app-data-table>
    <app-dialog v-model="createDialog">
      <auto-form
          :loading-action="collection.loading"
          text="create"
          :form-data="collection.formData"
          :formFields="collection.formFields"
          :exclude="['id']"
          @clear="clearData"
          @submit="create"
      />
    </app-dialog>
  </app-basic-layout>
</template>

<script>

import {secure_table} from "@/api/data";
import {Collection} from "@/plugins/collection";
import AppDataTable from "@/components/organisms/AppDataTable";
import appBasicLayout from "@/components/templates/appBasicLayout";
import autoForm from "@/plugins/AutoForms/AutoForm";
import AppDialog from "@/components/molecules/appDialog";

const collection = new Collection(secure_table);

export default {
  name: "ResourceView",
  components: {AppDialog, AppDataTable, appBasicLayout, autoForm},
  data: () => ({
    collection,
    createDialog: false,
  }),
  methods: {
    clearData: collection.clearData.bind(collection),
    create(obj) {
      this.collection.create(obj).then((res) => {
        this.collection.fetchData();
        this.createDialog = false;
      });
    },
    openCreate() {
      this.createDialog = true;
    },
    _remove_item(filter) {
      this.collection.remove(filter).then(res => {
        this.collection.fetchData();
        this.$refs['appDataTable'].editDialog = false;
      })
    },
    _update_item(filter, obj) {
      this.collection.update(filter, obj).then(res => {
        this.collection.fetchData();
        this.$refs['appDataTable'].editDialog = false;
      })
    }
  }
}


</script>

