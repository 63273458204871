var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.interaction)?_c('v-timeline-item',{staticClass:"my-1 pa-1",attrs:{"color":_vm.color,"small":"","elevation":"1"}},[_c('v-card',{staticClass:"d-flex pr-4",class:{
              'flex-row justify-start':_vm.$vuetify.breakpoint.mdAndUp,
              'flex-column':_vm.$vuetify.breakpoint.smAndDown,
          },attrs:{"disabled":!!_vm.interaction.canceled_at,"shaped":"","elevation":"0"}},[_c('v-card',[_c('v-card-subtitle',[_c('h3',{staticClass:"text--accent-2",class:{'text-decoration-line-through':_vm.interaction.canceled_at}},[_vm._v(" "+_vm._s(_vm._f("monetize")(_vm.interaction.description['price']))+" "+_vm._s(_vm.timeText)+" ")]),_c('span',[_vm._v(_vm._s(_vm.text))]),_vm._t("text",null,null,{
            is_canceled:!!_vm.interaction.canceled_at,
            interaction:_vm.interaction,
            price:_vm.interaction.description['price']
        }),_c('div',[(_vm.is_agent)?[_c('b',[_vm._v("@"+_vm._s(_vm.interaction.user.username))]),_vm._v(" | ")]:_vm._e(),_c('span',[_vm._v(_vm._s(_vm._f("timeStamp")(_vm.interaction.sended_at)))])],2)],2)],1),(_vm.activeAccept && _vm.$vuetify.breakpoint.mdAndUp)?_c('v-spacer'):_vm._e(),(_vm.activeAccept)?_c('div',{staticClass:"my-auto pr-3 pt-2 justify-end"},[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.$emit('accept',_vm.interaction)}}},[_vm._v(" acepto ")])],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }