<template>
  <v-container fluid class="ma-0 py-3 my-3 form-container flex-grow-1">
    <span class="json-field-header">
      <v-btn
          color="error"
          v-if="!disabled && $listeners.close"
          icon
          @click.stop='$emit("close")'
      >
        <v-icon>
        mdi-delete
        </v-icon>
      </v-btn>
      {{ textHeader }}
    </span>
    <v-row class="mb-0">
      <v-col cols="12" class="px-2 d-flex flex-column">
        <slot name="head">

        </slot>
      </v-col>
    </v-row>
    <good-look-json
        class="px-0"
        v-on="{...(disabled? {}:{close:(key)=>$emit('pop_key',key)})}"
        :global-format="{type:'col'}"
        :inverse-color="inverseColor"
        :data="unKeyValues"
    />
    <v-row class="mb-0">
      <slot>

      </slot>
      <v-col cols="12" class="d-flex flex-column px-2">
        <validation-observer
            tag="div"
            ref="observer"
            v-slot="{ valid,invalid }"
        >
          <v-col
              class="d-flex flex-row" cols="12" v-if="appendKeys">
            <auto-field
                dense
                class="flex-grow-1"
                outlined
                hide-details
                label="new key"
                type="textField"
                v-model="newKeyTypePair.name"
            ></auto-field>
            <auto-field
                class="grow-shrink-0"
                type="selectField"
                :items="autoformTypes"
                dense
                outlined
                hide-details
                label="type"
                v-model="newKeyTypePair.type"
            ></auto-field>
            <v-btn
                icon class="ma-auto"
                @click.stop="appendKey()"
            >
              <v-icon>
                mdi-send
              </v-icon>
            </v-btn>
          </v-col>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GoodLookJson from "@/components/molecules/goodLookJson.vue";
import {KeyTypePair} from "@/plugins/AutoForms/tools";
import {components} from "@/plugins/AutoForms/AutoField.vue";

export default {
    name: "subJsonField",
    components: {
        autoField: () => import("@/plugins/AutoForms/AutoField.vue"),
        GoodLookJson
    },
    props: {
        inverseColor: {
            type: Boolean,
            default: () => false,
        },
        formFields: {
            type: Array,
            default: () => ([])
        },
        textHeader: {
            type: String
        },
        value: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            default: () => false
        },
        addedKeys: {
            type: Array,
            default: () => ([])
        },
        appendKeys: {
            type: Boolean,
            default: () => false
        }
    },
    watch: {
        value(newValue) {
            this.innerValue = {...newValue};
        }
    },
    data: () => ({
        innerValue: {},
        newKeyTypePair: new KeyTypePair(),
    }),
    beforeMount() {
        this.innerValue = {...this.value};
    },
    methods: {
        appendKey() {
            this.$emit('appendFormFields', {
                ...this.newKeyTypePair
            });
            this.newKeyTypePair = new KeyTypePair();
        }
    },
    computed: {
        autoformTypes() {
            return Object.keys(components);
        },
        unKeyValues() {
            const keys = this.addedKeys.map(({keyData}) => keyData)
            return Object.keys(this.innerValue).reduce((carr, key) => {
                if (!keys.includes(key)) {
                    carr[key] = this.innerValue[key];
                }
                return carr
            }, {})
        }
    }
}
</script>

<style scoped>

</style>