import {
    ConfigDefinition,
    DefineMethod,
    DefineMethodsModifiers,
    ExtendTableDefinition,
    FormDefinition,
    Table
} from "@/api/Table";
import {monetize, yesNo} from "@/plugins/filters";

import {extend, nested, order_by} from "@/api/helper";

export const user_account = new Table(
    '', 'user_account',
    new ConfigDefinition(
        nested(
            'user_driver',
            'user_agent'
        )
    ),
    new ExtendTableDefinition(
        [],
        {
            is_superuser: {
                filter: yesNo
            },
            user_driver: {
                filter: (item) => item?.length > 0 ? 'yes' : '',
                rename: 'is_driver'
            },
            user_agent: {
                filter: (item) => item?.length > 0 ? 'yes' : '',
                rename: 'is_agent'
            }
        },
    ),
    new DefineMethodsModifiers(
        new DefineMethod(
            new ConfigDefinition(nested()),
            null,
            [
                "user_agent",
                "user_driver",
            ]
        ))
)

export const user_driver_basic = new Table(
    '/settings',
    'user_driver'
)
export const user_driver = new Table(
    '/settings',
    'user_driver',
    new ConfigDefinition(
        {
            ...order_by('code'),
            ...extend('user_account', "attended_by.name")
        }
    ),
    new ExtendTableDefinition(
        [
            'user_account.id',
            'user_id',
            'user_account.is_superuser',
            "attended_by"
        ],
        {
            isActive: {
                filter: yesNo
            }, isPremium: {
                filter: yesNo,
                colStyle: {
                    type: "icon",
                    match: {
                        true: "mdi-star",
                        false: "mdi-star-off-outline"
                    }
                }
            },
        }
    ),
    new DefineMethodsModifiers(
        new DefineMethod(
            new ConfigDefinition(extend('user_account')),
            null,
            [
                'user_account.id',
                'user_id',
                'user_account.is_superuser',
                "attended_by.name"
            ]
        )
    )
);


export const driver_facture_resume = new Table(
    '/settings',
    'user_driver',
    new ConfigDefinition(
        {
            ...order_by('code'),
            ...extend(
                "attended_by.name",
                "calc_debt.value",
                "calc_deb_pay.value",
                "calc_service_unclose.value",
                "deposit.value"
            )
        }
    ),
    new ExtendTableDefinition(
        [
            'user_account.id',
            'user_id',
            'user_account.is_superuser',
            "attended_by"
        ],
        {
            isActive: {
                filter: yesNo
            },
            "calc_debt.value": {
                rename: "calc_debt",
                filter: monetize
            },
            "attended_by.name": {
                rename: "attended_by",
            },
            "calc_deb_pay.value": {
                rename: "calc_deb_pay",
                filter: monetize
            },
            "calc_service_unclose.value": {
                rename: "calc_service_unclose",
                filter: monetize
            },
            "deposit.value": {
                rename: "deposit",
                filter: monetize
            },
            isPremium: {
                filter: yesNo,
                colStyle: {
                    type: "icon",
                    match: {
                        true: "mdi-star",
                        false: "mdi-star-off-outline"
                    }
                }
            },
        }
    )
);


export const user_agent_basic = new Table(
    '/settings',
    'user_agent'
)

export const user_agent = new Table(
    '/settings',
    'user_agent',
    new ConfigDefinition(
        extend('user_account', 'inspire_by.name')
    ),
    new ExtendTableDefinition(
        ['user_account.id', 'user_id', 'user_account.is_superuser', 'inspire_by'],
        {
            'user_account.email': {
                rename: 'email'
            },
            'user_account.phone_number': {
                rename: 'phone_number'
            },
            'user_account.username': {
                rename: 'username'
            },
            is_collaborator: {
                filter: yesNo
            },
            isActive: {
                filter: yesNo
            },
            'inspire_by.name': {
                rename: "inspire_by"
            }
        }
    ),
    new DefineMethodsModifiers(
        new DefineMethod(
            new ConfigDefinition(extend('user_account')),
            null,
            [
                'user_account.id',
                'user_id',
                'user_account.is_superuser',
                'inspire_by.name'
            ],
            {
                is_collaborator: {
                    cols: {
                        md: 6
                    }
                },
                isActive: {
                    cols: {
                        md: 6
                    }
                }
            }, new FormDefinition(
                ['is_collaborator', 'isActive', 'inspire_by']
            )
        )
    )
);