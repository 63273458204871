<template>
  <v-card>
    <v-card-text class="pa-1 pl-3">
      <v-list dense>
        <v-list-item
            v-for="item in items" class="d-flex flex-row pa-0"
            :key="item.method"
        >
          <td-cell
              :col-style="method_style"
              :value="item.method"/>
          <v-spacer/>
          <v-simple-checkbox v-model="item.isActive" @input="value=>updateRow(item,value)"></v-simple-checkbox>
        </v-list-item>
        <v-list-item
            v-for="item in miss" class="d-flex flex-row pa-0"
            :key="item.method"
        >
          <td-cell
              :col-style="method_style"
              :value="item.method"/>
          <v-spacer/>
          <v-simple-checkbox v-model="item.isActive" @input="value=>createRow(item,value)"></v-simple-checkbox>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import {secure_table} from "@/api/data";
import tdCell from "@/components/molecules/tdCell.vue";
import {SecureTableRow} from "@/api/Table";

export default {
  name: "appTableResource",
  components: {tdCell},
  props: {
    tableName: {
      type: String
    }
  },
  methods: {
    updateRow(item, value) {
      item.isLoading = true;
      let {id, isLoading, ...fields} = item;
      secure_table.update({id},
          {
            table_name: this.tableName,
            ...fields
          }
      ).then((res) => {
        this.fetchData();
      })
    },
    createRow(item, value) {
      item.isLoading = true;
      let {isLoading, ...fields} = item;
      secure_table.create(
          {
            table_name: this.tableName,
            ...fields
          }
      ).then((res) => {
        this.fetchData();
      })
    },
    fetchData() {
      secure_table.get({
        table_name: this.tableName
      }).then(
          ({items, modifiers, headers}) => {
            this.items = items;
            let [{driver, ...otherAttrs}, ...otherItems] = items;
            this.modifiers = modifiers;
            this.headers = headers;
            this.miss = ['GET', 'POST', 'DELETE', 'PUT'].filter(x => !this.methods.includes(x)
            ).map(
                method => (new SecureTableRow(method, false, driver))
            );
          }
      );
    },
  },
  data: () => ({
    items: [],
    modifiers: {},
    headers: [], miss: []
  }),
  computed: {
    method_style() {
      if (this.modifiers.get_modifiers && this.modifiers.get_modifiers.colStyle)
        return this.modifiers.get_modifiers.colStyle.method
      return {}
    },
    methods() {
      return this.items.map((item) => item.method);
    },

  },
  beforeMount() {
    this.fetchData();
  }
}
</script>

<style scoped>

</style>