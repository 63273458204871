<template>
  <div class="d-flex flex-column">
    <span>connected : {{ connected }}</span>
    <div v-if="current_user?.username == request_data.request_by">
      <h3>Esperando confirmación de la otra parte</h3>
      <p>Manténgase en esta pantalla por favor</p>
    </div>
    <div v-else>
      <div v-if="current_user?.username == interaction?.username">
        <h3>el cliente ha aceptado su oferta</h3>
        <p>parte del chofer que es el que toma la oferta</p>
      </div>
      <div v-else>
        <h3>un chofer a tomado su carrera</h3>
        <p>parte del cliente que es la parte que no realiza la interaccion</p>
      </div>
    </div>
    <div>
      <pre>
        {{ current_user?.username }} , {{ interaction?.username }}
      </pre>
      <h3>Acepte para continuar</h3>
      <good-look-json
          :order="['price','final_price','Municipio Origen','Municipio Destino']"
          :global-format="{type:'row',cols:{sm:6}}"
          :format="format"
          :data="bazaar.description"
      />
      <template v-if="current_user?.username == interaction?.username">
        <v-btn
            @click=""
        >accept 2
        </v-btn>
      </template>
      <template v-else>
        <v-btn
            @click=""
        >accept 1
        </v-btn>
      </template>
    </div>
    <!--    <app-chat :chat="request_data.bazaar"/>-->
  </div>
</template>
<script>
import {get_websocketUrl} from "@/plugins/axios";
import {session} from "@/api/auth";
import AppChat from "@/views/bazaar/components/bazzarServiceRequest/appChat.vue";
import GoodLookJson from "@/components/molecules/goodLookJson.vue";
import {monetize} from "@/plugins/filters";
import {WebsocketApp} from "@/plugins/websocketApp";

export default {
    name: 'app-interaction-accept',
    components: {GoodLookJson, AppChat},
    props: {
        request_data: {type: Object, required: true},
        request_interaction_token: {type: String, required: true}
    },
    data: () => ({
        _reconnect: null,
        ws_status: null,
        connection: null,
        connected: [],

    }),
    beforeDestroy() {
        this.connection.close();
        clearTimeout(this._reconnect);
    },
    beforeMount() {
        this.connect_ws(
            this.request_interaction_token
        );
    },
    methods: {
        connect_ws(token) {
            this.ws_status = "connecting"

            this.connection = new WebsocketApp(
                get_websocketUrl("/request"),
                {
                    authToken: token
                }
            )

            this.connection.subscribe(
                "show connected",
                (data) => {
                    let {connected} = data;
                    this.connected = connected;
                }
            )
        }
    },
    computed: {
        format: () => ({
            final_price: {
                rename: "Final Price",
                filter: monetize
            }, "Municipio Origen": {}, price: {
                rename: "Price",
                filter: monetize
            },
            phone_number: {
                rename: "Phone number"
            }
        }),
        current_user() {
            return session?.me;
        },
        seller() {
            return this.request_data.user_seller;
        },
        interaction() {
            return this.request_data.user;
        },
        bazaar() {
            let {bazaar} = this.request_data;
            if (bazaar == null)
                return {}
            let {description, ...rest} = bazaar;
            return {
                ...rest,
                description: {
                    "final_price": this.request_data.description["price"],
                    ...description
                }
            }
        }
    }
}
</script>
