<template>
  <v-stepper
      class="fill-height overflow-auto"
      vertical
  >
    <v-stepper-step
        v-if="alerts_procesadas.length > 0"
        :complete="true"
        step="..."
    >
      <span> Haz procesado: <b>{{ alerts_procesadas.length }}</b></span>
    </v-stepper-step>
    <v-stepper-step
        :complete="false"
        step="1"
        :rules="[() => message_type!='reject']"
    >
      <template v-if="message_type=='reject'">
        <template v-if="owner">
          Su viaje a sido rechazado
        </template>
      </template>
      <template v-else>
        Un cliente a aceptado su oferta
      </template>
    </v-stepper-step>
    <v-stepper-content
        step="1"
        v-if="message_selected"
        class="mx-0 px-2"
    >
      <div
          v-if="message_selected?.data.bazaar"
          class="d-flex flex-column fill-height">
        <app-chat
            :collapsable="true"
            class="flex-grow-0 "
            :chat="message_selected.data.bazaar"
        ></app-chat>
        <v-timeline
            align-top
            dense
            class="flex-grow-1 pt-0"
        >
          <app-bazaar-interaction
              :interaction="message_selected.data.interaction"
              :chat="message_selected.data.bazaar"
          >
          </app-bazaar-interaction>
        </v-timeline>
        <v-btn
            color="primary"
            v-if="is_back"
            @click="dismissCurrentAlert">
          <v-icon small>
            mdi-arrow-left
          </v-icon>
          ir atrás
        </v-btn>
        <div v-else>
          <auto-form
              :form-fields="[]"
              @submit="acceptRequest">
            <template v-slot:prepend-action>
              <v-btn color="error" @click="cancelRequest">
                Rechazar
              </v-btn>
            </template>
          </auto-form>
        </div>
      </div>
    </v-stepper-content>
    <v-stepper-step
        v-if="alerts.length>1"
        :complete="false"
        step="..."
    >
          <span>Tienes mas alertas por procesar
            <b>{{ alerts.length - 1 }}</b>
          </span>
    </v-stepper-step>
  </v-stepper>
</template>
<script>
import AppBazaarInteraction from "@/views/bazaar/components/appBazaarInteraction.vue"
import AppChat from "@/views/bazaar/components/bazzarServiceRequest/appChat.vue"
import AutoForm from "@/plugins/AutoForms/AutoForm.vue"
import {session} from "@/api/auth";
import {acceptRequest, request} from "@/api/data/bazaar";
import {is_back, message_type} from "@/const";


export default {
    name: 'app-content-alert',
    components: {AppBazaarInteraction, AppChat, AutoForm},
    props: {
        message_selected: {
            type: Object,
            required: true
        }
    },
    computed: {
        owner() {
            const {bazaar} = this.message_selected;
            return session.user_id == bazaar.seller;
        },
        message_type() {
            let {data} = this.message_selected;
            return message_type(data);
        },
        is_back() {
            return is_back.includes(this.message_type);
        },
        alerts() {
            return session.alerts;
        },
        alerts_procesadas() {
            return session.alerts_procesadas;
        },
    },
    methods: {
        async cancelRequest() {
            const alert_attended_id = this.message_selected.data.id;
            await request.remove(
                {request_id: alert_attended_id}
            );
            session.process_alert(alert_attended_id)
            this.$emit('dismiss')
        },
        async acceptRequest() {
            const alert_attended_id = this.message_selected.data.id;
            await acceptRequest.create(
                {request_id: alert_attended_id}
            )
            session.process_alert(alert_attended_id)
            this.$emit('dismiss')
        },
        dismissCurrentAlert() {
            const alert_attended_id = this.message_selected.data.id;
            session.process_alert(alert_attended_id)
            this.$emit('dismiss')
        },
    }
}
</script>
