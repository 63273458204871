<template>
  <v-list-item
      two-line
      @click="$emit('raiseNotification')"
  >
    <v-list-item-avatar>
      <app-chat-avatar :is_urgent="is_urgent"/>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="d-flex flex-row text-subtitle-2">
        <template v-if="message_type=='reject'">
          @{{ message.data.interaction.user.username }}
        </template>
        <template v-else-if="owner && message_type=='requested'">
          @{{ message.data.bazaar.seller_nested.username }}
        </template>
        <template v-else>
          Un cliente
        </template>
        :
      </v-list-item-title>
      <v-list-item-subtitle class="text-caption">
        <template v-if="message_type=='reject'">
          <template v-if="owner">
            El chofer a declinado su oferta
          </template>
          <template v-else>
            Haz declinado tu oferta
          </template>
          <br>
        </template>
        <template v-else-if="message_type=='requested'">
          <template v-if="owner">
            Ha aceptado la oferta de <b>@{{ message.data.interaction.user.username }}</b>
            <br>esperando confirmación del chofer
          </template>
          <template v-else>
            El cliente ha aceptado su oferta y<br>
            espera su confirmación
          </template>
        </template>
      </v-list-item-subtitle>
      <span class="text-caption text--disabled py-0 my-0">{{
          (message.data.rejected_at ?? message.data.requested_at) |dateFromNowUtc
        }}
        </span>
    </v-list-item-content>
    <div class="d-flex flex-column flex-grow-0 flex-shrink-0" style="width: 2em">
      <v-btn icon small @click.stop="$emit('onDelete')">
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
    </div>
  </v-list-item>
</template>
<script>
import {session} from "@/api/auth";
import {message_type} from "@/const";
import AppChatAvatar from "@/views/bazaar/components/bazzarServiceRequest/AppChatAvatar.vue";

export default {
    name: 'app-notification-row',
  components: {AppChatAvatar},
    props: {
        message: {},
    }, computed: {
        is_urgent() {
            let {data} = this.message;
            return data.bazaar.description['is_urgent'];
        },
        message_type() {
            let {data} = this.message;
            return this.message.type ?? message_type(data);
        },
        owner() {
            let {data} = this.message;
            return data.bazaar.seller == session.user_id;
        }
    }, data: () => ({
        session
    })
}
</script>
<style lang="scss">

@keyframes ringing {
  0% {
    transform: rotate(-10deg);
  }
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

</style>