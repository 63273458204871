<template>
  <div>
    <v-btn
        icon
        :dark="dark"
        v-bind="$attrs"
        :small="small"
        @click="showTheSearch"
        v-if="!showInputSearch"
        class="animated flipInX"
        :class="smallRounded">
      <v-icon :color="colorIcon">mdi-magnify</v-icon>
    </v-btn>
    <v-text-field
        v-else
        dense
        :dark="dark"
        class="pa-0 animated flipInX"
        style="margin: auto"
        v-model="searchValue"
        hide-details
        single-line
        autofocus
        :placeholder="placeholder"
        @input="onSearch"
        @keyup.esc="escFn"
        @keyup.enter="search"
    >
      <template v-slot:append>
        <v-progress-circular
            v-if="sleepInterval"
            style="margin: auto"
            indeterminate
            size="16"
            width="2"
            color="primary"/>
        <template v-else>
          <v-icon @click="closeTheSearch">
            mdi-close
          </v-icon>
        </template>
      </template>
    </v-text-field>
  </div>
</template>

<script>

import {DEFAULT_SEARCH_DEBOUNCE} from "@/const";

const btnIcon = {
    props: {
        small: {type: Boolean, default: false},
    },
    computed: {
        smallRounded() {
            return {"small-btn-icon": this.small};
        }
    },
}

const DEFAULT_SEARCH_PLACEHOLDER = 'Search...';

export default {
    name: "like_btn",
    inheritAttrs: false,
    mixins: [btnIcon],
    data: () => ({
        showInputSearch: false,
        searchValue: '',
        placeholder: DEFAULT_SEARCH_PLACEHOLDER,
        sleepInterval: null,
        debounce: DEFAULT_SEARCH_DEBOUNCE,
    }),
    props: {
        colorIcon: {type: String, default: () => ''},
        value: {type: String, default: () => ''},
        label: {type: String},
        lazy: {type: Boolean | Number},
        dark: {type: Boolean, default: () => true}
    },
    mounted() {
        if (this.value)
            this.searchValue = this.value;
        if (this.lazy) {
            if (typeof this.lazy === 'boolean') this.debounce = DEFAULT_SEARCH_DEBOUNCE;
            if (typeof this.lazy === 'number') this.debounce = this.lazy;
        }
    },
    beforeDestroy() {
        this.resetInterval();
    },
    methods: {
        onSearch(e) {
            if (this.lazy) {
                this.resetInterval();
                // this.indeterminate = true;
                this.sleepInterval = setTimeout(() => {
                    this.search(e);
                    this.sleepInterval = null;
                }, this.debounce)
            } else {
                this.search(e)
            }
        },
        showTheSearch() {
            this.placeholder = this.label || DEFAULT_SEARCH_PLACEHOLDER;
            this.showInputSearch = true;
        },
        closeTheSearch() {
            this.showInputSearch = false;
            this.searchValue = '';
            this.handleInput();
            this.search();
        },
        escFn() {
            if (this.searchValue) {
                this.searchValue = '';
                this.placeholder = 'Press ESC to close';
                this.handleInput();
            } else {
                this.showInputSearch = false;
                this.search();
            }
        },
        handleInput() {
            // TODO: implement this (Daniel)
        },
        resetInterval() {
            clearTimeout(this.sleepInterval);
            this.sleepInterval = null;
        },
        search(e) {
            this.resetInterval();
            this.$emit('input', e);
            this.$emit('onSearch', this.searchValue);
        }
    },

}
</script>

<style scoped>

</style>